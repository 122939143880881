@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@media screen and (max-width: 2560px) and (min-width: 1921px) {

}
@media screen and (max-width: 767px) {

}

/*

&::-webkit-scrollbar {
    height: 0px;
}
&::-webkit-scrollbar-track {
    background: $light-blak-bg;
}
&::-webkit-scrollbar-thumb {
    background: $light-blak-bg;
}

*/

$inter: "Inter";

$screen-xs: 767px;

$body-color:#121828;
$body-color-1:#13192A;
$light-body-color:#1E2434;
$light-blak-bg:#242B3C;

$primary01: #0D194F;
$primary02: #ff4545;

$text-blue:#1B1C3B;

$dark-blue:#191F2F;

$heading-color : #ECECEC;

$text-primary01: #81868E;
$text-primary02: #646F7C;
$border-primary01: #F3F3F3;

$gray_text : #7C7F88;

$white: #ffffff;
$light-white: #ECECEC;
$black: #000000;
$yellow: yellow;
$bg-gray: #111218;
$pink:#FF5E8F;
$cyan : #00F2EA;
$red: #F40407;
$orange : #FF6400;

$light-white-2: #F4F4F6;

$layer-black :#04171a;
$yellow :#fec63f;

$box-shadow01: 0 1px 119px rgba(0, 0, 0, 0.15);
$btn-shadow: 0 2.01389px 2.01389px rgba(0, 0, 0, 0.25);
$transition: all 0.3s ease-in-out;

body {
    font-family: $inter !important;
    //font-weight: 400 !important;
    //font-size: 14px !important;
    //line-height: 24px !important;
    background: #13192A !important;

}

.container {
    max-width: 1300px;
    @media screen and (max-width: 1440px) and (min-width: 1200px) { 
        max-width: 1088px !important;
    }
    
    &.large {
        max-width: 1200px;
    }
    &.ex-large {
        max-width: 1454px;
        @media screen and (max-width: 2560px) and (min-width: 1921px) {
            max-width: 1920px;
        }
    }
}

h1 {
    font-weight: 700 !important;
    font-size: 48px !important;
    line-height: 57.6px !important;
    color: $heading-color !important;
    font-family: $inter;
    letter-spacing: -0.32px;
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        font-size: 42px !important;
        line-height: 53.6px !important;
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
        font-size: 38px !important;
        line-height: 47.6px !important;
    }
    @media screen and (max-width: 767px) {
        font-size: 31px !important;
        line-height: 43.6px !important;
    }
}
h2 {
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 36.31px !important;
    color: $white !important;
    font-family: $inter;
    &.large{
        font-size: 64px !important;
        line-height: 72px !important;
    }
}

h3 {
    font-family: $inter;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 29.05px !important;
    color: $white !important;
    @media screen and (max-width: 767px) {
        font-size: 20px !important;
        line-height: 29.05px !important;
    }
    &.large{
        font-size: 30px !important;
        line-height: 38px !important;
    }
}

p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    font-family: $inter;
    color: $text-primary01;
    @media screen and (max-width: 2560px) and (min-width: 1921px) {
        font-size: 18px;
        line-height: 26px;
    }
    @media screen and (max-width: 1199px) {
        font-size: 12px;
        line-height: 16.36px;
    }
    br{
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    &.ex-small{
        font-size: 12px;
        line-height: 14.2px;
    }
    &.small{
        font-size: 14px;
        @media screen and (max-width: 2560px) and (min-width: 1921px) {
            font-size: 18px;
            line-height: 26px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
            font-size: 12px;
            line-height: 16.36px;
        }
    }
    &.large{
        font-size: 18px;
        color: $light-white;
        line-height: 28.8px;
    }
    &.white{
        color: $white !important;
    }
    span{
     color: $pink;
     font-size: 16px;   
     line-height: 19.36px;
     a{
        color: $pink;
     font-size: 16px;   
     line-height: 19.36px;
     text-decoration: underline;
     }
    }
}

strong {
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
    font-family: $inter;
    color: $text-primary01;
    @media screen and (max-width: 2560px) and (min-width: 1921px) {
        font-size: 18px;
        line-height: 26px;
    }
    @media screen and (max-width: 1199px) {
        font-size: 12px;
        line-height: 16.36px;
    }
    &.white{
        color: $white;
    }
    &.large{
        font-size: 20px;
        line-height: 24.2px;
    }
    &.small{
        font-size: 12px;
        line-height: 14.2px;
    }
}

span {
    font-family: $inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    color: $text-primary01;
    display: inline-block;
    @media screen and (max-width: 2560px) and (min-width: 1921px) {
        font-size: 18px;
        line-height: 26px;
    }
    @media screen and (max-width: 1199px) {
        font-size: 12px;
        line-height: 16.94px;
    }
    &.strong{
        font-weight: 500;
    }
    &.large{
        font-size: 16px;
    }
    &.small{
        font-size: 12px;
    }
    &.ex-small{
        font-size: 10px;
    }
    &.xx-small{
        font-size: 8px;
    }
    &.white{
        color: $white;
    }
}
a{
    color: $text-primary01;
    text-decoration: none !important;
    font-size: 16px;
    line-height: 19.36px;
    @media screen and (max-width: 2560px) and (min-width: 1921px) {
        font-size: 16px;
        line-height: 26px;
    }
}
ul{
    padding: 0 0 0 !important;
    margin: 0 0 0 !important;
    li{
        list-style-type: none;
        display: inline-block;
        @media screen and (max-width: 2560px) and (min-width: 1921px) {
            font-size: 18px;
            line-height: 26px;
        }
    }
}

#overLay {
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    visibility: hidden;
    z-index: 111;
    opacity: 0;
    transition: 0.6s ease-in-out all;

    &.active {
        visibility: visible;
        transition: 0.6s ease-in-out all;
        opacity: 1;
    }
}

.loading-div{
    background: linear-gradient(to right, #30384b  0%, #1c2234 100%);
}

.box-icon{
    width: 56px;
    height: 56px;
    border-radius: 16px;
    background: $dark-blue;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img, svg{
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(105%) contrast(101%);
        width: 24px;
        height: 24px;
        object-fit: contain;
        color: $white;
    }
    &.small{
        width: 40px;
        height: 40px;
        border-radius: 10px;
        img, svg{
            width: 18px;
            height: 18px;
        }
    }
}

.check-box, .check-box-wrapper{
    width: 15px;
    height: 15px;
    .label {
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: transparent;
        border: 2px solid #646F7C;
        border-radius: 4px;
    }

    /* On mouse-over, add a grey background color */
    .label:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .label input:checked ~ .checkmark {
        background-color: $cyan;
        border: 2px solid $cyan;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .label input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .label .checkmark:after {
        left: 4px;
        top: 2px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}

//      ****************************  Button Element  ****************************


.btn-style {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: auto;
    padding: 19px 52px 18px;
    color: $text-blue;
    cursor: pointer;
    border-radius: 15px;
    text-decoration: none;
    font-family: $inter;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
    background: $cyan;
    border: 2px solid transparent;
    transition: all .3s ease-in-out;
    z-index: 2;
    box-shadow:  0px 11px 16.8999996185px 0px rgba(0, 0, 0, 0.1882352941), 0px 18px 48.7999992371px 0px #eae5e7 inset;
    gap: 10px;
    @media screen and (max-width: $screen-xs) {
        padding: 15px 50px 15px;
    }
    &:hover {
        transition: all .3s ease-in-out;
        opacity: .9;
        box-shadow: none !important;
    }
    &.simple{
        box-shadow: none;
    }
    &.dark{
        background: $dark-blue;
        color: $white;
            img{
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(105%) contrast(101%);
            }
    }
    &.small {
        padding: 4px 19px 4px !important;
        // height: 38px;
        font-size: 14px;
        line-height: 22.4px;
        font-weight: 600;
        min-width: auto;
        letter-spacing: 4px;
    }
    &.large {
        font-size: 18px;
        padding: 14px 30px 14px;
        @media screen and (max-width: $screen-xs) {
            font-size: 16px;
            padding: 8px 30px 8px;
            height: auto;
        }
    }
    &.transparent {
        background: transparent;
        box-shadow: 0px 4px 15px 0px $text-blue inset;
        border: 1px solid #FFFFFF0F;
        color: $white;
    }
    &.colored-border {
        color: $white;
        border-radius: 15px;
        background: 0 0;
        //border-image-slice: 1!important;
        //border-image: linear-gradient(135deg,#0088CC 0%,#e36159 80%);
        border: 3px solid transparent;     
        background: linear-gradient($body-color, #121828) padding-box,linear-gradient(to right, #FF6393, #5352B0) border-box;
        box-shadow: none;   
        padding: 16px 52px 16px;
    }
    &.youtube-search{
        background: $red;
        color: $white;
        box-shadow:  0px 11px 16.8999996185px 0px rgba(0, 0, 0, 0.1882352941), 0px 12px 48.8px 0px rgba(250, 200, 215, 0.51) inset;
        img{
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(105%) contrast(101%);
        }
    }
    &.instagram-search{
        background: $orange;
        color: $white;
        box-shadow:  0px 11px 16.8999996185px 0px rgba(0, 0, 0, 0.1882352941), 0px 12px 48.8px 0px rgba(250, 200, 215, 0.51) inset;
        img{
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(105%) contrast(101%);
        }
    }
    &.text {
        min-width: 90px;
        height: 32px;
        padding: 7px 5px;
        background: transparent;
        border: none;
        box-shadow: none;
        color: $pink;
        font-weight: 500;
    }
    &.round {
        border-radius: 50px;
    }
    &.shadow {
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    }
    &.icon {
        display: inline-flex;
        align-items: center;
        gap: 8px;
    }
    &.gradient {
        background: rgb(219, 10, 10);
        background: linear-gradient(0deg, rgba(219, 10, 10, 1) 0%, rgba(253, 219, 45, 1) 100%);
        border: none;
        color: white;
    }
    &.light-gray{
        background: #242B3C !important;
        border: 2px solid #242B3C !important;
       
    }
    &.icon-circle {
        border-radius: 50%;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        i {
            font-size: 22px;
        }
    }
    &.pink {
        background: $primary02;
        color: $white;
        border-color: $primary02;
        &:hover {
            background: transparent;
            color: $primary02;
            border-color: $primary02;
        }
    }
    &.full{
        width: 100%;
    }
}



//      ****************************  Global Element  ****************************


.form {
    &.new-form{
        .field-wrapper{
            label{
                display: block;
                font-size: 14px;
                line-height: 21px;
            }
        }
        .form-group{
            box-shadow: none;
            background: #191F2F;
            border-radius: 8px;
            border: none;
            text-align: left;
            padding: 0;
            label{
                display: block;
                font-size: 14px;
                line-height: 21px;
            }
            input{
                box-shadow: none;
                background: transparent;
                padding: 0 0 0;
                color: $white;
                border: 1px solid #272C3B;
                padding: 11.5px 10px;

            }
        }
    }
    .form-group {
        margin-bottom: 24px;
        background: $body-color;
        box-shadow: 30px 2px 9px 0 $text-blue inset;
        border-radius: 15px;
        position: relative;
        border: 3px solid rgba(255, 255, 255, 0.0588235294);
        padding: 13px 15px;
    }
    label {
        color: $black;
        font-size: 16px;
        font-weight: 700;
        display: inline-block;
        margin-bottom: 10px;

        @media screen and (max-width: $screen-xs) {
            margin-bottom: 5px;
        }
        &.white{
            color: $white;
        }
    }
    .error {
        text-align: left;
        @media screen and (max-width: 991px) and (min-width: 768px) {
            font-size: 12px;
            line-height: normal;
        }
        @media screen and (max-width: 767px) {
            font-size: 10px;
            line-height: normal;
            left: 0px;
            padding-left: 25px;
            &:before {
                top: 0px !important;
                width: 12px !important;
                height: 12px !important;
                left: 0;
            }
        }
    }
    .form-control {
        border-radius: 8px;
        line-height: 19.36px;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        // height: 56px;
        // padding: 8px 17px 8px;
        border: none;
        color: $white;
        position: relative;
        outline: none;
        appearance: none;
        background-color: transparent;
        background: transparent;
        @media screen and (max-width: 991px) {
            //height:40px;
        }
        &::placeholder {
            color: $text-primary01;
            font-size: 16px;
        }
        &:disabled{
            background-color: $text-primary02;
        }
        &:focus {
            box-shadow: none;
            background: transparent;
            color: $white;
        }
    }
    textarea{
        resize: none;
    }
    input[type="checkbox"] {
        border: 1px solid #FFFFFF0F;
        border-radius: 4px;
        box-shadow: 0px 4px 15px 0px $text-blue inset;
        width: 20px;
        height: 20px;
    }
    input[type="date"]{
        color-scheme: dark;
      }
}