@import "./assets/scss/standard";

//************************************ Global Scss ***********************************

header.header{
  background: $dark-blue;
  padding: 24px 15px 24px 5px;
  position: relative;
  border-bottom: 1px solid #272C3B;
  @media screen and (max-width: 1440px) and (min-width: 1200px) {
    padding: 18px 15px 18px;
  }
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    padding: 13px 5px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0px;
  }
  &.scan-new-header{
    padding: 25px 5px;
  }
  .hamburger-wrapper{
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .logo{
      display: none;
      @media screen and (max-width: 767px) {
        display: block;
        img, svg{
          height: 16px;
          width: auto;
          object-fit: contain;
        }
      }
    }
    .hamburger{
      @media screen and (min-width: 1366px) {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
      }

      img{
        width: 34px;
        height: 34px;
        object-fit: contain;
        cursor: pointer;
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          width: 24px;
          height: 24px;
        }
        @media screen and (max-width: 767px) {
          width: 20px;
          height: 20px;
        }
      }
      &.desktop{
        display: block;
      }
      &.mobile{
        display: none;
      }
      @media screen and (max-width: 991px) {
        &.desktop{
          display: none;
        }
        &.mobile{
          display: block;
          margin: -2px 0 0;
        }
      }
    }
  }
  .right-menu{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 7.58px;
    @media screen and (min-width: 1200px) {
      width: calc(100% + 23px);
    }
    @media screen and (max-width: 767px) {
      gap: 5px;
    }
    a{
      cursor: pointer;
      display: inline-block;
      img{
        width: 26px;
        height: 26px;
        object-fit: contain;
        @media screen and (max-width: 767px) {
          width: 20px;
          height: 20px;
        }
      }
    }
    .bellIcon{
      position: relative;
      order: 2;
      @media screen and (max-width: 767px) {
        order: 1;
      }
      .dot{
        background: #00F2EA;
        border-radius: 50%;
        width: 13px;
        height: 13px;
        position: absolute;
        top: 0;
        right: 3px;
        z-index: 22;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          width: 6px;
          height: 6px;
          top: 2px;
          right: 3px;
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          width: 6px;
          height: 6px;
          top: 2px;
          right: 3px;
        }
        @media screen and (max-width: $screen-xs) {
          width: 7px;
          height: 7px;
          top: 2px;
        }
      }
    }
    .select-mode{
      display: flex;
      align-items: center;
      gap: 17px;
      order: 3;
      @media screen and (max-width: 1399px) and (min-width: 1200px) {
        gap: 12px;
      }
      @media screen and (max-width: 767px) {
        gap: 12px;
        order: 2;
      }
      .toggle-btn{
        position: relative;
        height: 36px;
        .toggle {
          position : relative ;
          display : inline-block;
          width : 78px;
          height : 36px;
          background-color: #1e2434;
          border-radius: 30px;
          .halfMoonIcon{
            width: 20px;
            height: 20px;
            position: absolute;
            z-index: 2;
            right: 10px;
            top: 9px;
          }
          .sunIcon{
            width: 22px;
            height: 22px;
            position: absolute;
            z-index: 2;
            left: 8px;
            top: 8px;
          }
        }

        /* After slide changes */
        .toggle:after {
          content: '';
          position: absolute;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: #343948;
          top: 5px;
          left: 5px;
          transition:  all 0.5s;
        }

        /* Toggle text */
        p {
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
        }

        /* Checkbox checked effect */
        .checkbox:checked + .toggle::after {
          left : 43px;
        }

        /* Checkbox checked toggle label bg color */
        .checkbox:checked + .toggle {
          background-color: #1e2434;
        }

        /* Checkbox vanished */
        .checkbox {
          display : none;
        }
      }
      span{
        font-size: 16px;
        line-height: 19.36px;
        font-weight: 500;
        display: inline-block;
      }
    }
    .user-detail{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      transition: all 0.3s ease-in-out;
      order: 3;
      margin: 0 0 0 30px;
      &.user-detail-dekstop{
        @media screen and (min-width: 1199px){
          display: flex !important;
        }
      }
      &.user-detail-mobile{
        @media screen and (max-width: 1200px){
          display: flex !important;
        }
      }
      @media screen and (max-width: 1470px) and (min-width: 1330px) {
        margin: 0 0 0 20px;
      }
      @media screen and (max-width: 1329px) and (min-width: 1200px){
        margin: 0 0 0 0px;
        gap: 6px;
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        margin: 0 0 0;
      }
      @media screen and (max-width: 767px) {
        order: 2;
        margin: 0 0 0;
      }
      .img-wrapper {
        img, svg{
          width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    max-width: 40px;
          @media screen and (max-width: 767px) {
            width: 34px;
            height: 34px;
            max-width: 34px;
          }
        }
      }
      .text-wrapper{
        font-size: 0;
        .dropdown{
          .dropdown-toggle{
            text-transform: capitalize;
            font-size: 14px;
            font-weight: 500;
            color: $white;
            background: transparent;
            padding: 0 0 0;
            border:none;
            outline: none;
            text-align: left;
            &:after{
              border: solid $white;
              border-width: 0 2px 2px 0;
              display: inline-block;
              padding: 2px;
              margin-left: 10px;
              transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
              @media screen and (max-width: 1259px) {
                display: none;
              }
            }
            img{
                width: 34px;
                height: 34px;
                max-width: 34px;
                object-fit: cover;
                border-radius: 50%;
              @media screen and (max-width: 767px) {
                width: 26px !important;
                height: 26px !important;
              }
            }
          }
          .dropdown-menu{
            margin-top: 33px;
            box-shadow: 0px 0px 7px 0px #00000040;
            border: 1px solid #272C3B;
            background: $dark-blue;
            padding: 8px 0;
            min-width: 225px;
            border-radius: 14px;
            inset: 0px 0 auto auto !important;
            @media screen and (max-width: 1199px) {
              min-width: 200px;
              margin-top: 6px;
            }
            .dropdown-item{
              color: $white;
              font-size: 14px;
              font-weight: 500;
              padding: 12px 16px;
              gap: 12px;
              display: grid;
              grid-template-columns: 20px auto;
              align-items: center;
              @media screen and (max-width: 767px) {
                font-size: 12px;
                line-height: 16px;
                gap: 6px;
              }
              &:hover{
                background: transparent;
                background-color: transparent;
              }
              img, svg{
                width: 20px;
                height: 20px;
                object-fit: contain;
                filter: brightness(0) saturate(100%) invert(100%) sepia(2%) saturate(368%) hue-rotate(207deg) brightness(116%) contrast(100%);
                @media screen and (max-width: 767px) {
                  width: 15px;
                  height: 15px;
                }
              }
            }
            .dropdown-divider{
              border-top: 1px solid #272C3B;
              margin: 10px 0 5px;
            }
          }
        }
        span.small{
          color: #C5C5C5;

        }
      }
    }
    .btns-wrapper{
      display: flex;
      align-items: center;
      gap: 7.58px;
      order: 1;
      @media screen and (max-width: 767px) {
        //order: 3;
        //display: none;
        gap: 3px;
      }
      .btn-div{
        // box-shadow: 0px 12px 48.8px 0px #272E40 inset;
        background: #1E2434;
        border-radius: 6px;
        text-align: center;
        display: inline-block;
        height: auto;
        padding: 5px 12px;
        @media screen and (max-width: 2560px) and (min-width: 1921px) {
          padding: 8px 20px;
        }
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          padding: 5px 10px;
          height: 36px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          padding: 5px 10px;
        }
        p{
          margin: 0 0 5px;
          font-size: 14px;
          @media screen and (max-width: 2560px) and (min-width: 1921px) {
            font-size: 18px;
            line-height: 26px;
          }
          @media screen and (max-width: 1470px) and (min-width: 1200px) {
            font-size: 12px;
          }
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            font-size: 10px;
          }
          @media screen and (max-width: 991px) and (min-width: 768px) {
            font-size: 9px;
          }
          @media screen and (max-width: 767px) {
            font-size: 8px;
          }
        }
        .progress{
          background-color:#191F2F;
          height: 3px;
          width: 100%;
          min-width: 110px;
          @media screen and (max-width: 2560px) and (min-width: 1921px) {
            height: 5px;
          }
          .progress-bar{
            background-color : $cyan;
          }
        }
      }
      .btn-style.small{
        padding: 7px 15px 7px !important;
        letter-spacing: unset;
       height: auto;
        background: $light-body-color;
        color: #D9D9D9;
        box-shadow: none;
        border-radius: 6px;
        @media screen and (max-width: 2560px) and (min-width: 1921px) {
          font-size: 18px;
          line-height: 26px;
          padding: 11px 20px 11px !important;
          border-radius: 8px;
        }
        @media screen and (max-width: 1470px) and (min-width: 1200px) {
          font-size: 11px;
          gap: 5px;
          padding: 7px 6px !important;
          height: 36px;
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          font-size: 10px;
          gap: 4px;
          padding: 2px 6px !important;
          text-wrap: nowrap;
          height: auto;
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          font-size: 9px;
          gap: 4px;
          padding: 2px 6px !important;
          text-wrap: nowrap;
          height: auto;
        }
        @media screen and (max-width: 767px) {
          font-size: 8px;
          line-height: normal;
          gap: 3px;
          text-wrap: nowrap;
          height: auto;
          padding: 7px 6px 7px !important;
          border-radius: 4px;
        }
        img{
          width: 19px;
          height: 19px;
          object-fit: contain;
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(3299%) hue-rotate(51deg) brightness(109%) contrast(70%);
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            width: 13px;
            height: 13px;
          }
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            width: 13px;
            height: 13px;
          }
          @media screen and (max-width: 991px) and (min-width: 768px) {
            width: 12px;
            height: 12px;
          }
          @media screen and (max-width: 767px) {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
  .notifications-main-wrapper{
    position: absolute;
    z-index: 999;
    right: 165px;
    top: 100px;
    display: none;
    max-height: 665px;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      top: 88px;
    }
    @media screen and (max-width: 1199px) and (min-width: 991px) {
      right: 56px;
      top: 70px;
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      top: 78px;
      right: 60px;
    }
    @media screen and (max-width: 767px) {
      left: 50%;
      right: unset;
      transform: translateX(-50%);
      top: 55px;
      max-height: 500px;
      width: 95%;
    }
    &.active{
      display: block;
    }
    .notifications-div{
      background: $dark-blue;
      border-radius: 0px 0 15px 15px;
      max-width: 445px;
      width: 100%;
    }
    .title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #80849D;
      padding: 16px 24px;
      gap: 30px;
      @media screen and (max-width: 767px) {
        padding: 8px 16px;
      }
      strong{
        position: relative;
        @media screen and (max-width: 767px) {
          font-size: 10px;
          line-height: 14.36px;
        }
        .total-notifications{
          background: $cyan;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          color: #121828;
          font-size: 14px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          position: absolute;
          right: -35px;
          top: -3px;
        }
      }
      button{
        color: $cyan;
        font-size: 14px;
        background: transparent;
        padding: 0;
        border: none;
        @media screen and (max-width: 991px) and (min-width: 768px) {
          font-size: 12px;
          line-height: 16.36px;
        }
        @media screen and (max-width: 767px) {
          font-size: 10px;
          line-height: 14.36px;
        }
      }
      img, svg{
        color: #80849D;
        margin: 0 0 0 12px;
        width: 19px;
        height: 19px;
        object-fit: contain;
        @media screen and (max-width: 767px) {
          width: 14px;
          height: 14px;
        }
      }
    }
    .notifications-wrapper{
      padding: 0 0 21px 0;
      max-height: 600px;
      overflow-y: scroll;
      @media screen and (max-width: 767px) {
        max-height: 430px;
      }
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: $cyan;
        border-radius: 10px;
      }
    }
    .notification{
      border-bottom: 2px solid rgba(79, 77, 103, 0.8);
      padding: 16px 16px 12px 24px;
      display: grid;
      grid-template-columns: 32px auto;
      gap: 14px;
      @media screen and (max-width: 767px) {
        padding: 10px 15px 10px 15px;
        grid-template-columns: 30px auto;
      }
      &.active{
        .content{
          span{
            color: $white;
            a, span{
              color: $cyan;
            }
          }
        }
      }
      .img-wrapper{
        background: #0F3743;
        width: 32px;
        height: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        @media screen and (max-width: 767px) {
          width: 28px;
          height: 28px;
          border-radius: 8px;
        }
        img, svg{
          color: $cyan;
          fill: $cyan;
          width: 19px;
          height: 19px;
          object-fit: contain;
          margin: 0 0 0 1px;
          @media screen and (max-width: 991px) and (min-width: 768px) {
            width: 14px;
            height: 14px;
          }
          @media screen and (max-width: 767px) {
            width: 12px;
            height: 12px;
          }
        }
      }
      .content{
        @media screen and (max-width: 767px) {
          font-size: 0;
          line-height: 0;
        }
        strong{
          font-weight: 500;
          margin: 0 0 5px;
          display: block;
          @media screen and (max-width: 767px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
        span{
          color: #646F7C;
          @media screen and (max-width: 767px) {
            font-size: 8px;
            line-height: 14px;
          }
          &.strong{
            color: #646F7C !important;
          }
          a, span{
            font-size: 14px;
            text-decoration: underline;
            color: #646F7C;
            @media screen and (max-width: 991px) and (min-width: 768px) {
              font-size: 12px;
              line-height: 16.36px;
            }
            @media screen and (max-width: 767px) {
              font-size: 9px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
  .search-filter-wrapper{
    // padding: 0 40px;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      padding: 0 0 0 0;
      .search-filter-bar.dark{
        padding: 9px 9px 9px 9px;
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          padding: 4px 3px 4px 4px;
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          padding: 2px 1px 2px 6px;
          gap: 0;
        }
      }
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
    .search-filter-bar{
      padding: 4px 4px 4px 10px;
      border-radius: 7px;
      background: $light-body-color;
        .platform-icon{
          @media screen and (max-width: 2560px) and (min-width: 1921px) {
            width: 26px !important;
            height: 26px;
          }
        }
      .form-group{
        input, span{
          font-size: 12px;
          line-height: 14px;
          @media screen and (max-width: 2560px) and (min-width: 1921px) {
            font-size: 18px;
            line-height: 26px;
          }
               @media screen and (max-width: 991px) and (min-width: 768px) {
                 font-size: 10px;
                 line-height: 12px;
               }
          &::placeholder{
            font-size: 12px;
            line-height: 14px;
            @media screen and (max-width: 2560px) and (min-width: 1921px) {
              font-size: 18px;
              line-height: 26px;
            }
                 @media screen and (max-width: 991px) and (min-width: 768px) {
                   font-size: 10px;
                   line-height: 12px;
                 }
          }
        }
      }
      .btn-style{
        padding: 8px 0 8px;
        gap: 7px;
        background: $light-body-color;
        box-shadow: 0px 10.15px 41.26px 0px #272E40 inset;
        border-radius: 5px;
        min-width: 180px;
        font-size: 12px;
        @media screen and (max-width: 2560px) and (min-width: 1921px) {
          font-size: 18px;
          line-height: 26px;
          min-width: 250px;
          padding: 8px 0 8px;
        }
        @media screen and (max-width: 1499px) and (min-width: 1200px) {
          min-width: 160px;
          gap: 10px;
          font-size: 11px;
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          min-width: 140px;
          gap: 5px;
          font-size: 10px;
          padding: 7px 5px;
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          min-width: 120px;
          gap: 4px;
          font-size: 8px;
          padding: 5px 5px;
        }
        img{
          width: 16px;
          height: 16px;
          @media screen and (max-width: 2560px) and (min-width: 1921px) {
            width: 26px;
            height: 26px;
          }
          @media screen and (max-width: 991px) and (min-width: 768px) {
            width: 14px;
            height: 14px;
          }
        }
      }
      .css-1nmdiq5-menu{
        #react-select-3-option-0, #react-select-3-option-2, #react-select-3-option-1, #react-select-2-option-0, #react-select-2-option-1, #react-select-2-option-2{
          img{
            width: 20px !important;
            margin-right: 0 !important;
          }
        }
      }
      .css-13cymwt-control{
        width: 60px;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          width: 40px;
        }
        .css-1fdsijx-ValueContainer{
          padding: 0;
          display: inline-flex;
    width: auto;
        }
        .css-1dimb5e-singleValue, .css-1dimb5e-singleValue{
          padding: 0;
          display: inline-flex;
    width: auto;
        img{
          width: 20px !important;
          height: 20px;
        }
        }
      }
    }
  }
  .mobile-filter{
    .scan-new-profile-inner-content{
      .search-filter-wrapper{
        padding: 0 0;
          display: block !important;
        .search-filter-bar.dark{
          flex-direction: row;
          padding: 2px 2px 2px 5px !important;
          gap: 10px;
          width: 100%;
          .btn-style.simple.dark{
            font-size: 9px;
            line-height: normal;
            min-width: 105px;
            gap: 7px;
            width: auto !important;
            font-weight: 600;
            img, svg{
              width: 13px;
              height: 13px;
            }
          }
          .platform-icon{
            @media screen and (max-width: 1199px){
              width: 15px !important;
            }
          }
          .form-group{
            padding: 0 0 0 0;
            &:before{
              height: 33px;
            }
            span{
              font-size: 10px;
              line-height: normal;
            }
            .form-control{
              font-size: 10px;
              padding-inline: 5px;
              line-height: normal;
              color: $white;
              &::placeholder{
                font-size: 10px;
                line-height: normal;
              }
            }
          }
          .css-1nmdiq5-menu{
            @media screen and (max-width: $screen-xs){
              width: 55px;
            }
            #react-select-3-option-0, #react-select-3-option-2, #react-select-3-option-1, #react-select-2-option-0, #react-select-2-option-1, #react-select-2-option-2{
              img{
                width: 20px !important;
                @media screen and (max-width: $screen-xs){
                  width: 16px !important;
                }
              }
            }
          }
          .css-13cymwt-control{
            width: 65px !important;
            @media screen and (max-width: $screen-xs){
              width: 38px !important;
            }
            .css-1dimb5e-singleValue, .css-1dimb5e-singleValue{
              img{
                width: 24px !important;
                height: 24px;
                @media screen and (max-width: $screen-xs){
                  width: 17px !important;
                  height: 17px;
                }
              }
            }
            .css-1xc3v61-indicatorContainer{
              padding-inline: 0 !important;
              img, svg{
                @media screen and (max-width: $screen-xs){
                  width: 14px;
                  height: 14px;
                }
              }
            }
          }
          .css-1hb7zxy-IndicatorsContainer{
            .css-1xc3v61-indicatorContainer{
              padding-inline: 0;
            }
          }
        }
      }
    }
  }

}
.inner-page-wrapper{
  .main-dashboard-wrapper{
    margin: 0 0 0 223px;
    transition: $transition;
    @media screen and (max-width: 1199px) and (min-width: 992px) {
      margin: 0 0 0 200px;
    }
    @media screen and (max-width: 991px) {
      margin: 0 0 0 0;
    }
    &.active{
      margin: 0 0 0 82px;
      transition: $transition;
      @media screen and (max-width: 991px) {
        margin: 0 0 0 0;
      }
    }
    .dashboard-inner-page{
      height: calc(100vh - 110px);
      overflow-y: auto;
      position: relative;
      @media screen and (max-width: 991px) and (min-width: 768px) {
        height: calc(100vh - 80px);
      }
      @media screen and (max-width: 767px) {
        height: calc(100vh - 70px);
        padding-inline: 0;
      }
      /* width */
      &::-webkit-scrollbar {
        width: 8px;
        @media screen and (max-width: 767px) {
          width: 3px;
        }
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $cyan;
        border-radius: 50px;
      }
    }
  }
}
.sidebar-outer{
  &.show{
    .sidebar-main-wrapper{
    left: 0;
      transition: $transition;
    }
  }
  &.active{
    .sidebar-close-icon{
      display: none;
    }
    .sidebar-main-wrapper{
      max-width: 82px;
      transition: $transition;
      @media screen and (max-width: 767px) {
        max-width: 64px;
      }
      .logo-wrapper{
        justify-content: center;
        a.logo{
          display: none;
          img{
            transition: $transition;
          }
        }
        a.logo-mask{
          display: block;
          img{
            transition: $transition;
          }
        }
      }
      ul.menu{
        li{
          a{
            gap: 0;
            padding: 7px 11px 7px;
            transition: $transition;
            border-radius: 10px;
            span{
              visibility: hidden;
              opacity: 0;
              width: 0;
              position: absolute;
              transition: $transition;

            }
          }
        }
      }
      .sidebar-footer{
        .boost-img{
          img.boost{
            display: none;
            transition: $transition;
          }
          a.lightingIcon{
            display: flex;
          }
        }
        .user-detail{
          gap: 0;
          transition: $transition;
          .text-wrapper{
            visibility: hidden;
            opacity: 0;
            width: 0;
            transition: $transition;
            display: none;
          }
        }
        .logout-icon{
          visibility: hidden;
          opacity: 0;
          width: 0;
          transition: $transition;
        }
      }
    }
  }
  .sidebar-main-wrapper{
    position: fixed;
    left: 0;
    top: 0;
    max-width: 223px;
    width: 100%;
    padding: 25px 0 90px;
    background: $dark-blue;
    transition: $transition;
    z-index: 222;
    height: 100vh;
    overflow-y: auto;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      padding: 19px 0 30px;
    }
    @media screen and (max-width: 1199px) and (min-width: 992px) {
      max-width: 200px;
    }
    @media screen and (max-width: 767px) {
      padding: 15px 0 50px;
    }
    &:after{
      content: "";
      display: block;
      // border-right: .5px solid;
      // border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(88, 180, 177, .5), rgba(255, 255, 255, 0)) 1;
      height: 100%;
      border-right: 1px solid #272C3B;
      position: absolute;
      right: 0;
      top: 0;
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $cyan;
      border-radius: 50px;
    }
    @media screen and (max-width: 991px) {
      left: -100%;
      max-width: 70%;
    }
    .logo-wrapper{
      text-align: center;
      padding: 12px 23px 50px;
      transition: $transition;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {

      }
      @media screen and (max-width: 767px) {
        padding: 8px 8px 30px;
      }
      a.logo{
        display: inline-block;
        height: 25px;
        img{
          max-width: 140px;
          width: 100%;
          object-fit: contain;
          transition: $transition;
          display: block;
        }
      }
      .sidebar-close-icon{
        cursor: pointer;
        height: 20px;
        object-fit: contain;
        position: absolute;
        top: 14%;
        right: 8px;
        margin:3px 0 0 0;
        @media screen and (max-width: 767px) {
          height: 16px;
        }
      }
      a.logo-mask{
        display: inline-block;
        height: 42px;
        display: none;
        img{
          height: 42px;
          width: 100%;
          object-fit: contain;
          transition: $transition;
        }
      }
    }
    .sidebar-menu{
      padding: 0px 16px 0;
      //height: calc(100vh - 710px);
      //overflow-y: scroll;
      transition: $transition;
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding: 0 16px 0;
      }
      @media screen and (max-width: 767px) {
        padding: 0 10px 0;
        //height: calc(100vh - 490px);
      }
      /* width */
      &::-webkit-scrollbar {
        width: 3px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $cyan;
        border-radius: 50px;
      }
    }
    ul.menu{
      li{
        display: block;
        &:not(&:last-of-type){
          margin: 0 0 2px;
          @media screen and (max-width: 767px) {
            margin: 0 0 4px;
          }
        }
        a{
          background: transparent;
          gap: 12px;
          width: 100%;
          padding: 7px 10px 7px 10px;
          justify-content: start;
          font-weight: 500;
          border-radius: 5px;
          min-width: auto;
          transition: $transition;
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            padding: 5px 8px 5px 8px;
            gap: 6px;
          }
          @media screen and (max-width: 767px) {
            gap: 6px;
          }
          img{
            width: 23px;
            height: 23px;
            object-fit: contain;
            filter: brightness(0) saturate(100%) invert(57%) sepia(4%) saturate(731%) hue-rotate(187deg) brightness(87%) contrast(82%);
            transition: $transition;
            @media screen and (max-width: 767px) {
              width: 18px;
              height: 18px;
            }
          }
          span{
            color: $gray_text;
            font-weight: 500;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            transition: $transition;
            line-height: 20px;
            font-size: 14px;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              line-height: 18px;
              font-size: 12px;
            }
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              height: 17px;
            }
            @media screen and (max-width: 767px) {
              line-height: 18px;
              font-size: 12px;
            }
          }
          &:hover, &.active{
            background: #1B5F9D40;
            font-weight: 500;
            img{
              filter: brightness(0) saturate(100%) invert(81%) sepia(55%) saturate(2551%) hue-rotate(120deg) brightness(97%) contrast(101%);
              transition: $transition;
            }
            span{
              color: $cyan;
              font-weight: 500;
              transition: $transition;
            }

          }
        }
      }
    }
    .sidebar-footer{
      position: absolute;
      bottom: 32px;
      left: 0;
      width: 100%;
      transition: all 0.3s ease-in-out;
      padding: 0 16px;
      /* width */
      &::-webkit-scrollbar {
        width: 3px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $cyan;
        border-radius: 50px;
      }
      @media screen and (max-height: 850px) {
        position: relative;
        bottom: 0;
        padding: 50px 16px 0;
      }
      .boost-img{
        margin: 24px 0 0;
        img.boost{
          width: 100%;
          transition: $transition;
          @media screen and (max-width: 991px) and (min-width: 768px) {
            max-width: 200px;
          }
        }
        a.lightingIcon{
          display: none;
          height: 41px;
          width: 48px;
          align-items: center;
          justify-content: center;
          background: transparent;
          padding: 7px 11px 7px;
          min-width: auto;
          &:hover, .active{
            background: $cyan;
            img{
              filter: brightness(0) saturate(100%) invert(11%) sepia(17%) saturate(2100%) hue-rotate(199deg) brightness(92%) contrast(97%);
              transition: $transition;
            }
          }
          img{
            width: 24px;
            height: 24px;
            object-fit: contain;
            filter: brightness(0) saturate(100%) invert(57%) sepia(4%) saturate(731%) hue-rotate(187deg) brightness(87%) contrast(82%);
            transition: $transition;
          }
        }
      }
      .user-detail{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        transition: $transition;
        .icon-wrapper, .img-wrapper{
        img{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          max-width: 40px;
        }
        }
        .text-wrapper{
          padding: 0 0 0 3px;
          transition: .4s ease-in-out all;
          opacity: 1;
          max-width: 100%;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span{
            color: $white;
            display: block;
          }
          span.small{
            color: #C5C5C5;
            margin: 3px 0 0 0;
          }
        }
        a.logout-icon{
          transition: $transition;
          cursor: pointer;
          img, svg{
            width: 20px;
            height: 20px;
            object-fit: contain;
            transition: $transition;
          }
        }
      }
    }
  }
}
.search-filter-wrapper{
  max-width: 600px;
  margin-inline: auto;
  width: 100%;
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    padding: 30px 25px;
  }
  @media screen and (max-width: 767px) {
    padding: 15px;
  }
}
.search-filter-bar{
  border: 2px solid #FFFFFF0F;
  box-shadow: 0px 4px 15px 0px #00F2EA1C inset;
  padding: 9px 9px 9px 20px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  gap: 10px;
  margin-inline: auto;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
    padding: 10px 13px 13px 13px !important;
  }
  &.dark{
    background: $light-body-color;
    box-shadow: none;
    border: 2px solid $light-body-color;
  }
  .css-1nmdiq5-menu{
    background: $light-body-color;
    padding: 12px 0;
    left: -11px;
    z-index: 3;
    width: 92px;
    #react-select-3-option-0, #react-select-3-option-2, #react-select-3-option-1, #react-select-2-option-0, #react-select-2-option-1, #react-select-2-option-2, .css-tr4s17-option, .css-10wo9uf-option, .css-10wo9uf-option, .css-1n6sfyn-MenuList, .css-d7l1ni-option, .css-tr4s17-option, .css-10wo9uf-option{
      background: transparent;
      border: 1px solid;
      border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 242, 234, 0.1) 49%, rgba(255, 255, 255, 0) 100%);
      text-align: center;
      &.active{
        background: transparent!important;
      }
      img{
        width: 20px !important;
        margin: 0 0 3px !important;
      }
    }
  }
  .css-13cymwt-control{
    background: transparent;
    border: none;
    width: 65px;
    .css-1fdsijx-ValueContainer, .css-hlgwow{
      display: flex;
      .css-1dimb5e-singleValue, .css-1dimb5e-singleValue{
        >div{
          color: transparent;
          font-size: 12px ;
        }
        img{
          width: 23px !important;
          height: 23px;
          object-fit: contain;
          margin: 0 0 0 0 !important;
        }
      }
      .css-qbdosj-Input, .css-19bb58m{
        display: none;
      }
    }
    .css-1hb7zxy-IndicatorsContainer, .css-1wy0on6{
      .css-1xc3v61-indicatorContainer{
        padding: 0 !important;
        img, svg{
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            width: 14px;
            height: 14px;
          }
        }
      }
      >span.css-1u9des2-indicatorSeparator, .css-1u9des2-indicatorSeparator{
        display: none;
      }
    }
    .css-hlgwow{
      .css-1dimb5e-singleValue{
        img{
          height: 23px;
          margin: 0 !important;
          object-fit: contain;
          width: 23px !important;
        }
      }
      .css-19bb58m{
        display: none;
      }
    }
    .css-1wy0on6{
      .css-1u9des2-indicatorSeparator{
        display: none;
      }
    }
  }
  .form-group{
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 0 0 0 5px;
    &:before{
      content: '';
      border: 0.5px solid;
      height: 49px;
      width: 1px;
      background: #99999954;
      position: absolute;
      left: -5px;
      top: 50%;
      transform: translateY(-50%);
      display: none;
    }
    .accounts-main-wrapper{
      position: absolute;
      left: 0;
      width: 100%;
      background: rgba(25, 31, 47, 1);
      border-radius: 10px;
      top: 70px;
      @media screen and (max-width: 767px) {
        top: 140px;
      }
      .title{
          border-bottom: 0.3px solid rgba(100, 111, 124, 0.2);
        padding: 8px 30px 11px;
        text-align: left;
        strong.white{
          color: rgba(100, 111, 124, 1) !important;
          font-weight: 500;
        }
      }
      .accounts-list{
        padding: 15px 26px 18px;
        .account{
          display: grid;
          grid-template-columns: 70px auto;
          gap: 10px;
          cursor: pointer;
          &:not(&:last-of-type){
            margin: 0 0 15px;
          }
          .img-wrapper{
            display: flex;
            align-items: center;
            gap: 8px;
            img.platform-icon{
              width: 13px;
              height: 13px;
              object-fit: contain;
            }
            img.avatar-icon{
              border-radius: 50%;
              width: 45px;
              height: 45px;
              object-fit: cover;
            }
          }
          .name-wrapper{
            text-align: left;
            .text{
              display: flex;
              align-items: center;
              gap: 15px;
              flex-wrap: wrap;
              span.small{
                color: rgba(100, 111, 124, 1);
                font-size: 12px;
                font-style: normal;
                line-height: 18px;
                span{
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    span{
      font-size: 24px;
      line-height: 29.05px;
      font-weight: 500;
      display: inline-block;
      font-style: italic;
      color: $white;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 17px;
      }
    }
    input.form-control{
      background: transparent;
      font-size: 20px;
      line-height: 24.2px;
      font-style: italic;
      color: $text-primary01;
      border: none;
      width: 100%;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 17px;
      }
      &:focus{
        box-shadow: none;
        border: none;
      }
      &::placeholder{
        font-size: 20px;
        line-height: 24.2px;
        font-style: italic;
        color: $text-primary01;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 17px;
        }

      }
    }
  }
  .btn-style{
    padding: 12px 0px 12px;
    min-width: 220px;
    border-radius: 10px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    img{
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
  }
}
.modal-dialog.line-chart{
  max-width: 930px;
  .modal-content{
    background: $dark-blue;
    padding: 20px 40px 5px;
    @media screen and (max-width: 767px) {
      padding: 10px 20px 5px;
    }
    .modal-header{
      border: none;
      padding: 0;
      button.btn-close{
        --bs-btn-close-bg: url("./assets/images/icons/close-popup-icon.svg");
        --bs-btn-close-opacity:1;
        &:focus{
          box-shadow: none;
          outline: none;
          border: none;
        }
      }
    }
    .modal-body{
      padding: 0;
      .select-detail-wrapper{
        select{
          font-size: 16px;
          font-weight: 600;
          background: #191F2F;
          border: none;
          box-shadow: none;
          padding: 0;
          display: inline-block;
          width: auto;
          text-transform: uppercase;
          option{
            background: transparent;
            border: none;
            color: $white; /* Text color */
            padding: 5px 10px; /* Padding */
          }
          .select-option{
            padding: 10px !important;
            height: 60px !important;
          }
        }
        .dropdown{
          padding: 0;
          button{
            background: transparent;
            border: none;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 600;
            padding: 0;
            @media screen and (max-width: 767px) {
              font-size: 12px;
            }
            &:after{
              display: none;
            }
          }
          .dropdown-menu{
            background: #121828;
            border-radius: 10px;
            //top: 14px !important;
            a{
              color: $white;
              font-size: 16px;
              padding-block: 13px ;
              &:hover{
                background: transparent;
                color: $white;
                opacity: .8;

              }
              &:not(&:last-of-type){
                border-bottom: 1px solid rgba(100, 111, 124, 0.15);;
              }
            }
          }
        }
        .rating{
          display: flex;
          align-items: center;
          gap: 5px;
          margin: 9px 0 0;
          h3{
            margin: 0;
          }
          .text{
            display: flex;
            align-items: center;
            gap: 5px;
            img{
              width: 16px;
              height: 16px;
              object-fit: contain;
            }
            &.down{
              .up-arrow{
                display: none;
              }
              span{
                color: #EA4335;
              }
            }
            &.up{
              .down-arrow{
                display:  none;
              }
              span{
                color: #34A853;
              }
            }
          }
        }
        .rank{
          display: inline-flex;
          align-items: center;
          gap: 5px;
          position: relative;
          display: none;
          &:hover{
            .info-text{
              display: block;
            }
          }
          .dot{
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }
          img{
            width: 18px;
          }
          .info-text{
            background: #121828;
            padding: 14px 28px;
            border-radius: 10px;
            width: 400px;
            //width: 100%;
            display: none;
            position: absolute;
            left: 97px;
            top: 50%;
            transform: translateY(-50%);
            &:before{
              content: "";
              position: absolute;
              top: 50%;
              left: -10px;
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-right: 10px solid #121828;
            }
          }
        }
      }
      .content-wrapper{
        span{
          color: #646F7C;
        }
      }
      .graph-chart-wrapper{
        margin: 50px 0 24px;
      }
      .slick-arrow {
        &:before{
          font-size: 30px;
        }
        &.slick-prev{
          @media screen and (max-width: 767px) {
            left: -13px;
          }
          &:before{
            content: '' !important;
            background-image: url("./assets/images/icons/right-arrow-white-icon.svg");
            background-size: 10px;
            background-position: center;
                width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            display: block;
            @media screen and (max-width: 767px) {
              width: 14px;
              height: 14px;
            }
          }
        }
        &.slick-next{
          @media screen and (max-width: 767px) {
            right: -20px;
          }
          &:before{
            content: '' !important;
            background-image: url("./assets/images/icons/right-arrow-white-icon.svg");
            background-size: 10px;
            background-position: center;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            display: block;
            transform: rotate(180deg);
            @media screen and (max-width: 767px) {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
}
.subscription-cancel-pause-modal{
  max-width: 660px !important;
  &.cancel-subscription-modal{
    max-width: 511px !important;
    .modal-content{
      .modal-body {
        padding: 23px 32px;
        box-shadow: 0px 0px 16.3px 0px #00000014;
        border-radius: 16px;
        .main-wrapper {
          .content-wrapper {
            text-align: left;
            h3{
              margin: 0 0 25px;
            }
            .btn-wrapper {
              text-align: center;
              margin: 50px 0 0;
              a {
                color: #191F2F;
                font-size: 14px;
                font-weight: 600;
                max-width: 230px;
                width: 100%;
                line-height: normal;
                padding: 9px 20px;
                border-radius: 8px;
                height: auto;
              }
            }
          }
        }
      }
    }

  }
  &.confirm-user-profile-modal{
    @media screen and (max-width: 1440px){
      max-width: 382px !important;
    }
    .modal-content{
      border-radius: 7.5px;
    }
    .modal-header{
      padding: 20px 22px 9px 22px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 1440px){
        padding: 12px 14px 9px;
      }
      .modal-title{
        color: #FFFFFFCC;

        font-weight: 700;
        border: none;
        font-size: 24px;
        line-height: 30.52px;
        @media screen and (max-width: 1440px){
          font-size: 12px;
          line-height: 14.52px;
        }
      }
      .btn-close{
        --bs-btn-close-bg: url("./assets/images/icons/close-icon-light.svg");
        //posssition: absolute;
        //right: 16px;
        //top: 13px;
        opacity: 1;
        background-size: 16px;
        padding: 0;
        border: none;
        box-shadow: none;
        margin: -7px 0 0 0;
        @media screen and (max-width: 1440px){
          background-size: 10px;
        }
      }
    }
    .modal-body{
      padding: 50px 40px 30px !important;
      @media screen and (max-width: 1440px){
        padding: 33px 40px 20px !important;
      }
      .main-wrapper{
        .content-wrapper{
          text-align: center;
          .user-profile-info-wrapper{
             border-radius: 12px;
            background: #1C2335;
            padding: 22.63px 20.25px;
            display: inline-block;
            border: 1px solid transparent;
            background: linear-gradient(#1C2335, #1C2335) padding-box,linear-gradient(43.75deg, rgba(255, 22, 93, 0.4) 4.26%, rgba(0, 242, 234, 0) 51.06%, rgba(0, 242, 234, 0.24) 97.85%) border-box;
            @media screen and (max-width: 1440px){
              padding: 18.63px 14.25px;
            }
            .user-profile-img{
              text-align: center;
              .avatarIcon{
                width: 110px;
                height: 100px;
                border-radius: 50%;
                object-fit: cover;
                margin: 0 auto;
                @media screen and (max-width: 1440px){
                  width: 57px;
                  height: 57px;
                }
              }
              strong.small{
                font-weight: 700;
                margin: 12px 0 0 0;
                display: block;
                font-size: 19px;
                line-height: 24px;
                @media screen and (max-width: 1440px){
                  font-size: 11px;
                  line-height: 13px;
                  margin: 7px 0 0 0;
                }
              }
            }
            .text-wrapper{
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              align-items: center;
              margin: 15px 0 0 0;
              gap: 6.5px;
              @media screen and (max-width: 1440px){
                margin: 5px 0 0 0;
                gap: 4.5px;
              }
              .text{
                background: #1218287D;
                border-radius: 7.5px;
                padding: 11px 14px 5px;
                display: inline-block;
                text-align: center;
                strong{
                  display: block;
                  margin: 0 0 4px;
                  &.small{
                    font-size: 17px;
                    line-height: 22px;
                    @media screen and (max-width: 1440px){
                      font-size: 10.5px;
                      line-height: 12.71px;
                    }
                  }
                }
                span{
                  display: block;
                  &.small {
                    color: #646F7C;
                    font-size: 16px;
                    line-height: 22px;
                    @media screen and (max-width: 1440px){
                      font-size: 7.5px;
                      line-height: 10px;
                    }
                  }
                }
              }
            }
          }
          .profiles-info{
            background: #151B2B;
            max-width: 480px;
            width: 100%;
            border-radius: 10px;
            padding: 14px 15px;
            margin:  25px auto 10px;
            @media screen and (max-width: 1440px){
              max-width: 268px;
              padding: 10px 8px;
              border-radius: 7.5px;
            }
            p{
              margin: 0 0 8px;
              font-size: 18px;
              line-height: 26px;
              @media screen and (max-width: 1440px){
                font-size: 10.5px;
                line-height: 12.7px;
              }
            }
            .progress{
              height: 7px;
              border-radius: 20px;
              background: #232A3C;
              .progress-bar{
                background: $cyan;
              }
            }
          }
          p{
            color: #646F7C;
            font-size: 18px;
            line-height: 26px;
            @media screen and (max-width: 1440px){
              font-size: 11px;
              line-height: 14px;
            }
            a, span{
              font-size: 18px;
              line-height: 26px;
              @media screen and (max-width: 1440px){
                font-size: 11px;
                line-height: 14px;
              }
              &.cyan{
                color: $cyan;
              }
            }
          }
          .btn-wrapper{
            display: flex;
            flex-wrap: nowrap;
            gap: 10px;
            margin: 25px 0 0 0;
            justify-content: center;
            .btn-style{
              font-weight: 700;
              height: auto;
              min-height: auto;
              width: 100%;
              padding: 17px 20px 16px;
              border-radius: 12px;
              font-size: 18px;
              max-width: 350px  ;
              @media screen and (max-width: 1440px){
                font-size: 12px;
                line-height: 14.52px;
                padding: 7.5px 7px;
                border-radius: 8px;
                max-width: 167px;
              }
              &.dark{
                background: #232A3C;
                color: #AEB0B7;
                &:hover{
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
  .modal-content{
    background: $dark-blue;
    border-radius: 15px;
    .modal-body{
      padding: 53px 48px 50px;
      @media screen and (max-width: 767px) {
        padding: 53px 20px 50px;
      }
      .main-wrapper{
        text-align: center;
        img{
          max-width: 230px;
          object-fit: contain;
        }
        p{
          color: #646F7C;
        }
        .form-group{
          box-shadow: none;
          margin: 33px 0 45px;
          textarea{
            box-shadow: none;
          }
        }
        .text.btn-style{
          display: block;
          margin: 17px 0 0 0;
          &.red{
            color: #EA4335;
          }
          &.white{
            color: $white;
          }
        }
      }
    }
  }
  &.delete-account-modal{
    max-width: 511px !important;
    .modal-body{
      padding: 23px 32px !important;
      @media screen and (max-width: 1199px) {
        padding: 15px 15px !important;
      }
    }
    .main-wrapper{
      text-align: left !important;
      .content-wrapper{
        h3{
          margin: 0 0 29px;
          @media screen and (max-width: 1199px) {
            margin: 0 0 10px;
            font-size: 16px !important;
            line-height: 22px !important;
          }
        }
        p{
          font-size: 16px;
          color: $white;
          line-height: 22px;
          @media screen and (max-width: 1199px) {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
      form.form{
        .form-group{
          margin-top: 0;
        label{
          @media screen and (max-width: 1199px) {
            font-size: 12px;
            line-height: 18px;
          }
        }
        }
        .btn-wrapper{
          display: flex;
          align-items: center;
          gap: 0;
          justify-content: end;

          .btn-style{
            width: auto;
            height: auto;
            margin: 0 0 0;
            @media screen and (max-width: 1199px) {
              font-size: 12px;
              line-height: 16px;
              border-radius: 6px;
              padding: 7px 20px;
            }
            &.text.cyan{
              color: $cyan;
            }
            &.bg-red{
              background: #DB2719;
              border: 1px solid #DB2719;
              border-radius: 8px;
              color: $white;
              padding: 10px 20px;
              &:hover{
                background: $white;
                color: #DB2719;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  &.change-password-modal{
      max-width: 511px !important;
      .modal-body{
        padding: 23px !important;
      }

    .main-wrapper{
      text-align: left !important;
      img{
        margin: 0 0 12px;
        max-width: 80px;
        height: 80px;
      }
      p{
        margin: 20px 0 40px;
      }
      form.form{
        width: 100%;
        margin-inline: auto;
        text-align: left;
        label{
          font-size: 14px;
          line-height: 21px;
        }
        .form-group{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          margin: 0 0 15px !important;
          border: 1px solid #272C3B;
          border-radius: 8px;
          padding: 9px 15px;
          img{
            width: 24px;
            height: 24px;
          }
        }
        .btn-style.simple{
          width: 100%;
          margin: 20px 0 0 0;
        }
      }
    }
    .btn-wrapper{
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 12px;
      .btn-style{
        width: auto !important;
        display: inline-flex !important;
        padding: 11px 21px 7px;
        border-radius: 8px;
        height: 40px;
        &.dark{
          border: 1px solid #272C3B;
          color: #646F7C !important;
        }
      }
    }
  }
  &.changed-password-modal{
    img.lockIcon{
      filter: brightness(0) saturate(100%) invert(86%) sepia(98%) saturate(7060%) hue-rotate(89deg) brightness(107%) contrast(105%);
      margin: 0 0 15px;
    }
    p{
      margin: 15px 0 40px;
    }
  }
  &.upload-profile{
    .main-wrapper{
      .uploaded-picture{
        max-width: 240px;
        margin: 37px auto 35px;
        .upload-pic{
          border: 1px dashed $cyan;
          border-radius: 15px;
          padding: 25px 29px 24px 30px;
          position: relative;
          .avatar-icon{
            width: 180px;
            height: 180px;
            border-radius: 50%;
            overflow: hidden;
            background: $body-color;
            position: relative;
            &:before{
              content: '';
              display: block;
              position: absolute;
              top: 45%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-image: url("./assets/images/icons/input-type-file-bg-img.png");
              background-repeat: no-repeat;
              background-position: center;
              width: 124px;
              height: 40px;
            }
            label{
              width: 100%;
              height: 100%;
              position: relative;
              z-index: 99;
            }
            input{
              visibility: hidden;
              position: relative;
              z-index: 99;
            }
            .avatar{
              width: 100%;
              height: 100%;
              border-radius: 50%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
            }
          }
        }
        .progress{
          height: 8px;
          margin: 35px 0 0;
          .progress-bar{
            height: 8px;
            background: $cyan;
          }
        }
      }
      .btn-style.simple{
        max-width: 390px;
        width: 100%;
      }
    }
  }
}
.dashboard-download-modal{
  .modal-dialog{
    max-width: 400px;
    .modal-content{
      background: $dark-blue;
      .modal-header{
        border: none;
        padding: 0;
        .btn-close{
          position: absolute;
          top: 16px;
          right: 17px;
          z-index: 2;
          color: $white;
          --bs-btn-close-bg:url("./assets/images/icons/close-white-icon.png");
          opacity: 1;
          background-size: 13px;
          box-shadow: none;
          outline: none;
        }
      }
      .modal-body{
        padding: 22px 21px 33px;
        .img-wrapper{
          margin: 30px auto 16px;
          text-align: center;
          img{
            width: 100px;
            height: 100px;
            object-fit: contain;
            margin-inline: auto;
          }
        }
        .proagress-main-wrapper{
          background: #151B2B;
          padding: 13px 10px;
          border-radius: 10px;
          max-width: 90%;
          margin: 0 auto 11px;
          .title{
            display: flex;
            //grid-template-columns: 49% 49% ;
            gap: 10px;
            justify-content: center;
            span{
              color: #D9D9D9;
              font-size: 12px;
            }
          }
          .progress{
            height: 6px;
             background: #232A3C;
            margin: 10px 0 0 0;
            .progress-bar{
              background: $cyan;
            }
          }
        }
        .text-wrapper{
          text-align: center;
          p{
            color: $text-primary02 !important;
            text-align: center !important;
            font-size: 11px;
            line-height: 14px;
            span.cyan{
              color: $cyan;
              font-size: 11px;
              line-height: 14px;

            }
          }
          .btn-wrapper{
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            gap: 10px;
            margin: 40px 0 0;
            .btn-style{
              padding: 9px 20px 9px;
              border-radius: 8px;
              margin-inline: auto;
              font-size: 12px;
              width: 100%;
              @media screen and (max-width: 767px) {
                font-size: 10px;
                line-height: normal;
                padding: 8px 20px;
                border-radius: 5px;
              }
              &.dark{
                background: #232A3C;
              }
            }
          }

          p, span{
            text-align: left;
            color: #646F7C;
          }
        }
        .data-wrapper{
          margin: 0 0 6px;
          background: #151B2B;
          border-radius: 10px;
          padding: 13px 12px 17px;
        }
        .checkbox-wrapper {
          display: block;
          position: relative;
          padding-left: 25px;
          margin: 12px 0 0;
          cursor: pointer;
          font-size: 14px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: #D9D9D9;
        }

        /* Hide the browser's default checkbox */
        .checkbox-wrapper input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 4px;
          left: 0;
          height: 13px;
          width: 13px;
          background-color: #121828;
          border-radius: 3px;
          border: 1px solid $text-primary01;
        }

        /* On mouse-over, add a grey background color */
        .checkbox-wrapper:hover input ~ .checkmark {
          background-color: #121828;
        }

        /* When the checkbox is checked, add a blue background */
        .checkbox-wrapper input:checked ~ .checkmark {
          background-color: $cyan;
          border: 1px solid $cyan;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .checkbox-wrapper input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .checkbox-wrapper .checkmark:after {
          left: 3px;
          top: 1px;
          width: 4px;
          height: 7px;
          border: solid #121828;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
        }
      }
    }
  }
}
.page-title{
  h1{
    margin: 0 0 30px;
  }
}
.profile-detail-wrapper{
  border-radius: 15px;
  background: $dark-blue;
  padding: 33px 30px 32px;
  text-align: center;
  height: 100%;
  @media screen and (max-width: 991px) and (min-width: 768px) {
    padding: 20px 20px 25px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 20px 30px;
  }
  .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3.h3{
      color:$pink !important;
    }
  }
  .profile-pic-wrapper{
    text-align: center;
    margin: 46px 0 32px;
    @media screen and (max-width: 1199px) {
      margin: 20px 0 20px;
    }
    img.avatarIcon{
      width: 180px;
      height: 180px;
      border-radius: 50%;
      display: block;
      margin: 0 auto 9px;
      @media screen and (max-width: 1199px) {
        width: 100px;
        height: 100px;
      }
    }
    button.change-profile-pic{
      color: $cyan;
      background: $body-color;
      padding: 19px 30px 18px;
      @media screen and (max-width: 1199px) {
        font-size: 12px;
        line-height: 16px;
        padding: 10px 20px;
        border-radius: 8px;
      }
      img{
        filter: brightness(0) saturate(100%) invert(72%) sepia(61%) saturate(2317%) hue-rotate(126deg) brightness(103%) contrast(104%);
        @media screen and (max-width: 1199px) {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .form-wrapper{
    form.form{
      text-align: center;
      .form-group{
        display: flex;
        align-items: center;
        gap: 8px;
        label{
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            font-size: 10px;
            line-height: 14px;
          }
          @media screen and (max-width: 767px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
        img{
          width: 24px;
          height: 24px;
          @media screen and (max-width: 767px) {

          }
        }
        input.form-control{
          @media screen and (max-width: 1199px) {
            font-size: 12px;
            line-height: 16px;
          }
          &::placeholder{
            @media screen and (max-width: 1199px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
      .change-pass{
        a{

          @media screen and (max-width: 1199px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      button.save-btn{
        margin: 40px 0 0 ;
        min-width: 210px;
        @media screen and (max-width: 1199px) {
          margin: 20px auto 0 !important;
          font-size: 12px !important;
          line-height: 16px;
          border-radius: 8px;
        }
      }
    }
  }
}
.table-wrapper{
  overflow: auto;
  background: $dark-blue;
  border-radius: 15px;
  // max-height: 450px;
  &::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #38414F !important;
    border-radius: 5px;
  }
  &.gapped{
    background: transparent;
    table{
      border-collapse:separate;
      border-spacing:0 4px;
      thead{

        tr{
          th{
            padding-block: 20px;
            @media screen and (max-width: 1199px) {
              padding-block: 8px;
            }
            &:first-of-type{
              padding-top: 5px;
              border-radius: 15px 0 0 15px;
              padding-bottom: 6px;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                border-radius: 8px 0 0 8px;
}
            }
            &:last-of-type{
              //padding-top: 5px;
              border-radius: 0 15px 15px 0;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                border-radius: 0 8px 8px 0;
}
            }
            span{
              &.ex_bold{
                font-weight: 700;
              }
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                font-size: 12px;
                line-height: 18px;
              }
              @media screen and (max-width: 767px) {
                font-size: 10px;
                line-height: 14px;
              }
            }
          }
        }
      }
      tbody{
        &:before{
          content:"@";
          display:block;
          line-height:5px;
          text-indent:-99999px;
        }
        tr{
          &:first-of-type{
            td{
              padding-top: 5px;
            }
          }
            td{
              &:first-of-type{
              padding-top: 5px;
                border-radius: 15px 0 0 15px;
                @media screen and (max-width: 1440px) and (min-width: 1200px) {
                  border-radius: 8px 0 0 8px;
}
            }
              &:last-of-type{
                padding-top: 5px;
                border-radius: 0 15px 15px 0;
                @media screen and (max-width: 1440px) and (min-width: 1200px) {
                  border-radius: 0 8px 8px 0;
}
              }
          }
        }
      }
    }
    th, td {
      background: $dark-blue ;
      padding-block: 5px;
    }
    &.dark{
      th,td{
        background: #161C2C !important;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    width: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $text-primary01;
  }
  table{
    width: 100%;
    tr{
      .main-wrapper{
        display: flex;
        align-items: center;
        gap: 5px;
        img{
          height: 12px;
          object-fit: contain;
        }
        strong, label{
          color: #646F7C;
          font-weight: bold;
          font-size: 16px;
          @media screen and (max-width: 1440px) and (min-width: 768px) {
            font-size: 12px;
            line-height: 18px;
          }
          @media screen and (max-width: 767px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
      td{
        padding-block: 15px;
        strong{
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 12px;
            line-height: 18px;
          }
        }
        span.small{
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
        p{
          margin: 0 ;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 12px;
            line-height: 18px;
          }
          @media screen and (max-width: 767px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
    }
    thead{
      padding: 10px 15px;
      tr{
        border-bottom: 1px solid rgba(100, 111, 124, 0.17);
        //padding: 24px 30px 19px 15px;
        th{
          padding-block: 18px;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            padding-block: 14px !important;
          }
          &:first-of-type{
            padding-left: 15px;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              padding-left: 10px !important;
            }
          }
          &:last-of-type{
            padding-right: 15px;
            text-align: center;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              padding-right: 10px !important;
            }
            .main-wrapper{
              justify-content: center;
            }
          }
        }
      }
    }
    tbody{
      tr{
        &:first-of-type{
          td{
            padding-top: 30px;
          }
        }
        td{
          padding-block: 15px;
          &:first-of-type{
            padding-left: 15px;
          }
          &:last-of-type{
            padding-right: 15px;
            text-align: center;
            .main-wrapper{
              justify-content: center;
            }
          }
          span{
            @media screen and (max-width: 767px) {
              font-size: 10px;
              line-height: 14px;
            }
          }
          svg, img{
            color: #646F7C;
            width: 20px;
            height: 20px;
            object-fit: contain;
            cursor: pointer;
            border-radius: 50%;
            &.tall{
              border-radius: 3px;
              height: 57px;
              width: 36px;
            }
          }
          .avatar-icon{
            img{
              width: 30px;
              height: 30px;
              object-fit: cover;
            }
          }
          p.status{
            text-align: center;
            padding: 4px 10px 3px;
            border-radius: 5px;
            display: inline-block;
            min-width: 105px;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              font-size: 12px;
              line-height: 18px;
              min-width: 80px;
              padding: 3px 7px;
            }
            @media screen and (max-width: 767px) {
              font-size: 10px;
              line-height: 14px;
            }
            &.complete{
              color: #34A853 !important;
              background: rgba(52, 168, 83, 0.2);;
            }
            &.queue{
              background: rgba(253, 189, 97, 0.2);;
              color: #FDBD61 !important;
            }
            &.in-progress{
              color: #FF6400 !important;
              background: rgba(255, 100, 0, 0.2);
            }
            &.failed{
              color: #EA4335 !important;
              background: rgba(234, 67, 53, 0.2);
            }
          }
          .btn-group{
            button{
              background: transparent;
              padding: 0;
              border: none;
              &:after{
                display: none;
              }
            }
            .dropdown-menu{
              background: $body-color;
              border: 2px solid rgba(40, 44, 47, 0.59);;
              border-radius: 10px;
              min-width: 230px;
              padding: 4px 7px;
              a{
                background: transparent;
                color: $white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 15px;
                padding: 14px 10px;
                img, svg{
                  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7420%) hue-rotate(142deg) brightness(119%) contrast(115%);
                  width: 20px;
                  height: 20px;
                  object-fit: contain;
                  color: $white;
                }
                &:not(&:last-of-type){
                border-bottom: 1px solid rgba(100, 111, 124, 0.15);
                }
                &:hover{
                  background: transparent;
                }
              }
            }
          }
          .btn-style.dark{
            background: $body-color;
            padding: 8px 23px 8px;
            border-radius: 10px;
            font-size: 14px;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              font-size: 12px;
              line-height: 18px;
              border-radius: 5px;
              padding: 4px 10px 5px;
            }
            @media screen and (max-width: 1199px) and (min-width: 768px) {
              font-size: 11px;
              line-height: 18px;
              border-radius: 5px;
              padding: 2px 10px;
            }
          }
          .platform-icon{
            width: 22px;
            height: 22px;
            object-fit: contain;
          }
          .user-detail-wrapper{
            display: grid;
            grid-template-columns: 45px auto;
            gap: 15px;
            align-items: center;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              grid-template-columns: 35px auto;
            }
            .img-wrapper{
              img{
                width: 45px;
                height: 45px;
                object-fit: cover;
                border-radius: 50%;
                @media screen and (max-width: 1440px) and (min-width: 1200px) {
                  width: 35px;
                  height: 35px;
                }
              }
            }
            .content-wrapper{
              .name{
                display: flex;
                align-items: center;
                gap: 5px;
                strong{
                  @media screen and (max-width: 767px) {
                    font-size: 10px;
                    line-height: 14px;
                  }
                }
                img{
                  width: 15px;
                  height: 15px;
                  object-fit: contain;
                  @media screen and (max-width: 1440px) and (min-width: 1200px) {
                    width: 11px;
                    height: 11px;
                  }
                }
              }
              .text{
                display: flex;
                align-items: center;
                gap: 15px;
              }
            }
          }
          .download-dropdown-wrapper{
            width: 100%;
            display: block;
            text-align: center;
            .action-dropdown{
              width: 100%;
              display: block;
              text-align: center;
              &.show{
                .dropdown-toggle{
                  &:after{
                    border: solid $cyan;
                    border-width: 0 2.5px 2.5px 0;
                    display: inline-block;
                    padding: 3px;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    margin: 0 0 -1px 8px;
                    @media screen and (max-width: 1440px) and (min-width: 768px) {
                      padding: 2px;
                      border-width: 0 2px 2px 0;
                    }
                  }
                }
              }
            }
            .dropdown-toggle{
              background: $body-color;
              font-weight: 700;
              font-size: 15px;
              line-height: 16px;
              display: block;
              width:auto;
              margin-inline: auto;
              padding: 10px 18px 12px;
              border: none;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                font-size: 12px;
                line-height: 18px;
                padding: 2px 15px;
                border-radius: 5px;
              }
              @media screen and (max-width: 1199px) and (min-width: 768px) {
                font-size: 10px;
                line-height: 18px;
                padding: 4px 15px 5px;
                border-radius: 5px;
              }
              &:after{
                border: solid $white;
                border-width: 0 2.5px 2.5px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                margin: 0 0 -1px 8px;
                @media screen and (max-width: 1440px) and (min-width: 768px) {
                  padding: 2px;
                  border-width: 0 2px 2px 0;
                }
              }
            }
            .dropdown-menu{
              background: #152A47;
              border-radius: 8px;
              padding: 10px;
              width: 100%;
              text-align: left;
              margin-top: 3px;
              min-width: auto;
              .dropdown-item{
                color: $white;
                font-weight: 500;
                font-size: 12px;
                padding: 3px 5px;
                display: block;
                text-align: left;
                border: none;
                border-bottom: none !important;
                @media screen and (max-width: 1199px) and (min-width: 768px) {
                  font-size: 10px;
                }
                &:not(&:last-of-type){
                  margin: 0 0 10px;
                }
                &:hover, &.active, &:focus{
                  background: transparent;
                }
                img, svg{
                  width: 15px;
                  height: 15px;
                  object-fit: contain;
                  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(6765%) hue-rotate(222deg) brightness(128%) contrast(110%);
                  margin: 0 5px 0 0;
                  @media screen and (max-width: 1199px) and (min-width: 768px) {
                    width: 11px;
                    height: 11px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.pagination-component{
  padding: 17px 30px;
  border-radius: 15px;
  @media screen and (max-width: 1440px) and (min-width: 1200px) {
    padding: 13px 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 5px 10px 9px;
    border-radius: 6px !important;
  }
  p{
    color: #646F7C;
    display: inline-block;
    margin: 0 0 0;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      font-size: 12px;
      line-height: 18px;
    }
    @media screen and (max-width: $screen-xs){
      font-size: 10px;
      line-height: 14px;
    }
  }
  .select-wrapper{
    @media screen and (max-width: $screen-xs) {
      text-align: left;
    }
    .form-select{
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: #646F7C;
      display: inline-block;
      width: auto;
      --bs-form-select-bg-img: url("./assets/images/icons/down-arrow.png");
      background-size: 10px 6px;
      padding: 0px 2.25rem 0px .75rem;
      @media screen and (max-width: $screen-xs){
        font-size: 10px;
        line-height: 14px;
        padding: 0px 24px 0px 5px;
      }
      &:focus{
        box-shadow: none;
      }
    }
  }
  ul.pagination-list{
    text-align: right;
    align-items: center;
    @media screen and (max-width: $screen-xs) {
      text-align: right;
      width: 100%;
      overflow: hidden;
      justify-content: flex-end;
    }
    li{
      vertical-align: middle;
      font-size: 0;
      &:not(&:last-of-type){
        margin: 0 8px 0 0;
      }
      &.active{
       a{
         background: $text-primary02;
         color: $white ;
         svg{
           color: $white;
         }
       }
      }
      a{
        color: $text-primary02;
        width: 24px;
        height: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        line-height: unset;
        @media screen and (max-width: $screen-xs) {
          width: 20px;
          height: 20px;
          font-size: 10px;
        }
        svg{
          color: $text-primary02;
          //object-fit: contain;
          width: 18px;
          height: 18px;
          @media screen and (max-width: $screen-xs) {
            width: 14px;
            height: 14px;
          }
        }
        &.active{
          background: $text-primary02;
          color: $white ;
          svg{
            color: $white;
          }
        }
      }
    }
  }
}
.filter-detail-wrapper{
  background: $body-color;
  border: 1px solid rgba(100, 111, 124, 0.17);
  border-radius: 10px;
  max-width: 950px;
  width: 100%;
  z-index: 9;
  position: absolute;
  right: 0;
  top: 90px;
  display: none;
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    top: 170px;
  }
  @media screen and (max-width: $screen-xs) {
    width: 95%;
    left: 50%;
    transform: translateX(-50%);
    right: unset;
  }
  &.active{
    display: block;
  }
  .main-wrapper{
    padding: 26px 30px 10px 35px;
    @media screen and (max-width: $screen-xs) {
      padding: 15px 15px 0 15px;
    }
    .col-xl-4{
      margin: 0 0 30px;
      @media screen and (max-width: $screen-xs) {
        margin: 0 0 20px;
      }
    }
    .content-wrapper{
      strong{
        display: block;
        margin: 0 0 15px;
        @media screen and (max-width: $screen-xs) {
          font-size: 10px;
          line-height: 14px;
          margin: 0 0 8px;
        }
      }
      .dropdown{
        .dropdown-menu{
          background: #121828;
          border-radius: 10px;
          top: 53px !important;
          transform: none !important;
          inset: auto auto auto auto !important;
          margin-top: 5px;
          width: 100%;
          border: 1px solid #646F7C26;
          @media screen and (max-width: $screen-xs) {
            border-radius: 5px;
          }
          a{
            color: #ffffff;
            font-size: 16px;
            padding-block: 13px;
            @media screen and (max-width: $screen-xs) {
              font-size: 10px;
              line-height: 14px;
            }
            &:hover{
              background: transparent !important;
            }
            &:not(&:last-of-type){
              border-bottom: 1px solid rgba(100, 111, 124, 0.15);
            }
          }
        }
      }
      .form-select, input, .dropdown-toggle{
        background-color: transparent;
        box-shadow: none;
        border: 1px solid $text-primary02;
        border-radius: 15px ;
        padding: 15px 30px 15px 16px;
        color: $text-primary02;
        --bs-form-select-bg-img:url('./assets/images/icons/down-arrow-white.png');
        background-size: 12px 7px;
        height: 56px;
        width: 100%;
        text-align: left;
        @media screen and (max-width: $screen-xs) {
          font-size: 10px;
          line-height: 14px;
          padding: 8px 9px 8px 9px;
          height: auto;
          border-radius: 5px;
          background-size: 8px 4px;
        }
        &:after{
          position: absolute;
          right: 15px;
          top: 22px;
          border: solid $white;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 4px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          border-radius: 1px;
        }
        &:focus, &:focus-visible{
          border: 1px solid $cyan;
        }
        option {
          background: $body-color;
          color: $white;
          font-size: 16px;
          padding: 13px 26px;
          &:not(&:last-of-type){
            border-bottom: 1px solid rgba(100, 111, 124, 0.17);
          }
        }
      }
      .select-date-wrapper{
        display: flex;
        align-items: center;
        @media screen and (max-width: $screen-xs) {
          gap: 20px;
        }
        .form-group{
          display: inline-block;
          margin: 0 15px 0 0;
          position: relative;
          @media screen and (max-width: $screen-xs) {
            display: block;
            margin: 0 0 20px;
            width: 100%;
          }
          &:focus{
            border: 1px solid $cyan;
          }
          input{
            --bs-form-select-bg-img:none;
            background: $body-color;
            color-scheme: $body-color;
            @media screen and (max-width: $screen-xs) {
              display: block;
              width: 100%;
            }
          }
          input[type="date"]::-webkit-calendar-picker-indicator {
            color: transparent;
            background: url("./assets/images/icons/calendar-icon.png");
            z-index: 0
          }
          .calendarIcon{
            width: 24px;
            height: 24px;
            object-fit: contain;
            position: absolute;
            right: 21px;
            top: 14px;
          }
        }
      }
    }
  }
  .btn-wrapper{
    text-align: right;
    padding: 17px 80px 17px ;
    border-top: 1px solid rgba(100, 111, 124, 0.17);
    @media screen and (max-width: $screen-xs) {
      padding: 17px 10px;
    }
    .btn-style{
      padding: 10px 52px 10px;
      border-radius: 10px;
      @media screen and (max-width: $screen-xs) {
        padding: 7px 10px 7px;
        font-size: 10px;
        border-radius: 5px;
        line-height: 14px;
      }
      &:first-of-type{
        margin: 0 17px 0 0;
        border: 1px solid $text-primary02;
        @media screen and (max-width: $screen-xs) {
          margin: 0 10px 0 0;
        }
      }
    }
  }
}
.navbar-and-filters-wrapper{
  position: relative;
  ul.nav-tabs-wrapper{
    background: $light-blak-bg;
    display: inline-block;
    padding: 3px !important;
    border-radius: 8px;
    @media screen and (max-width: 991px) and (min-width: 768px) {
      border-radius: 6px;
    }
    @media screen and (max-width: $screen-xs) {
      text-align: center;
      margin: 0 0 14px !important;
      display: flex;
      flex-wrap: nowrap;
      padding: 4px 3px !important;
      border-radius: 5px;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 0px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: $light-blak-bg;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $light-blak-bg;
      }
    }
    li{
      a.nav-tab{
        color: #646F7C;
        padding: 7px 18px;
        display: block;
        cursor: pointer;
        border-radius: 6px;
        font-weight: 500;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 12px;
          line-height: 18px;
          padding: 5px 20px 5px;
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          font-size: 11px !important;
          line-height: 16px;
          padding: 5px 11px 6px;
          border-radius: 4px;
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          font-size: 10px;
          line-height: 14px;
          padding: 5px 11px 6px;
          border-radius: 4px;
        }
        @media screen and (max-width: $screen-xs) {
          font-size: 9px;
          line-height: 14px;
          padding: 5px 10px 5px;
          text-wrap: nowrap;
          border-radius: 4px;
        }
        &.active{
          color: $white;
          background: $body-color-1;
          font-weight: 600;
        }
      }
    }
  }
  .filters-wrapper{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 17px;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      gap: 10px;
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      gap: 5px;
    }
    @media screen and (max-width: $screen-xs) {
      justify-content: space-between;
      gap: 7px;
    }
    .search-bar{
      @media screen and (max-width: $screen-xs) {
        width: 100% !important;
      }
    }
    .filter, .search-bar{
      border: 1px solid #646F7C;
      border-radius: 12px;
      cursor: pointer;
      color: $white;
      padding: 7px 24px;
      display: inline-flex;
      align-items: center;
      gap: 10px;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        padding: 6px 24px 6px 15px;
        font-size: 12px;
        line-height: 18px;
        gap: 7px;
        border-radius: 9px;
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding: 7px 11px;
        border-radius: 5px;
        gap: 5px;
        width: auto !important;
        font-size: 10px;
        line-height: 14px;
      }
      @media screen and (max-width: $screen-xs) {
        padding: 7px 11px;
        border-radius: 5px;
        font-size: 10px;
        line-height: 14px;
        gap: 5px;
        width: auto !important;
      }
      img, svg{
        object-fit: contain;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          width: 18px;
          height: 18px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          width: 12px;
          height: 12px;
        }
        @media screen and (max-width: $screen-xs) {
          width: 12px;
          height: 12px;
        }
      }
      @media screen and (max-width: $screen-xs){
        width: 100%;
      }
      &.active{
        border: 1px solid $cyan;
      }
      input.form-control{
        background: transparent;
        color: $white;
        font-size: 16px;
        border: none;
        box-shadow: none;
        outline: none;
        padding: 0 0 ;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 10px;
          line-height: 18px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          font-size: 10px;
          line-height: 14px;
        }
        @media screen and (max-width: $screen-xs) {
          font-size: 10px;
          line-height: 14px;
        }
        &::placeholder{
          color: $white;
          font-size: 16px;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 10px;
            line-height: 18px;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            font-size: 10px;
            line-height: 14px;
          }
          @media screen and (max-width: $screen-xs) {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
    }
    .export{
      @media screen and (max-width: $screen-xs){
        display: block;
        width: auto;
      }
      .btn-style{
        border-radius: 8px !important;
        padding: 7px 15px;
        font-size: 14px !important;

        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 10px !important;
          line-height: 18px;
          padding: 5px 10px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          font-size: 10px !important;
          line-height: 14px;
          padding: 6px 8px;
          border-radius: 5px !important;
          min-width: auto;
          gap: 7px;
        }
        @media screen and (max-width: $screen-xs){
          font-size: 10px !important;
          line-height: 14px;
          font-weight: 500;
          padding: 6px 8px;
          border-radius: 5px !important;
          min-width: auto;
          gap: 7px;
        }
        img, svg{
          height: 18px;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            height: 13px;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            height: 13px;
          }
          @media screen and (max-width: $screen-xs){
            height: 10px;
            width: 9px;
          }
        }
      }
    }
  }
}
.no-downloads{
  text-align: center;
  padding: 60px 0;
  background: #191F2F;
  border-radius: 15px;
  .img-wrapper{
    img{
      max-width: 330px;
      object-fit: contain;
      width: 100%;
    }
  }
  .content-wrapper{
    strong{
      display: block;
      margin: 5px 0 19px;
    }
    p{
      a{
        color: $cyan;
        font-size: 16px;
      }
    }
  }
}
.my-download-grid{
  .col-xl-2.col-lg-3.col-md-3.col-6{
    margin: 0 0 25px;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      padding-left: 6px;
      padding-right:6px;
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      &:nth-child(4n){
      }
      &:not(&:nth-child(4n)){
        padding-right:6px;
      }
    }
    @media screen and (max-width: $screen-xs){
      margin: 0 0 10px;
      &:nth-child(odd){
        padding-right: 5px;
      }
      &:nth-child(even){
        padding-left: 5px;
      }
    }

  }
}
.section-title-bar{
  h3{
    margin: 0 0 0;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      font-size: 16px !important;
      line-height: 20px !important;
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      font-size: 16px !important;
      line-height: 20px !important;
    }
    @media screen and (max-width: $screen-xs){
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }
  ul.icon-list{
    text-align: end;
    li{
      &:not(&:last-of-type){
        margin: 0 13px 0 0;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          margin: 0 10px 0 0;
        }
        @media screen and (max-width: $screen-xs){
          margin: 0 8px 0 0;
        }
      }
    }
  }
}
.my-download-card{
  background: $dark-blue;
  border-radius: 15px;
  padding: 29px 27px 15px;
  position: relative;
  @media screen and (max-width: 1440px) and (min-width: 1200px) {
    padding: 20px 15px 15px;
  }
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    padding: 29px 10px 10px;
  }
  @media screen and (max-width: $screen-xs){
    padding: 29px 10px 10px;
  }
  .avatar-wrapper{
    width: 93px;
    height: 93px;
    margin: 0 auto 0;
    position: relative;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      width: 70px;
      height: 70px;
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      width: 60px;
      height: 60px;
    }
    @media screen and (max-width: $screen-xs){
      width: 40px;
      height: 40px;
    }
    .avatarImg{
      width: 93px;
      height: 93px;
      border-radius: 50%;
      object-fit: cover;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        width: 70px;
        height: 70px;
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        width: 60px;
        height: 60px;
      }
      @media screen and (max-width: $screen-xs){
        width: 40px;
        height: 40px;
      }
    }
    .downloadAvatarSideIcon{
      width: 15px;
      height: 15px;
      object-fit: contain;
      position: absolute;
      right: -4px;
      top: 14px;
    }
    .verifies-icon{
      object-fit: contain;
      position: absolute;
      right: -4px;
      top: 5px;
      border-radius: 50%;
      background: $dark-blue;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img, svg{
        color: $cyan;
        img, svg{
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .platform-icon{
    width: 33px;
    height: 33px;
    background: #2B3140;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: 10px;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      width: 24px;
      height: 24px;
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      width: 24px;
      height: 24px;
    }
    @media screen and (max-width: $screen-xs){
      width: 20px;
      height: 20px;
    }
    img{
      width: 22px;
      height: 22px;
      object-fit: contain;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        width: 16px;
        height: 16px;
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        width: 14px;
        height: 14px;
      }
      @media screen and (max-width: $screen-xs){
        width: 10px;
        height: 10px;
      }
    }
  }
  .content-wrapper{
    text-align: center;
    overflow: hidden;
    strong{
      display: block;
      text-align: center;
      margin: 16px 0;
      width: 100%;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        font-size: 12px;
        line-height: 14px;
        margin: 12px 0 7px;
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: 10px;
        line-height: 14px;
        margin: 12px 0 7px;
      }
      @media screen and (max-width: $screen-xs){
        font-size: 10px;
        line-height: 14px;
        margin: 12px 0 10px;
      }
    }
    .video-and-followers{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      .total{
        background: #1218287D;
        border-radius: 10px;
        display: inline-block;
        padding: 6px 15px;
        text-align: center;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          border-radius: 8px;
          padding: 5px 10px;
          border: 7.5px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          border-radius: 8px;
          padding: 5px 10px;
          border: 7.5px;
        }
        @media screen and (max-width: $screen-xs){
          padding: 6px 10px 4px;
          border-radius: 6px;
        }
        span{
          display: block;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 8px;
            line-height: 10px;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            font-size: 8px;
            line-height: 14px;
          }
          @media screen and (max-width: $screen-xs){
            font-size: 8px;
            line-height: 10px;
          }
        }
        span.white{
          margin: 0 0 5px;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 10px !important;
            line-height: 12px !important;
            margin: 0 0 3px;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            font-size: 10px;
            line-height: 13px;
            margin: 0 0 3px;
          }
          @media screen and (max-width: $screen-xs){
            font-size: 10px;
            line-height: 13px;
            margin: 0 0 3px;
          }
        }
      }
    }
    .view-btn{
      margin: 12px 0 13px;
      .btn-style.dark{
        background: $body-color;
        font-weight: 700;
        width: 100%;
        display: flex;
        border-radius: 10px;
        padding: 12px 5px 12px;
      }
    }
    .downloaded-info{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      background: $body-color;border-radius: 6px;
      display: inline-block;
      width: 100%;
      padding: 10px 10px 11px;
      margin: 20px auto 7px;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        margin: 15px auto 5px;
        padding: 7px 9px 9px;
        line-height: 0;
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        margin: 15px auto 5px;
        padding: 7px 9px 9px;
        line-height: 0;
      }
      @media screen and (max-width: $screen-xs){
        padding: 9px 10px 9px;
        font-size: 0;
        line-height: 0;
        margin: 10px auto 5px;
      }
      span, a{
        font-weight: 700;
        font-size: 15px;
        color: $white;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 9px;
          line-height: 12px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          font-size: 9px;
          line-height: 14px;
        }
        @media screen and (max-width: $screen-xs){
          font-size: 8px;
          line-height: 12px;
        }
      }
      img{
        width: 13px;
        height: 13px;
        object-fit: contain;
      }
    }
    .download-dropdown-wrapper{
      width: 100%;
      display: block;
      text-align: center;
      .action-dropdown{
        width: 100%;
        display: block;
        text-align: center;
        &.show{
          .dropdown-toggle{
            &:after{
              border: solid $cyan;
              border-width: 0 2.5px 2.5px 0;
              display: inline-block;
              padding: 3px;
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              margin: 0 0 -1px 8px;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                padding: 2px;
                border-width: 0 1.5px 1.5px 0;
              }
              @media screen and (max-width: 1199px) and (min-width: 768px) {
                padding: 2px;
                border-width: 0 1.5px 1.5px 0;
                margin: 0 0 0px 8px;
              }
              @media screen and (max-width: $screen-xs){
                padding: 2px;
                border-width: 0 1.5px 1.5px 0;
                margin: 0 0 0px 8px;
              }
            }
          }
        }
      }
      .dropdown-toggle{
        background: $body-color;border-radius: 6px;
        font-weight: 700;
        font-size: 15px;
        line-height: 16px;
        display: block;
        width: 100%;
        margin-inline: auto;
        padding: 10px 10px 10px;
        border: none;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 9px;
          line-height: 12px;
          padding: 5px 10px 7px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          font-size: 9px;
          line-height: 14px;
          padding: 8px 10px 7px;
        }
        @media screen and (max-width: $screen-xs){
          font-size: 9px;
          line-height: 12px;
          padding: 10px 10px 9px;
        }
        &:after{
          border: solid $white;
          border-width: 0 2.5px 2.5px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          margin: 0 0 -1px 8px;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            padding: 2px;
            border-width: 0 1.5px 1.5px 0;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            padding: 2px;
            border-width: 0 1.5px 1.5px 0;
          }
          @media screen and (max-width: $screen-xs){
            padding: 2px;
            border-width: 0 1.5px 1.5px 0;
            margin: 0 0 0px 8px;
          }
        }
      }
      .dropdown-menu{
        background: #152A47;
        border-radius: 8px;
        padding: 10px;
        width: 100%;
        text-align: left;
        margin-top: 10px;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          margin-top: 2px;
          padding: 4px 2px;
          border-radius: 5px;
        }

        .dropdown-item{
          color: $white;
          font-weight: 500;
          font-size: 12px;
          padding: 3px 5px;
          vertical-align: middle;
          display: flex;
          align-items: center;
          gap: 4px;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 9px;
            line-height: 12px;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            font-size: 9px;
            line-height: 12px;
          }
          @media screen and (max-width: $screen-xs){
            font-size: 8px;
            line-height: 12px;
          }
          &:not(&:last-of-type){
            margin: 0 0 10px;
          }
          &:hover, &.active, &:focus{
            background: transparent;
          }
          img, svg{
            width: 15px;
            height: 15px;
            object-fit: contain;
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(6765%) hue-rotate(222deg) brightness(128%) contrast(110%);
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              width: 12px;
              height: 10px;
            }
            @media screen and (max-width: 1199px) and (min-width: 768px) {
              width: 12px;
              height: 10px;
            }
            @media screen and (max-width: $screen-xs){
              width: 12px;
              height: 10px;
            }
          }
        }
      }
    }
    .status-wrapper{
      text-align: center;
      position: absolute;
      display: inline-block;
      top: 17px;
      right: 10px;
      font-size: 0;
      line-height: 0;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        top: 14px;
        right: 6px;
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        top: 12px;
        right: 8px;
      }
      @media screen and (max-width: $screen-xs){
        top: 12px;
        right: 8px;
      }
      span.status{
        padding: 2px 10px;
        display: inline-block;
        border-radius: 6px;
        font-size: 10px;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 7px;
          line-height: 10px;
          padding: 2px 5px;
          border-radius: 3px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          font-size: 7px;
          line-height: 10px;
          padding: 2px 5px;
          border-radius: 3px;
        }
        @media screen and (max-width: $screen-xs){
          font-size: 6px;
          line-height: 10px;
          padding: 2px 5px;
          border-radius: 3px;
        }
        &.completed{
          color: #34A853;
          background: #34A85333;
        }
        &.in-queue{
          color: #FDBD61;
          background: #FDBD6133;
        }
        &.in-progress{
          color: #FF6400;
          background: #FF640033;
        }
        &.failed{
          color: #EA4335;
          background: #EA433533;
        }
      }
    }
  }
}
.calendar-wrapper{
  .react-calendar{
    float: right;
    max-width: 296px;
    height: 315px;
    background: #121828;
    border: none;
    border-radius: 15px;
    @media screen and (max-width: 767px) {
      float: none;
      width: 100%;
      height: 200px;
    }
    .react-calendar__navigation{
      background: #191F2F;
      border-radius: 15px 15px 0 0;
      margin-bottom: 25px;
      height: 55px;
      @media screen and (max-width: 767px) {
        height: 40px;
        margin-bottom: 10px;
      }
      button{
        color:#646F7C;
        @media screen and (max-width: $screen-xs) {
          min-width: 20px;
          span{
            font-size: 8px;
            line-height: normal;
          }
        }
      }
    }
    .react-calendar__viewContainer{
      background: #121828;
      .react-calendar__month-view__weekdays__weekday{
        color: #646F7C;
        @media screen and (max-width: $screen-xs) {
          font-size: 9px;
          line-height: 13px;
        }
        abbr[title]{
          text-decoration: none;
        }
      }
    }
    .react-calendar__month-view__days{
      .react-calendar__month-view__days__day{
        color: $white;
        @media screen and (max-width: $screen-xs) {
          font-size: 9px;
          line-height: 13px;
          padding: 5px 3px;
        }
        &.react-calendar__tile--now {
          background: $cyan;
          border-radius: 5px;
          color: #191F2F;
          font-weight: bold;
        }
      }
      .react-calendar__month-view__days__day--weekend{
        color: #646F7C;
      }
      .react-calendar__tile--active{
        background: $cyan;
        border-radius: 5px;
        color: #191F2F;
        font-weight: bold;
      }
    }
    .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
      background: $cyan;
      border-radius: 5px;
      color: #191F2F;
      font-weight: bold;
    }
  }
}
.user-scan-dashboard-info{
  padding: 9px 0px 10px;
  background: linear-gradient(to right, #112d3b 10%,#1e1f31, #28192e);
  &.profle-scan-info{
    .total-social-info-wrapper{
      padding: 7px 13px 9px 10px;
      background: linear-gradient(to right, #1A2134, #121828);
      border-radius: 7.4px;
      border: 1px solid #191F2F;
      display: grid;
      grid-template-columns: 46% 46%;
      gap: 11px 8%;
      background: #191F2F;
        @media screen and (max-width: $screen-xs) {
          padding:13px 9px;
          gap: 11px 10px;
          justify-content: space-between;
        }

      .main-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name{
          display: grid;
          grid-template-columns: 11px auto;
          gap: 8.5px;
          align-items: center;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            gap: 5.5px;
          }
            @media screen and (max-width: $screen-xs) {
              gap: 3px;
            }
          img, svg{
            width: 11px;
            height: 11px;
            color: $cyan;
            object-fit: contain;
          }
          span{
            font-size: 10px;
            font-weight: 500;
            color: $text-primary02;
            line-height: 12px;
            @media screen and (max-width: 767px) {
              font-size: 9px;
              line-height: normal;
            }
          }
        }
        span{
          font-size: 10px;
          font-weight: 700;
          line-height: 12px;
          @media screen and (max-width: 767px) {
            font-size: 9px;
            line-height: normal;
          }
        }
      }
    }
    .actions-main-wrapper{
      grid-template-columns:48% 48% ;
      @media screen and (max-width: 1199px) and (min-width: 991px) {
        grid-template-columns:32% 32% ;
      }
    }
  }
  @media screen and (max-width: 1440px) and (min-width: 1200px) {
    .col-xxl-5{
      width: 35%;
    }
    .col-xxl-3{
      width: 28%;
    }
    .col-xxl-2{
      width: 18%;
    }
    .col-xxl-3{
      padding-left: 20px;
      padding-right: 10px;
    }
    .col-3, .col-md-2, .col-md-3, .col-md-5, .col-xxl-2 , .col-xxl-5  {
      padding-left: 10px;
      padding-right: 0;
    }
  }
  .col-xxl-2.col-xl-6.col-lg-6.col-md-4, .col-xxl-2.col-xl-6.col-lg-6.col-md-4{
    @media screen and (max-width: 991px) and (min-width: 768px) {
      padding-right: 0;
    }
  }
  .actions-main-wrapper{
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    gap: 12px;
  }
  .user-info-wrapper{
    background: #162132;
    border-radius: 10px;
    padding: 12px 10px;
    display: grid;
    grid-template-columns: 50px auto;
    align-items: center;
    gap: 10px;
    border: 1px solid $dark-blue;
    .img-wrapper{
      img{
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .text-wrapper{
           overflow: hidden;
           width: 100%;
      strong{
        display: inline-block;
        margin: 0 0 4px;
        position: relative;
        padding: 0 17px 0 0;
        word-break: break-word;
        text-wrap: nowrap;
        font-size: 12px;
        line-height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        @media screen and (max-width: 767px) {
          margin: 0 0 0;
        }
        .verifiedIcon{
          position: absolute;
          right: 0;
          top: -2px;
        }
      }
      span{
        display: block;
        word-break: break-all;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
  .ratings-wrapper{
      padding: 7px 9px 8px;
    background: #191F2F;
    border-radius: 7px;
    @media screen and (max-width: 1199px) {
      padding: 10px 9px 10px;
    }
    .main-wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(&:last-of-type){
        margin: 0 0 12px 0;
      }
      .name{
        display: grid;
        grid-template-columns: 11px auto;
        gap: 9px;
        align-items: center;
        @media screen and (max-width: 1500px) and (min-width: 1200px) {
          gap: 7px;
        }
        img, svg{
          width: 11px;
          height: 11px;
          color: $cyan;
          object-fit: contain;
        }
        span{
          font-size: 10px;
          font-weight: 500;
          color: $text-primary02;
          line-height: 12px;
          @media screen and (max-width: 767px) {
            font-size: 9px;
            line-height: normal;
          }
        }
      }
      span{
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        @media screen and (max-width: 767px) {
          font-size: 9px;
          line-height: normal;
        }
      }
    }
  }
  .action-wrapper{
    background: #1C1E30;
    border-radius: 7.5px;
    text-align: center;
    padding: 10px 5px 8px;
    height: 100%;
    border: 1px solid $cyan;
    cursor: pointer;
    transition: .3s ease-in-out all;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      padding: 12px 5px 12px;
    }
    &:hover{
      background: $cyan;
      transition: .3s ease-in-out all;
      .icon-wrapper{
        background: #00000014;
        transition: .3s ease-in-out all;
        img, svg{
          filter: brightness(0) saturate(100%) invert(6%) sepia(44%) saturate(1141%) hue-rotate(191deg) brightness(99%) contrast(93%);
          transition: .3s ease-in-out all;
        }
      }
      .text{
        span{
          color: $body-color-1;
        }
      }
    }
    .icon-wrapper{
      background: #1B2F3F;
      border-radius: 50%;
      width: 33.3px;
      height: 33.3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-inline: auto;
      margin: 0 0 7px;
      transition: .3s ease-in-out all;
      img, svg{
        width: 16px;
        height: 16px;
        object-fit: contain;
        color: $cyan;
        transition: .3s ease-in-out all;
      }
    }
    .text{
      font-size:0;
      span{
        color: $cyan;
        font-size: 10px ;
        font-weight: 700;
      }
      span.strong{
        line-height: 12px;
        @media screen and (max-width: 1399px) and (min-width: 1200px) {
          font-size: 9px;
        }
        @media screen and (max-width: 767px) {
          font-size: 9px;
        }
      }
    }
  }
  .features-wrapper{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 15px 14px 15px;
    background: #252441;
    border-radius: 10px;
    .text-wrapper{
      text-align: center;
      h2{
        font-size: 47px !important;

      }
      .name-title{
        display: inline-flex;
        align-items: center;
        gap: 5px;
        img, svg{
          width: 15px;
          height: 15px;
          object-fit: contain;
        }
      }
    }
    ul.features-list{
      li{
        display: grid;
        grid-template-columns: 6px auto;
        align-items: center;
        gap: 9px;
        color: $text-primary02;
        font-size: 12px;
        &:not(&:last-of-type){
          margin: 0 0 7px;
        }
        .dot{
          width: 6px;
          height: 6px;
          border-radius: 50%;
          display: block;
          background: $cyan;
        }
      }
    }
  }
}
.dashboard-average-cards-wrapper{
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  @media screen and (max-width: 1300px) and (min-width: 1200px) {
    grid-template-columns: 11.5% 11.5% 11.5% 11.5% 11.5% 11.5% 11.5% 11.5%;
  }
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    grid-template-columns: 24% 24% 24% 24%;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 49% 49%;
  }
  .average-card{
    text-align: center;
    background: $dark-blue;
    border-radius: 8px;
    padding: 7px 8px 10px;
    @media screen and (max-width: 1660px) and (min-width: 1200px){
      padding: 7px 3px 10px;
    }
    @media screen and (max-width: 1199px) and (min-width: 992px) {
      padding: 12px 8px 4px;
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      padding: 10px 8px 4px;
    }
    @media screen and (max-width: $screen-xs) {
      padding: 10px 8px 4px;
    }
    .title{
      display: grid;
      grid-template-columns: 13px auto;
      gap: 5px;
      margin: 0 0 8px;
      justify-content: center;
      align-items: center;
      // min-height: 34px;
      @media screen and (max-width: 1660px) and (min-width: 1400px){
        gap: 4px;
        grid-template-columns: 11px auto;
      }
      @media screen and (max-width: 1399px) and (min-width: 1200px){
        grid-template-columns: 11px auto;
        gap: 4px;
      }
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        grid-template-columns: 15px auto;
      }
      img{
        width: 13px;
        height: 13px;
        object-fit: contain;
        @media screen and (max-width: 1660px) and (min-width: 1441px){
          width: 11px;
          height: 11px;
        }
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          width: 9px;
          height: 9px;
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          width: 15px;
          height: 15px;
        }
      }
      span{
        color: $text-primary02;
        line-height: normal;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 8px;
          line-height: 10px;
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          font-size: 11px;
          line-height: 18px;
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          font-size: 9px;
          line-height: 14px;
        }
      }
    }
    h2{
      font-size: 30px;
      margin: 0 0 2px 0;
      @media screen and (max-width: 1199px) and (min-width: 1441px)  {
        font-size: 24px !important;
      }
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        font-size: 22px !important;
        line-height: 26px !important;
      }
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        font-size: 28px !important;
        line-height: 38px !important;
      }
      @media screen and (max-width: 991px) and (min-width: 768px) {
        font-size: 22px !important;
        line-height: 26px !important;
      }
      @media screen and (max-width: $screen-xs) {
        font-size: 20px !important;
      }
    }
    .average-text{
      background: $body-color;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;
      gap: 5px;
      padding: 2px 9px;
      .dot{
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #FF6400;
      }
      span{
        color: $text-primary02;
      }
      img{
        width: 9px;
        height: 9px;
        object-fit: contain;
      }
    }
  }
}
.average-text-div-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  strong{
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  button{
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 0;
    line-height: 0;
    img, svg{
      &.captureIcon{
        width: 20px;
        height: 20px;
        object-fit: contain;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          width: 16px;
          height: 16px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          width: 12px;
          height: 12px;
        }
        @media screen and (max-width: 767px)  {
          width: 11px;
          height: 11px;
          object-fit: contain;
        }
      }
    }

  }
}
.dashboad-total-small-charts-main{
  .average-text-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    button{
      background: transparent;
      padding: 0;
      border: none;
      outline: none;
      box-shadow: none;
      .captureIcon{
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
    }
  }
  .dashboad-total-small-charts{
    display: grid;
    grid-template-columns: repeat(8, 12%);
    gap: 7px;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      margin: 24px 0 0;
      grid-template-columns: repeat(4, 24%);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 49% 49%;
    }
    .small-chart-card-wrapper{
      background: #151B2B;
      border-radius: 10px;
      padding: 10px 10px 7px;
      position: relative;
      .name-title{
        display: grid;
        grid-template-columns: 13px auto;
        align-items: center;
        gap: 4px;
        justify-content: center;
        img{
          width: 12px;
          height: 12px;
          object-fit: contain;
        }
      }
      .content-wrapper{
        text-align: center;
        padding: 15px 10px 0;
        h2{
          font-size: 30px;
          @media screen and (max-width: 1199px) and (min-width: 767px) {
            font-size: 24px !important;
          }
        }
        .average-text{
          background: $body-color;
          border-radius: 4px;
          display: inline-flex;
          align-items: center;
          gap: 5px;
          padding: 2px 9px;
          margin: 0 0 12px 0;
          .dot{
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #FF6400;
          }
          span{
            color: $text-primary02;
          }
          img{
            width: 9px;
            height: 9px;
            object-fit: contain;
          }
        }
      }
      button{
        background: transparent;
        border: none;
        position: absolute;
        top: 2px;
        right: 10px;
        padding: 0;
        img{
          width: 14px;
          height: 14px;
          object-fit: contain;
        }
      }
    }
  }
}
.select-time-main-wrapper{
  background: #1F2836;
  border-radius: 15px;
  padding: 30px 55px 25px 36px;
  grid-template-columns: 49% 49%;
  gap: 2%;
  max-width: 750px;
  width: 100%;
  position: absolute;
  right: 0;
  z-index: 9;
  // top: 90px;
  display: none;
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    margin-top: 10px;
    align-items: center;
  }
  &.active{
    display: grid;
  }
  &.chart{
    @media screen and (max-width: 767px) {
      // top: 120px;
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      // top: 65px;
    }
  }
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    // top: 130px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 10px 20px 12px;
    // top: 190px;
    grid-template-columns: 39% 59%;
    margin: 10px 0 0;
  }
  form{
    ul.time{
      li{
        display: flex;
        align-items: center;
        gap: 25px;
        &:not(&:last-of-type){
          margin: 0 0 27px;
          @media screen and (max-width: 767px) {
            margin: 0 0 15px;
          }
        }
        input[type="radio"]{
          width: 14px;
          height: 14px;
          margin: 0 0 0 0;
        }
        label{
          color: $white;
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            font-size: 12px;
            line-height: 16px;
          }
          @media screen and (max-width: $screen-xs) {
            font-size: 8px;
            line-height: 12px;
          }
          input{
            width: 37px;
            height: 27px;
            background: #1A2231;
            border-radius: 5px;
            border: none;
            outline: none;
            box-shadow: none;
            color: $white;
            @media screen and (max-width: $screen-xs) {
              height: 20px;
              margin-inline: 5px;
              border-radius: 4px;
            }
          }
          &:after{
            content: "";
            width: 11px;
            height: 11px;
            background: #00F2EA;
            border: 4px solid #1F2836;
            border-radius: 50%;
            position: absolute;
            left: 2px;
            top: 7px;
            display: none;
            @media screen and (max-width: $screen-xs) {
              width: 9px;
              height: 9px;
              border: 3px solid #1F2836;
              top: 50%;
              left: 1.2px;
              transform: translateY(-50%);
            }
          }
        }
        input[type="radio"] {
          display: none;
        }

        /* Style the custom radio button */
        input[type="radio"] + label {
          display: inline-block;
          position: relative;
          padding-left: 45px;
          cursor: pointer;
          @media screen and (max-width: $screen-xs) {
            padding-left: 20px;
          }
        }

        /* Style the radio button indicator */
        input[type="radio"] + label:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 16px; /* Size of the radio button */
          height: 16px; /* Size of the radio button */
          border-radius: 50%; /* Make it round */
          background-color: transparent; /* Background color */
          border: 3px solid #646F7C;
          @media screen and (max-width: 1366px) and (min-width: 1200px) {

          }
          @media screen and (max-width: $screen-xs) {
            width: 12px;
            height: 12px;
            border: 1px solid #646F7C;
          }
        }

        /* Style the radio button indicator when checked */
        input[type="radio"]:checked + label:before {
          background-color: $cyan; /* Change the background color when checked */
          border: 3px solid $cyan;
        }
        input[type="radio"]:checked + label:after {
          display: block;
        }
      }
    }
  }
  .calendar-wrapper{
    text-align: right;
    @media screen and (max-width: 767px) {
      text-align: center;
    }
    .select-time{
      margin: 5px 0 15px;
      @media screen and (max-width: 767px) {
        margin: 0px 0 10px;
      }
      .select{
        border: 1px solid rgba(100, 111, 124, 0.13);
        border-radius: 10px;
        padding: 7px 8px 7px 17px;
        display: inline-block;
        min-width: 122px;
        text-align: left;
        @media screen and (max-width: $screen-xs) {
          padding: 0px 8px 4px;
          border-radius: 5px;
          min-width: 80px;
          height: auto;
        }
        span{
         @media screen and (max-width: $screen-xs) {
           font-size: 8px;
           line-height: 12px;
           margin: 0 10px !important;
         }
       }
      }
      span{
        @media screen and (max-width: $screen-xs) {
          font-size: 8px;
          line-height: 12px;
          margin: 0 10px !important;
        }
      }
    }
  }
}
.loading-screen-component{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0  30px;
  .main-wrapper{
    text-align: center;
    .spinner-icon{
      .spinner-border{
        border-color: $white;
      }
      .SpinnerIcon{
        animation: spin 1s linear infinite;
        width: 53px;
        height: 53px;
        border-radius: 50%;
        object-fit: contain;
        margin-inline: auto;
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
    .content-wrapper{
      p{
        margin: 12px 0 0 0;
        color: $text-primary02;
      }
    }
    .progress-bar-wrapper{
      margin: 47px 0 0 0 ;
      text-align: center;
      .progress{
        background-color:#191F2F;
        height: 7px;
        max-width: 338px;
        width: 100%;
        margin-inline: auto;
        .progress-bar{
          background-color : $cyan;
        }
      }
    }
  }
}

//************************************ Pages Scss ***********************************

.bg-img{
  background-image: url('./assets/images/sign-up-bg-img.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  height: 100vh;
}
.signup-page-main-wrapper{
  background-image: url('./assets/images/sign-up-bg-img.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .singup-page{
    max-width: 610px;
    width: 100%;
    margin-inline: auto;
    // padding: 128px 0 140px;
    text-align: center;
    position: relative;
    @media screen and (max-height: 800px) {
      padding: 128px 0 140px;
    }
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      padding: 50px 0;
    }

    @media screen and (max-width: 767px) {
      padding: 50px 0 30px;
    }
    &.sign-in{
      max-width: 710px;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        max-width: 600px;
      }
    }
    &.reset{
      max-width: 520px;
    }
    .inner-page-wrapper{
      a.btn-style.colored-border{
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 11px;
          line-height: 18px;
          padding: 4px 18px !important;
          border: 2px solid transparent;
        }
        @media screen and (max-width: 767px) {
          font-size: 8px;
          line-height: 14px;
          padding: 4px 10px !important;
          border: 1px solid transparent;
        }
      }
      h1{
        margin: 20px 0 16px 0;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 40px !important;
          line-height: 50px !important;
        }
        @media screen and (max-width: 991px) and (min-width: 767px) {
          font-size: 32px !important;
          line-height: 40px !important;
        }
        @media screen and (max-width: 767px) {
          font-size: 24px !important;
          line-height: 32.6px !important;
        }
      }
      p{
        &.large{
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 14px;
            line-height: 22px;
          }
          @media screen and (max-width: 991px) and (min-width: 767px) {
            font-size: 16px;
            line-height: 22px;
          }
          @media screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 20px;
            padding: 0 30px;
          }
        }
      }
    }
    .form-wrapper{
      text-align: left;
      max-width: 390px;
      width: 100%;
      margin-inline: auto;
      margin-top: 43px;
      @media screen and (max-width: 767px) {
        margin-top: 30px;
      }
      &.text-center{
        text-align: center;
        form.form{
          a.btn-style{
            width: 100%;
            margin: 0 0 16px;
          }
        }
      }
      p.p{
        padding: 0 0 0 25px;
        margin: 0 0 20px;
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
      p{
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          font-size: 14px;
          line-height: 20px;
        }
        @media screen and (max-width: 767px) {
          font-size: 10px;
          line-height: 16px;
        }
      }
      form.form{
        position: relative;
        .input-wrapper{
          position: relative;
        }
        .error{
          color: #AAAAAA;
    margin: 0 0 16px;
    padding: 0 0 0 10px;
    position: absolute;
    left: 108%;
    top: 50%;
    transform: translateY(-50%);
    text-wrap: wrap;
    max-width: 350px;
    width: 100%;
          @media screen and (max-width: 1199px) {
            position: relative;
            top: unset;
            left: 0px;
            transform: none;
            padding-left: 25px;
          }
          &:before{
            content: '';
            display: block;
            background-image: url("./assets/images/icons/cross-red-error-icon.png");
            position: absolute;
            left: -12px;
            top: 5px;
            width: 14px;
            height: 14px;
            background-size: contain;
            background-repeat: no-repeat;
            @media screen and (max-width: 1199px) {
              left: 5px !important;
            }
          }
        }
        .form-group{
          margin: 0 0 16px;
          padding: 15px;
          border: 3px solid #FFFFFF0F;
          transition: $transition;
          display: flex;
          align-items: center;
          gap: 21px;
          @media screen and (max-width: 1440px) and (min-width: 768px) {
            padding: 12px 13px;
          }
          @media screen and (max-width: 767px) {
            padding: 10px 13px;
            border: 2px solid #FFFFFF0F;
            border-radius: 8px;
          }
          &:hover, &:focus, &.active{
            background: linear-gradient(#121828, #121828) padding-box,linear-gradient(to right, #00F2EA, #5352B0) border-box;
            transition: $transition;
            border: 3px solid transparent;
            @media screen and (max-width: 767px) {
              border: 2px solid transparent;
            }
            input.form-control{
              &::placeholder{
                color: $white;
              }
            }
          }

          img{
            width: 25px;
            height: 25px;
            object-fit: contain;
            min-width: 25px;
            @media screen and (max-width: 1440px) and (min-width: 768px) {
              width: 20px;
              height: 20px;
              min-width: 20px;
            }
            @media screen and (max-width: 767px) {
              width: 16px;
              height: 16px;
              min-width: 16px;
            }
          }
          svg{
            width: 22px;
            height: 22px;
            object-fit: contain;
            min-width: 25px;
            @media screen and (max-width: 1440px) and (min-width: 768px) {
              width: 18px;
              height: 18px;
              min-width: 18px;
            }
            @media screen and (max-width: 767px) {

            }
          }
          input.form-control{
            height: 20px;
            transition: $transition;
            @media screen and (max-width: 991px) and (min-width: 768px) {
              font-size: 12px;
            }
            @media screen and (max-width: 767px) {
              font-size: 10px;
            }
            &::placeholder{
              @media screen and (max-width: 991px) and (min-width: 768px) {
                font-size: 12px;
              }
              @media screen and (max-width: 767px) {
                font-size: 10px;
              }
            }
          }
          .react-tel-input{
            padding: 0 0 0 4px;
            .form-control {
              background: transparent;
              background-color: transparent;
              border: none;
              padding-left: 52px;
            }
            .flag-dropdown {
              background: transparent;
              background-color: transparent;
             border: none;
             .selected-flag{
              background: transparent !important;
              background-color: transparent !important;
              padding-left: 0 !important;
             }
             &:hover{
               background: transparent;
               background-color: transparent;
             }
            }
            .country-list {
              background: #1b1c3b !important;
              border-radius: 8px;
              overflow-x: hidden;
              &::-webkit-scrollbar {
                width: 5px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #1b1c3b;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #81868E;
              }

              .country{
                background: transparent !important;
                background-color: transparent !important;
                display: block;
              }
            }
          }
          /* Change the white to any color */
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 1000px $body-color inset !important;
            background: transparent !important;
            outline: none;
            background-color: transparent !important;
          }
          input:-webkit-autofill{
            -webkit-text-fill-color: $text-primary01 !important;
          }
        }
        .infoIcon{
          position: absolute;
          right: -40px;
          top: 15px;
          @media screen and (max-width: 991px) {
            display: none;
          }
          &:hover{
            .your-privacy-text{
              width: 358px;
              padding: 10px 27px 10px 30px;
              visibility: visible;
              opacity: 1;
              transition: $transition;
              @media screen and (max-width: 1199px) and (min-width: 992px) {
                width: 200px;
              }
            }
          }
          img, svg{
            width: 24px;
            height: 24px;
            object-fit: contain;

          }
          .your-privacy-text{
            text-align: left;
            background: #191F2F;
            border-radius: 15px;
            max-width: 358px;
            width: 358px;
            position: absolute;
            top: -40px;
            left: 45px;
            visibility: hidden;
            opacity: 0;
            padding: 0;
            transition: $transition;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              width: 200px;
            }
          }
        }
        .reset-pass-btn{
          width: 100%;
          @media screen and (max-width: 1440px) and (min-width: 768px) {
            padding: 12px 10px;
            font-size: 14px;
            line-height: 20px;
          }
          @media screen and (max-width: 767px) {
            font-size: 11px;
            line-height: 14px;
            padding: 9px 10px;
            border-radius: 6px;
          }
        }
      }
      .agree-text{
        display: flex;
        align-items:center;
        gap: 16px;
        position: relative;
        input[type="checkbox"] {
          border: 1px solid #FFFFFF0F;
          border-radius: 20px;
          box-shadow: 25px 4px 0px 0px #1B1C3B inset;
          width: 20px;
          height: 20px;
          position: relative;
          &:after{
            content: '';
            position: absolute;
            background: transparent;
            border: 2px solid #FFFFFF0F;
            width: 100%;
            height: 100%;
            box-shadow: 0px 4px 15px 0px #1B1C3B inset;
          }
        }
        input[type="checkbox"]:checked::after {
          display: none;
        }

        input[type=checkbox]:checked+label::before {
          content: "";
          display: block;
          position: absolute;
          text-align: center;
          height: 20px;
          width: 20px;
          left: 0px;
          top: 3px;
          // background-color: $pink;
          background: linear-gradient(130deg, #00F2EA , #5352B0);
          font-family: "Montserrat";
          border-radius: 2px;
          border: 1px solid rgb(150 150 150 / 30%);
        }

        input[type=checkbox]:checked+label::after {
          content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
          display: block;
          position: absolute;
          left: 3px;
          top: 3px;
        }
        label{
          color: $white;
          font-size: 16px;
          line-height: 25.6px;
          margin: 0 0 0 0;
          a{
            color: $pink;
            text-decoration: underline !important;
          }
        }
      }
      .signup-with{
        display: flex;
        align-items: center;
        gap: 19px;
        margin: 16px 0 40px;
        justify-content: center;
        p{
          margin: 0 0 0 0;
        }
        ul.icon-list{
          li{
            &:not(&:last-of-type){
              margin: 0 10px 0 0;
            }
            a.btn-style.transparent{
              min-width   : auto;
              width: 56px;
              height: 56px;
              padding: 0;
              img, svg{
                width: 30px;
                height: 30px;
                object-fit: contain;
              }
            }
          }
        }
        .btn-style{
          padding: 18px 10px;
          border-radius: 12px;
          @media screen and (max-width: 1440px) and (min-width: 768px) {
            padding: 12px 10px;
            font-size: 14px;
            line-height: 20px;
          }
          @media screen and (max-width: 767px) {
            font-size: 11px;
            line-height: 14px;
            padding: 11px 10px;
            border-radius: 6px;
          }
        }
      }
      .already-signedin{
        text-align: center;
        margin: 20px 0 0;
        &.remember-pass{
          margin: 40px 0 0 0;
        }
        p{
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            font-size: 14px;
            line-height: 20px;
          }
          @media screen and (max-width: 991px) and (min-width: 768px) {
            font-size: 12px;
            line-height: 16px;
          }
          @media screen and (max-width: 767px) {
            font-size: 11px;
            line-height: 16px;
          }
          a{
            text-decoration: underline !important;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              font-size: 14px;
              line-height: 20px;
            }
            @media screen and (max-width: 991px) and (min-width: 768px) {
              font-size: 12px;
              line-height: 16px;
            }
            @media screen and (max-width: 767px) {
              font-size: 11px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
.scan-profile{
  .scan-profile-inner-content{
    height: calc(100vh - 180px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    text-align: center;
    @media screen and (max-width: 991px) and (min-width: 768px) {
      gap: 5px;
    }
    @media screen and (max-width: 767px) {
      gap: 10px;
    }
    h1{
      margin: 0 0 0px 0;
      font-size: 42px !important;
      line-height: 50.4px !important;
      @media screen and (max-width: 767px) {
        font-size: 22px !important;
        line-height: 30px !important;
      }
    }
    p{
      @media screen and (max-width: 767px) {
        font-size: 10px;
        line-height: 16.94px;
      }
    }
    .search-filter-wrapper{
      .search-filter-bar{
        border: 1px solid #FFFFFF0F;
        box-shadow: none;
        border-radius: 10px;
        padding: 5px 6px 5px 12px;
        background: #181E2E;
        .form-group{
          span{
            font-size: 14px;
            line-height: 16.94px;
            @media screen and (max-width: 767px) {
              font-size: 12px;
              line-height: 16.94px;
            }
          }
          input{
            font-size: 14px;
            line-height: 16.94px;
            color: #81868E;
            @media screen and (max-width: 767px) {
              font-size: 12px;
              line-height: 16.94px;
            }
            &::placeholder{
              font-size: 14px;
              line-height: 16.94px;
              color: #81868E;
              @media screen and (max-width: 767px) {
                font-size: 12px;
                line-height: 16.94px;
              }
            }
          }
        }
        .dropdown-center{
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }

        .btn-style, .dropdown-toggle{
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          gap: 7px;
          font-size: 14px;
          line-height: 16.94px;
          font-weight: 700;
          box-shadow: none;
          border-radius: 7px;
          padding: 9px 21px;
          min-width: 200px;
          background: $cyan;
          color: $text-blue;
          border: 2px solid transparent;
          @media screen and (max-width: 767px) {
            font-size: 11px;
            line-height: 16px;
            padding: 7px 21px;
            width: 100%;
          }
          &:hover{
            transition: all 0.3s ease-in-out;
            opacity: 0.9;
            box-shadow: none !important;
          }
          &:after{
            display: none;
          }
          img, svg{
            width: 21px;
            height: 21px;
            @media screen and (max-width: 767px) {
              width: 18px;
              height: 18px;
            }
          }
        }
        .dropdown-menu{
          background: #152A47;
          padding: 3px;
          border-radius: 8px;
          width: 100%;
          margin: 5px 0 0 0;
          .dropdown-item{
            display: inline-flex;
            align-items: center;
            gap: 9.4px;
            color: $white;
            border-radius: 4px;
            font-size: 12px;
            line-height: 14.52px;
            font-weight: 500;
            opacity: 70%;
            padding: 11px 8px;
            transition: .3s ease-in-out all;
            @media screen and (max-width: $screen-xs){
              font-size: 10px;
              line-height: 12.52px;
            }
            &:hover, &:active, &.active{
              background: #6067794A;
              opacity: 1;
              transition: .3s ease-in-out all;
            }
            &:not(&:last-of-type){
              margin: 0 0 3px;
            }
            img, svg{
             filter: brightness(0) saturate(100%) invert(98%) sepia(100%) saturate(6%) hue-rotate(142deg) brightness(102%) contrast(103%);;
              width: 19.48px;
              height: 19.48px;
              transition: .3s ease-in-out all;
              @media screen and (max-width: $screen-xs){
                width: 16.48px;
                height: 16.48px;
              }
              &.small{
                width: 19.48px;
                height: 14.48px;
                @media screen and (max-width: $screen-xs){
                  width: 16.48px;
                  height: 12.48px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.profile-not-found{
  //background: linear-gradient(95.46deg, #F6743E -6.96%, #D42525 108.25%);
  background: $white;
  border: 1px solid #FFD8D8;
  display: grid;
  grid-template-columns: 24px auto;
  gap: 16px;
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
  max-width:400px;
  margin-inline: auto;
  width: 100%;
  .closeIcon{
    width: 18px;
    height: 18px;
    object-fit: contain;
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }
  .img-wrapper{
    img{
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }
  .text-wrapper{
    text-align: left;
    strong{
      color: #212121;
      margin: 0 0 4px;
      display: block;
      padding: 0 20px 0 0;
    }
    span{
      color: #212121;
      display: block;

    }
  }
}
.searces-tips-main-wrapper{
  background: $dark-blue;
  max-width: 123px;
  width: 100%;
  border-radius: 15px;
  padding: 11px;
  position: fixed;
  right: 0;
  top: 160px;
  height: auto;
  z-index: 22;
  @media screen and (max-width: 1440px) and (min-width: 1200px) {
    padding: 11px 8px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
  &.active{
    height: calc(100vh - 210px);
    max-width: 360px;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      max-width: 320px;
    }
    .inner-wrapper{
      display: block;
      height: calc(100% - 70px);
    }
    .title{
      a{
        background: linear-gradient(to right, #173341 6%, $dark-blue);
        border-left: 3px solid #00F2EA;
        .text{
          strong{
            display: block;
          }
        }
        img{
          display: none;
          &.tipsInfoIcon{
            display: block;
          }
          &.tipsInfoWhiteIcon{
            display: none;
          }
        }
      }
    }
  }
  .title{
    a{
      display: flex;
      align-items: center;
      gap: 16px;
      border-left: 3px solid #31394F;
      background: transparent;
      padding: 12px;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        padding: 8px 12px;
        gap: 10px;
      }
      .text{
        strong{
          color: $cyan;
          display: none;
          text-wrap: nowrap;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
      img{
        display: none;
        &.tipsInfoWhiteIcon{
          display: block;
        }
      }
    }
  }
  .inner-wrapper{
    padding: 45px 10px 0px;
    overflow-y: scroll;
    height: auto;
    display: none;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      padding: 35px 5px 0;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #81868E;
      border-radius: 50px;
    }
    .content-wrapper{
      padding: 16px;
      background: $body-color;
      border-radius: 10px;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        padding: 10px;
      }
      &:not(&:last-of-type){
        margin: 0 0 10px;
      }
      strong{
        color: $white;
        margin: 0 0 5px;
        display: block;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
      span{
        display: block;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}
.profile-ratings{
  .filter-section{
    background: linear-gradient(to right, #105761 , #541b3a);
    //background-image: url("./assets/images/filter-bg-img.png"), linear-gradient(to right, #105761 , #541b3a); /* W3C */
    background-size: cover;
    //filter: blur(8px);
    padding: 97px 0 205px;
    &.tiktok{
      background: linear-gradient(to right, #105761 , #541b3a);
    }
    &.youtube{
      background: linear-gradient(to right, #FF1417 , #C70D0D);
    }
    &.instagram{
      background: linear-gradient(to right, #FF6400 ,#FE001F, #FD0056);
    }
    .search-filter-bar{
      max-width: 680px;
      background: #121828;
    }
  }
  .main-content-wrapper{
    margin: -100px auto 0;
    padding: 0 0 100px;
    width: 85%;
    @media screen and (max-width: 1600px) {
      width: 100%;
    }
    .user-detail-wrapper{
      background: $dark-blue;
      border-radius: 15px;
      padding: 24px 24px 17px;
      margin: 0 0 37px;

      .col-xl-5.col-lg-7.col-md-7{
        @media screen and (max-width: 767px) {
          margin: 40px 0;
        }
      }
      .col-xl-4.col-lg-12.col-md-12{
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          margin: 50px 0 0 0;
        }
      }
      .user-detail{
        height: 100%;
        .avatar{
          display: grid;
          grid-template-columns: 67px auto;
          align-items: center;
          gap: 20px;
          margin: 0 0 12px;
          .img-wrapper{
            img{
              width: 67px;
              height: 67px;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .text-wrapper{
            p{
              color: #CCDAE7;
              margin: 0 0 4px 0;
            }
          }
        }
        span{
          color: #646F7C;
          font-weight: 500;
          line-height: 20px;
        }
      }
      .ratings-overview{
        display: grid;
        grid-template-columns:auto auto;
        justify-content: center;
        gap: 39px 60px;
        height: 100%;
        .overview{
          display: grid;
          grid-template-columns: 31px auto;
          gap: 15px;
          .img-wrapper{
            img{
              width: 31px;
              height: 31px;
              object-fit: contain;
            }
          }
        }
      }
      .overview-img-wrapper{
        height: 100%;
        .content{
          padding: 15px 21px 18px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 0 16px;
          &.Emerging{
            background: linear-gradient(to right , #4f5cbd, #4478df , #0062e0);
          }
          &.Growing{
            background: linear-gradient(to right , #34A853, #8CE383 , #90FF5B);
          }
          &.Established{
            background: linear-gradient(to right , #FBBC05, #FFEB45 , #F8AB13);
          }
          &.Popular{
            background: linear-gradient(to right , #FF6E1C, #FDA438 , #FBB605);
          }
          &.Trendsetting{
            background: linear-gradient(to right , #FF0000, #B40000 , #FF2626);
          }
          .text{
            h2.large{
              font-size: 64px !important;
              line-height: 74px !important;
              @media screen and (max-width: 767px) {
                font-size: 50px !important;
                line-height: 60px !important;
              }
            }
            .title{
              display: inline-flex;
              align-items: center;
              background: $white;
              border-radius: 7px;
              padding: 10px 24px ;
              position: relative;
              gap: 15px;
              cursor: pointer;
              &:hover{
                .profile-info-text{
                  display: block;
                }
              }
              .dot{
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #5352B0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 11px;
              }
              span{
                color: #646F7C;
              }
              img{
                width: 17px;
                height: 17px;
                object-fit: contain;
              }
              .profile-info-text{
                display: none;
                position: absolute;
                left: 115%;
                background: $white;
                border-radius: 10px;
                min-width: 400px;
                padding: 14px 28px;
                z-index: 3;
                @media screen and (max-width: 2000px) and (min-width: 1901px) {
                  min-width: 370px;
                }
                @media screen and (max-width: 1600px) and (min-width: 1200px) {
                  min-width: 320px;
                }
                @media screen and (max-width: 1900px) and (min-width: 1200px) {
                  top: 55px;
                  left: 0;
                  &:before{
                    top: -9px !important;
                    left: 30px !important;
                    transform: none;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent !important;
                    border-bottom: 10px solid #ffffff !important;
                  }
                }
                @media screen and (max-width: 1350px) and (min-width: 1200px) {
                  min-width: 270px;
                }
                @media screen and (max-width: 767px) {
                  left: 0;
                  min-width: 300px;
                  top: 55px;
                  &:before{
                    top: -9px !important;
                    left: 30px !important;
                    transform: none;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent !important;
                    border-bottom: 10px solid #ffffff !important;
                  }
                }
                &:before{
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: -10px;
                  transform: translateY(-50%);
                  width: 0;
                  height: 0;
                  border-top: 10px solid transparent;
                  border-bottom: 10px solid transparent;
                  border-right:10px solid $white;
                }
                span{
                  color: #121828;
                  display: block;
                  font-weight: 500;
                }
              }
            }
          }
          .img{
            img{
              height: 104px;
              object-fit: contain;
              width: 100%;
            }
          }
        }
        ul.listings{
          li{
            color: #646F7C;
            padding: 0 0 0 9px;
            font-size: 12px;
            position: relative;
            &:nth-child(odd){
              margin: 0 20px 0 0;
            }
            span.dot{
              width: 6px;
              height: 6px;
              border-radius: 50%;
              position: absolute;
              left: -3px;
              top: 48%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
    .total-overview-with-charts{
      margin: 0 0 75px;
      .total-overview-main-wrapper{
        display: grid;
        grid-template-columns: 19% 19% 19% 19% 19%;
        gap: 15px;
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          grid-template-columns: 49% 49%;
        }
        @media screen and (max-width: 767px) {
          grid-template-columns: 100%;
        }
        .total-overview{
          padding: 35px 12px 12px;
          background: $dark-blue;
          border-radius: 12px;
          position: relative;
          .captureIcon{
            width: 14px;
            height: 14px;
            object-fit: contain;
            position: absolute;
            top: 10px;
            right: 10px;
          }
          .rating{
            display: flex;
            align-items: center;
            gap: 5px;
            margin: 9px 0 11px;
            h3{
              margin: 0;
            }
            .text{
              display: flex;
              align-items: center;
              gap: 5px;
              img{
                width: 16px;
                height: 16px;
                object-fit: contain;
              }
              &.down{
                .up-arrow{
                  display: none;
                }
                span{
                  color: #EA4335;
                }
              }
              &.up{
                .down-arrow{
                  display:  none;
                }
                span{
                  color: #34A853;
                }
              }
            }
          }
          .rank{
            display: inline-flex;
            align-items: center;
            gap: 5px;
            .dot{
              width: 6px;
              height: 6px;
              border-radius: 50%;
            }
            img{
              width: 18px;
            }
          }
          button{
            background: transparent;
            border: none;
          }
        }
      }
    }
    .download-btns-wrapper{
      text-align: center;
      a{
        max-width: 382px;
        width: 100%;
        font-weight: 400;
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          max-width: max-content;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        &:not(&:last-of-type){
          margin: 0 22px 0 0;
          @media screen and (max-width: 767px) {
            margin: 0 0 10px 0;
          }
        }
        img{
          filter: none;
          width: 18px;
          object-fit: contain;
        }
      }
    }
  }
}
.my-profiles-page{
  .dashboard-inner-page{
    padding: 45px 54px 50px;
    @media screen and (max-width: 1199px) and (min-width: 992px) {
      padding: 30px 30px 40px;
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      padding: 25px 20px 40px;
    }
    @media screen and (max-width: 767px) {
      padding: 25px 15px 30px;
    }
    h1{
      margin: 0 0 30px;
    }
    .col-xl-5.col-lg-12.col-md-12{
      @media screen and (max-width: 1199px) {
        margin: 0 0 30px 0;
      }
    }
    .main-wrapper{
      border-radius: 15px;
      background: $dark-blue;
      padding: 33px 30px 32px;
      text-align: center;
      height: 100%;
      @media screen and (max-width: 991px) and (min-width: 768px) {
        padding: 20px 20px 25px;
      }
      @media screen and (max-width: 767px) {
        padding: 20px 20px 30px;
      }
      .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3.h3{
          color:$pink !important;
        }
      }
      .profile-pic-wrapper{
        text-align: center;
        margin: 46px 0 32px;
        img.avatarIcon{
          width: 180px;
          height: 180px;
          border-radius: 50%;
          display: block;
          margin: 0 auto 9px;
        }
        button.change-profile-pic{
          color: $cyan;
          background: $body-color;
          padding: 19px 30px 18px;
          img{
            filter: brightness(0) saturate(100%) invert(72%) sepia(61%) saturate(2317%) hue-rotate(126deg) brightness(103%) contrast(104%);
          }
        }
      }
      .form-wrapper{
        form.form{
          text-align: center;
          .form-group{
            display: flex;
            align-items: center;
            gap: 8px;
            img{
              width: 24px;
              height: 24px;
            }
          }
          button.save-btn{
            margin: 40px 0 0 ;
            min-width: 210px;
          }
        }
      }
      .package-price{
        background: $body-color;
        border-radius: 15px;
        padding: 35px 20px 30px;
        position: relative;
        display: inline-block;
        max-width: 260px;
        width: 100%;
        text-align: center;
        margin: 55px auto 40px;
        .three-dots-icon{
          position: absolute;
          right: 15px;
          top: 15px;
          color: $white;
          background: transparent;
          padding: 0;
          button{
            background: transparent;
            padding: 0;
            border: none;
            &:after{
              display: none;
            }
          }
          img, svg{
            color: $white;
            width: 24px;
            height: 24px;
          }
        }

        .dropdown-menu{
          background: $body-color;
          padding: 13px 5px;
          border: 1px solid rgba(40, 44, 47, 0.59);
          //inset: -13px auto auto 20px !important;
          @media screen and (max-width: 1199px) {
            //inset: 0 auto auto 0 !important;
          }
          a{
            padding: 10px 20px;
            background: transparent;
            font-size: 16px;
            &:not(&:last-of-type){
              border-bottom: 1px solid rgba(100, 111, 124, 0.15);
            }
            img{
              width: 17px;
              height: 17px;
              object-fit: contain;
              margin: 0 10px 0 0;
            }
            &.orange{
                color: #EA4335;
              img{
                filter: brightness(0) saturate(100%) invert(35%) sepia(60%) saturate(1606%) hue-rotate(334deg) brightness(92%) contrast(99%);
              }
            }
            &.light-orange{
              color: #FF6E1C;
              img{
                filter: brightness(0) saturate(100%) invert(50%) sepia(39%) saturate(3569%) hue-rotate(348deg) brightness(105%) contrast(101%);
              }
            }
          }
        }
        h2.large{
          color: $pink !important;
          margin: 0 0 10px;
        }
        .view-plan{
          margin: 34px 0 0 ;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 9px;
          img, svg{
            width: 22px;
            height: 22px;
            object-fit: contain;
          }
          p{
            margin: 0 0 0 0;
            color: $white;
          }
        }
      }
      .package-facilities{
        display: grid;
        grid-template-columns: 49% 49%;
        gap: 17px;
        @media screen and (max-width: $screen-xs) {
          display: block;
        }
        .package{
          background: $body-color;
          border-radius: 15px;
          padding: 20px 23px 25px 22px;
          box-shadow: inset 0px 4px 15px 0px rgba(0, 58, 56, 0.36);
          text-align: left;
          @media screen and (max-width: 991px) {
            margin: 0 0 20px 0;
          }
          strong{
            color: #646F7C;
          }
          .progress{
            height: 8px;
            margin: 22px 0;
            --bs-progress-bg:#191F2F;
            .progress-bar{
              background: $cyan;
              height: 8px;
            }
          }
          p{
            margin: 0 0 0 ;
            color: $white;
          }
        }
      }
    }
  }
}
.settings-page{
  .dashboard-inner-page{
    padding: 45px 54px 50px;
    @media screen and (max-width: 1199px) and (min-width: 992px) {
      padding: 30px 30px 40px;
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      padding: 25px 0px 40px;
    }
    @media screen and (max-width: 767px) {
      padding: 25px 15px 30px;
    }
    .page-title{
      margin: 0 0 30px;
      @media screen and (max-width: 991px) and (min-width: 768px) {
        margin: 0 0 10px;
      }
      @media screen and (max-width: 767px) {
        margin: 0 0 10px;
      }
      h1{
        margin: 0 0 0 0;
        @media screen and (max-width: 767px) {
          margin: 0 0 20px 0;
        }
      }

    }
    .page-inner-body-wrapper{
      display: flex;
      gap: 16px;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .nav-tabs-wrapper{
        max-width: 260px;
        width: 100%;
        border: 1px solid #272C3B;
        background: #191F2F;
        border-radius: 8px;
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          max-width: 220px;
        }
        @media screen and (max-width: 767px) {
          max-width: 100%;
        }
        .menu-wrapper{
          padding: 8px;
          &:last-of-type{
            border-top: 1px solid #272C3B;
            margin: 76px 0 0 0;
          }
          ul.nav-tabs-list{
            background: $dark-blue;
            li{
              display: block;
              &:not(&:last-of-type){
                margin: 0 0 2px;
              }
              a.nav-tab{
                padding: 11.5px 8px;
                display: flex;
                align-items: center;
                gap: 12px;
                color: $white;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                border-radius: 8px;
                cursor: pointer;
                transition: .3s ease-in-out all;
                @media screen and (max-width: 1199px) {
                  font-size: 12px;
                  line-height: 18px;
                  gap: 7px;
                  padding: 9px 10px;
                  border-radius: 5px;
                }
                img, svg{
                  width: 20px;
                  height: 20px;
                  object-fit: contain;
                  @media screen and (max-width: 1199px) {
                    width: 17px;
                    height: 17px;
                  }
                }
                &.active, &:hover{
                  background: #23293A;
                  transition: .3s ease-in-out all;
                }
              }
            }
          }
        }

      }
      .tabs-main-content-wrapper{
        width: 100%;
        .tab-main-wrapper{
          background: #191F2F;
          border-radius: 8px ;
          height: 100%;
          border: 1px solid #272C3B;
          @media screen and (max-width: 1199px) {
            height: auto;
          }
          .title-wrapper{
            border-bottom: 1px solid #272C3B;
            padding: 15px 30px;
            @media screen and (max-width: 1199px) {
              padding: 10px 15px;
            }
            img, svg{
              @media screen and (max-width: 767px) {
                width: 14px;
                object-fit: contain;
                height: auto;
              }
            }
            h3{
              font-size: 20px !important;
              line-height: 25px !important;
              text-align: left;
              margin: 0 0 !important;
              @media screen and (max-width: 1199px) and (min-width: 768px) {
                font-size: 14px !important;
                line-height: 20px !important;
              }
              @media screen and (max-width: 767px) {
                font-size: 12px !important;
                line-height: 16px !important;
              }
            }
          }
          .tab-body-wrapper{
            padding: 28px 30px;
            @media screen and (max-width: 1199px) {
              padding: 15px 15px;
            }
          }
          .profile-detail-wrapper{
            padding: 0 0 0;
            .form-wrapper{
              .form.new-form{
                text-align: left;
                .save-btn{
                  font-size: 16px;
                  border-radius: 8px;
                  padding: 10px 28px;
                  margin: 100px 0 0 40px;
    min-width: auto;
    height: auto;
                }
              }
            }
          }
          .billing-main-wrapper{
            .col-xl-7.col-lg-12.col-md-12{
              @media screen and (max-width: 1199px) {
                margin: 0 0 30px;
              }
            }
            .sub-title-wrapper{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .btn-style{
                color: #878A8D;
                gap: 4px;
                font-size: 14px;
                @media screen and (max-width: 1199px) {
                  font-size: 12px;
                  line-height: 16px;
                }
                img, svg{
                  filter: brightness(0) saturate(100%) invert(66%) sepia(10%) saturate(114%) hue-rotate(169deg) brightness(80%) contrast(88%);
                  width: 19px;
                  height: 19px;
                  object-fit: contain;
                  @media screen and (max-width: 1199px) {
                    width: 15px;
                    height: 15px;
                  }
                }
              }
            }
            .billing-wrapper{
              border-radius: 15px;
              padding: 16px 30px 35px;
              box-shadow: 0px 1px 24px 0px #091C421F;
              border: 1px solid #272C3B;
              border-radius: 16px;
              margin-bottom: 100px;
              @media screen and (max-width: 1199px) and (min-width: 768px) {
                padding: 15px 15px 10px;
                margin-bottom: 0;
              }
              @media screen and (max-width: $screen-xs) {
                padding: 15px 15px 10px;
                margin-bottom: 0;
              }
              .priocing-main-div{
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media screen and (max-width: $screen-xs) {
                  flex-direction: column-reverse;
                  gap: 15px;
                }
                .package-price{
                  // background: $body-color;
                  position: relative;
                  @media screen and (max-width: $screen-xs) {
                    width: 100%;
                  }
                  .three-dots-icon{
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    color: $white;
                    background: transparent;
                    padding: 0;
                    button{
                      background: transparent;
                      padding: 0;
                      border: none;
                      &:after{
                        display: none;
                      }
                    }
                    img, svg{
                      color: $white;
                      width: 24px;
                      height: 24px;
                    }
                  }
                  .package-name{
                    color: #FF165D;
                    font-size: 14px;
                    line-height: 22px;
                    display: inline-block;
                  }
                  h2{
                    font-size: 48px !important;
                    line-height: 60px !important;
                    font-weight: 600 !important;
                    margin: 10px 0 0 0;
                    @media screen and (max-width: 1199px) and (min-width: 768px) {
                      font-size: 30px !important;
                      line-height: 36px !important;
                    }
                    @media screen and (max-width: $screen-xs) {
                      font-size: 30px !important;
                      line-height: 36px !important;
                      margin: 3px 0 0;
                    }
                    &.white{
                      color: $white !important;
                    }
                    sub{
                      color: #939393;
                      font-size: 20px;
                      line-height: 24px;
                      font-weight: 400;
                      @media screen and (max-width: 1199px) {
                        font-size: 12px;
                        line-height: 16px;
                      }
                    }
                  }
                  .dropdown-menu{
                    background: $body-color;
                    padding: 13px 5px;
                    border: 1px solid rgba(40, 44, 47, 0.59);
                    //inset: -13px auto auto 20px !important;
                    @media screen and (max-width: 1199px) {
                      //inset: 0 auto auto 0 !important;
                    }
                    a{
                      padding: 10px 20px;
                      background: transparent;
                      font-size: 16px;
                      &:not(&:last-of-type){
                        border-bottom: 1px solid rgba(100, 111, 124, 0.15);
                      }
                      img{
                        width: 17px;
                        height: 17px;
                        object-fit: contain;
                        margin: 0 10px 0 0;
                      }
                      &.orange{
                        color: #EA4335;
                        img{
                          filter: brightness(0) saturate(100%) invert(35%) sepia(60%) saturate(1606%) hue-rotate(334deg) brightness(92%) contrast(99%);
                        }
                      }
                      &.light-orange{
                        color: #FF6E1C;
                        img{
                          filter: brightness(0) saturate(100%) invert(50%) sepia(39%) saturate(3569%) hue-rotate(348deg) brightness(105%) contrast(101%);
                        }
                      }
                    }
                  }
                  .view-plan{
                    margin: 34px 0 0 ;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 9px;
                    img, svg{
                      width: 22px;
                      height: 22px;
                      object-fit: contain;
                    }
                    p{
                      margin: 0 0 0 0;
                      color: $white;
                    }
                  }
                }
                .text-wrapper{
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  @media screen and (max-width: $screen-xs) {
                    width: 100%;
                    justify-content: space-between;
                  }
                  span{
                    color: #878A8D;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                    @media screen and (max-width: 1199px) {
                      font-size: 11px;
                      line-height: 14px;
                    }
                  }
                  .subs-end-date{
                    background: #FF165D;
                    border-radius: 50px;
                    color: $white;
                    line-height: normal;
                    font-weight: 600;
                    padding: 5px 8px 5px;
                  }
                }
              }
              .package-facilities{
                display: grid;
                grid-template-columns: 49% 49%;
                gap: 17px;
                @media screen and (max-width: $screen-xs) {
                  display: block;
                }
                .package{
                  background: $body-color-1;
                  border-radius: 15px;
                  padding: 20px 23px 25px 22px;
                  // box-shadow: inset 0px 4px 15px 0px rgba(0, 58, 56, 0.36);
                  border: 0.77px solid #FFFFFF0F;
                  text-align: left;
                  @media screen and (max-width: 991px) {
                    margin: 0 0 12px 0;
                    padding: 15px 15px 15px 15px;
                  }
                  strong{
                    color: #646F7C;
                  }
                  .progress{
                    height: 8px;
                    margin: 80px 0 24px;
                    --bs-progress-bg:#191F2F;
                    @media screen and (max-width: 1199px) and (min-width: 768px) {
                      margin: 30px 0 10px;
                    }
                    @media screen and (max-width: $screen-xs) {
                      margin: 30px 0 10px;
                      height: 5px;
                    }
                    .progress-bar{
                      background: $cyan;
                      height: 8px;
                      @media screen and (max-width: $screen-xs) {
                        height: 5px;
                      }
                    }
                  }
                  p{
                    margin: 0 0 0 ;
                    color: $white;
                  }
                }
              }
              .subscription-status{
                background: $body-color-1;
                border-radius: 15px;
                padding: 18px 20px 20px;
                // box-shadow: inset 0px 4px 15px 0px rgba(0, 58, 56, 0.36);
                text-align: left;
                margin: 85px 0 24px;
                border: 0.77px solid #FFFFFF0F;
                @media screen and (max-width: 991px) and (min-width: 768px) {
                  margin: 25px 0 20px;
                }
                @media screen and (max-width: $screen-xs) {
                  padding: 15px 15px 15px;
                  margin: 20px 0 10px;
                }
                .status{
                  display: flex;
                  justify-content: space-between;
                  @media screen and (max-width: $screen-xs) {
                    flex-direction: column;
                    gap: 10px;
                  }
                  p{
                    margin: 0 0 0 0;
                  }
                }
                .progress{
                  height: 8px;
                  margin: 15px 0;
                  --bs-progress-bg:#191F2F;
                  @media screen and (max-width: $screen-xs) {
                    height: 5px;
                  }
                  .progress-bar{
                    background: $cyan;
                    height: 8px;
                    @media screen and (max-width: $screen-xs) {
                      height: 5px;
                    }
                  }
                }
              }
            }
            .contact-our-team{
              background: $dark-blue;
              border-radius: 15px;
              height: 100%;
              padding: 35px 60px 60px;
              @media screen and (max-width: $screen-xs) {
                padding: 20px 20px 25px;
              }
              .img-wrapper{
                margin: 0 0 50px;
                text-align: center;
                img{
                  max-width: 270px;
                  width: 100%;
                  object-fit: contain;
                  margin-inline: auto;
                }
              }
              p{
                color: #646F7C;
                margin: 15px 0  24px;
              }
              form.form{
                .form-group{
                  img{
                    width: 24px;
                    height: 24px;
                    object-fit: contain;
                    position: absolute;
                    left: 19px;
                    top: 15px;
                  }
                  input, textarea{
                    padding: 5px 8px 5px 50px;
                  }
                }
                .send-btn{
                  max-width: 211px;
                  width: 100%;
                }
              }
            }
          }
          .notification-tab-content{
            padding: 30px 7px 60px;
            background: $dark-blue;
            border-radius: 15px;
            @media screen and (max-width: 991px) and (min-width: 768px) {
              padding: 10px 7px 30px;
            }
            @media screen and (max-width: $screen-xs) {
              padding: 20px 7px 30px;
            }
            .col-md-6{
              &:nth-child(odd){
                border-right: 1px solid rgba(100, 111, 124, 0.17);
                @media screen and (max-width: $screen-xs) {
                  border-right: none;
                }
              }
              &:last-child {
                border-right: none;
                .notification{
                  border-bottom: none;
                }
              }
              &:nth-last-child(2) {
                //border-right: none;
              }
            }
            .title-wrapper{
              border-bottom: 1px solid rgba(100, 111, 124, 0.17);
              padding: 0 30px 10px;
              margin: 0 0 6px;
              @media screen and (max-width: 1199px) {
                padding: 0 14px 10px;
              }
            }
            h3{
              color: $white;
              margin: 0 0 14px;
            }
            p{
              color: #646F7C;
            }
            strong{
              margin: 0 0 10px;
              display: block;
            }
            .notification{
              padding: 20px 40px 7px 30px;
              border-bottom: 1px solid #646F7C2B;
              display: grid;
              grid-template-columns: 80% 20%;
              align-items: center;
              height: 100%;
              @media screen and (max-width: 1199px) {
                padding: 14px 10px 7px 15px;
              }
            }
            .toggle-switch{
              .toggle {
                position : relative ;
                display : inline-block;
                width : 55px;
                height : 27px;
                background-color: $body-color;
                border-radius: 30px;
                float: right;
                @media screen and (max-width: 1199px) {
                  width : 35px;
                  height : 20px;
                  border-radius: 20px;
                }
              }
              .toggle:before {
                content: "";
                position: absolute;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: #646F7C;
                top: 6px;
                left: 6px;
                transition:  all 0.5s;
                z-index: 2;
                border: 5px solid $dark-blue;
                @media screen and (max-width: 1199px) {
                  width: 8px;
                  height: 8px;
                  border: 2px solid $dark-blue;
                  top: 5px;
                }
              }
              .toggle:after {
                content: '';
                position: absolute;
                width: 19px;
                height: 19px;
                border-radius: 50%;
                background-color: #646F7C;
                top: 3px;
                left: 4px;
                transition:  all 0.5s;
                @media screen and (max-width: 1199px) {
                  width: 12px;
                  height: 12px;
                }
              }
              .checkbox:checked + .toggle::after {
                left : 30px;
                background-color: $cyan;
                @media screen and (max-width: 1199px) {
                  left: 16px;
                }
              }
              .checkbox:checked + .toggle::before {
                left : 33px;
                background-color: $cyan;
                @media screen and (max-width: 1199px) {
                  left: 18px;
                  top: 5px;
                }
              }
              .checkbox:checked + .toggle {
                background-color: $body-color;
              }
              .checkbox {
                display : none;
              }
            }
            .delivery-method{
              padding: 27px 30px;
              border-top: 1px solid rgba(100, 111, 124, 0.17);
              @media screen and (max-width: 991px) {
                padding: 20px 14px 0;
              }
              ul.list{
                li{
                  display: flex;
                  align-items: center;
                  gap: 20px;
                  @media screen and (max-width: 1199px) {
                    gap: 10px;
                  }
                  &:not(&:last-of-type){
                    margin: 0 0 20px;
                  }
                  input{
                    width: 20px;
                    height: 20px;
                    @media screen and (max-width: 1199px) {
                      width: 13px;
                      height: 13px;
                    }
                  }
                  input[type="checkbox"]:checked{
                    accent-color: $cyan
                  }
                  input[type="checkbox"] {
                    accent-color: #121828
                  }
                  label{
                    color: $white;
                    font-size: 16px;
                    @media screen and (max-width: 1199px) {
                      font-size: 11px;
                      line-height: 18px;
                    }
                  }
                }
              }
            }
          }
          .payment-method-tab-main-wrapper{
            .title-wrapper{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .btn-style{
                padding: 7px 16px 8px;
                font-weight: 600;
    border-radius: 8px;
    font-size: 14px;
    line-height: normal;
              }
            }
            .tab-body-wrapper{
              .form-wrapper{
                form.form{
                  label{
                    @media screen and (max-width: $screen-xs) {
                      font-size: 12px;
                      line-height: 16px;
                    }
                  }
                }
              }
            }
          }
          .password-security-tab-wrapper{
            .tab-body-wrapper{
              .form-wrapper{
                .form-group{
                  background: $body-color-1;
                }
              }
              .btn-style.text.cyan{
                color: $cyan;
                text-decoration: underline !important;
                @media screen and (max-width: 1199px) {
                  font-size: 12px;
                  line-height: 16px;
                }
              }
              .main-wrapper{
                padding: 35px 0;
                @media screen and (max-width: 1199px) {
                  padding: 15px 0 20px;
                }
                &:not(&:last-of-type){
                  border-bottom: 1px solid #73849B;
                }
                strong{
                  font-size: 16px;
                  font-weight: 600;
                  line-height: normal;
                  color: $white;
                  @media screen and (max-width: 1199px) {
                    font-size: 12px;
                  }
                }
                .text-wrapper{
                strong {
                  font-size: 14px;
                  line-height: 17px;
                  font-weight: 500;
                  color: $white;
                  @media screen and (max-width: 1199px) {
                    font-size: 12px;
                    line-height: 16px;
                  }
                }
                p{
                  color: #646F7C;
                  font-size: 14px;
                  line-height: 20px;
                  @media screen and (max-width: 1199px) {
                    font-size: 12px;
                    line-height: 16px;
                  }
                }
                }
                .btn-style{
                  &.dark{
                    background: $dark-blue;
                    border: 1px solid #272C3B;
                    font-size: 14px;
                    border-radius: 8px;
                    padding: 7px 18px;
                    @media screen and (max-width: 1199px) {
                      font-size: 12px;
                      line-height: 16px;
                    }
                    img, svg{
                      filter: none !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}
.no-pay-out-component-wrapper{
  text-align: center;
  .img-wrapper{
    img, svg{
      width: 102px;
      height: 102px;
      object-fit: contain;
      margin-inline: auto;
      @media screen and (max-width: $screen-xs) {
        width: 80px;
        height: 80px;
      }
    }
  }
  .text-wrapper{
    strong{
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      color: $white;
      @media screen and (max-width: $screen-xs) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    p{
      color: #596780;
      margin: 0 0  35px;
    }
    .btn-style{
      padding: 13px 20px 9px;
    border-radius: 8px;
      @media screen and (max-width: 1199px) {
        font-size: 12px;
        line-height: 16px;
        padding: 8px 20px 6px;
        border-radius: 6px;
      }
    }
  }
}
.download-overview-cards-wrapper{
  // background: $dark-blue;
  // padding: 43px 0;
  // border-radius: 15px;
  @media screen and (max-width: 1199px) {
    // padding: 43px 0 10px 0;
  }
  .col-xl-3.col-lg-6.col-md-6{
    @media screen and (min-width: 1200px) {
      padding-right: 0;
    // margin: 0 0 50px;
    &:first-of-type{
      padding-left: 12px;
    }
    &:last-of-type{
      padding-right: 12px;
    }
    }
    &:last-of-type{
      .overview-card{
        &:after{
          // display: none;
          @media screen and (max-width: 1199px) {
            // display: block;
          }
        }
      }
    }
  }
  .overview-card{
    display: grid;
    grid-template-columns: 47px auto;
    gap: 20px;
    align-items: center;
    position: relative;
    background: $dark-blue;
    padding: 23px;
    border-radius: 8px;

    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      padding: 20px 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 20px 20px;
    }
    &:after{
      content: '';
      display: none;
      border-right: 1px solid;
      border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #58b4b1, rgba(255, 255, 255, 0)) 1;
      height: 80px;
      position: absolute;
      right: 0;
      top: 0;
    }
    .img-wrapper{
      img{
        width: 47px;
        height: 47px;
        object-fit: contain;
      }
    }
    .text-wrapper{
      @media screen and (max-width: 1399px) and (min-width: 1200px){
        margin: 20px 0 0 0;
      }
      h3{
        margin: 0 0 5px;
        word-break: break-all;
        font-size: 22px !important;
        line-height: 26px !important;
        @media screen and (max-width: 1399px) and (min-width: 1200px){
          font-size: 25px !important;
        }

      }
      span{
        display: block;
        font-size: 12px;
        @media screen and (max-width: 767px) {
          font-size: 11px;
        }
      }
    }
  }
}
.my-download-page{
  .section-title-bar{
    ul.icon-list{
      li{
        a{
          &.active{
            .box-icon{
              opacity: 1;
            }
          }
          .box-icon{
            opacity: .5;
            @media screen and (max-width: 1440px) and (min-width: 768px) {
              width: 44px;
              height: 44px;
              border-radius: 12px;
            }
            @media screen and (max-width: $screen-xs){
              width: 32px;
              height: 32px;
              border-radius: 8px;
            }
            img, svg{
              @media screen and (max-width: 1440px) and (min-width: 768px) {
                width: 18px;
                height: 18px;
              }
              @media screen and (max-width: $screen-xs){
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
  .dashboard-inner-page {
    padding: 45px 54px 100px;
    @media screen and (max-width: 1199px) and (min-width: 992px) {
      padding: 30px 0px 100px;
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      padding: 25px 0 40px;
    }
    @media screen and (max-width: $screen-xs) {
      padding: 25px 15px 30px;
    }
    .page-title{
      margin: 0 0 50px;
      @media screen and (max-width: 991px) and (min-width: 768px) {
        margin: 0 0 25px;
      }
      @media screen and (max-width: 767px) {
        margin: 0 0 15px;
      }
      h1{
        margin: 0 0 0 0;
        @media screen and (max-width: 767px) {
          margin: 0 0 20px 0;
        }
      }
    }
    .navbar-and-filters-wrapper{
      margin: 34px 0 39px;
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        margin: 20px 0 15px;
      }
      @media screen and (max-width: 767px) {
        margin: 20px 0 10px;
      }
    }
    .downloads-data{
      // margin: 30px 0 0 0;
      .pagination-component{
        margin: 9px 10px 0;
        background: $dark-blue;
        border-radius: 15px;
        @media screen and (max-width: 991px){
          border-radius: 5px;
          padding: 8px 0;
          margin: 9px 10px 0;
        }
      }
    }
  }
}
.video-chart-wrapper{
  .tabs-main-wrapper{
display: flex;
align-items: center;
justify-content: space-between;
margin: 0 0 23px;
    .tabs-wrapper{
      text-align: center;
      &.cyan-bg{
        background: $cyan;
        display: inline-block;
        border-radius: 8px;
        padding: 3px 2px 2px;
        @media screen and (max-width: $screen-xs) {
          border-radius: 5px;
        }
        ul.tabs{
          li{
            margin: 0 0;
            &:not(&:last-of-type){
              margin: 0 3px 0 0;
            }
            a{
              background: transparent;
              border-radius: 8px;
              padding: 6px 19px 4px;
              color: #191F2FCC;
              font-weight: 600;
              @media screen and (max-width: $screen-xs) {
                font-size: 10px;
                padding: 5px 15px 4px;
                min-width: 60px;
                border-radius: 5px;
              }
              &.active{
                font-weight: 600;
                background: #1B2F3F;
                color: $white;
                img, svg{
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(42deg) brightness(93%) contrast(118%);
                transition: .3s ease-in-out all;
                }
              }
              img{
                filter: brightness(0) saturate(100%) invert(20%) sepia(9%) saturate(4994%) hue-rotate(146deg) brightness(95%) contrast(84%);
                width: 18px;
                height: 18px;
                object-fit: contain;
                margin-top: -2px;
                transition: .3s ease-in-out all;
                @media screen and (max-width: $screen-xs) {
                  width: 13px;
                  height: 13px;
                }
              }
            }
          }
        }
      }
      ul.tabs{
        li{
          &:not(&:last-of-type){
            margin: 0 10px 0 0;
          }
          a{
            font-size: 12px;
            line-height: 14px;
            font-weight: bold;
            border-radius: 15px;
            gap: 6px;
            @media screen and (max-width: $screen-xs) {
              font-size: 10px;
              padding: 10px 30px 10px;
            }
            img, svg{
              @media screen and (max-width: $screen-xs) {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }
    }
    .refresh-data-btn{
          color:#646F7C;
          font-size: 12px;
          gap: 5px;
          @media screen and (max-width: 767px) {
            font-size: 11px;
            gap: 5px;
          }
          img, svg{
            color: #646F7C;
            width: 17px;
            height: 17px;
            object-fit: contain ;
            margin-top: -5px;
            @media screen and (max-width: 767px) {
              width: 15px;
              height: 15px;
            }
          }
    }
  }
  .charts-main-wrapper{
    ul.video-tabs{
      @media screen and (max-width: $screen-xs) {
        text-align: left !important;
      }
      li{
        @media screen and (max-width: $screen-xs) {
          display: block;
        }
      }
    }
  }
  .video-chart-main-wrapper{
    //background: $dark-blue;
    border-radius: 50px;
    padding: 0 0 50px ;
    @media screen and (max-width: 1399px) {
      border-radius: 20px;
    }
    @media screen and (max-width: $screen-xs) {
      padding: 0 0 10px ;
    }
    .charts-main-wrapper{
      .average-cards-wrapper{
        .average-text-div-wrapper{
          button{
            img, svg{
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                width: 10px;
                height: 10px;
              }
              @media screen and (max-width: 767px) {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }
    .videos-main-wrapper, .charts-main-wrapper{
      //padding: 58px 0;
      @media screen and (max-width: 1599px) and (min-width: 992px) {
        //padding: 25px 10px;
      }
      @media screen and (max-width: 991px) {
        //padding: 25px 0px;
      }
      .container-fluid{
        padding-inline: 0;
      }
      .section-topbar{
        margin: 28px 0 0px 0;
        position: relative;
        @media screen and (max-width: $screen-xs) {
          margin: 15px 0 0 0;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          margin: 20px 0 20px 0;
        }
        ul.video-tabs{
          background: #242B3C;
          display: inline-block;
          padding: 4px 4px!important;
          border-radius: 8px;
          @media screen and (max-width: 1199px) and (min-width: 768px) {
          }
          @media screen and (max-width: $screen-xs) {
            padding: 3px 3px 3px !important;
            margin: 0 0 17px !important;
            border-radius: 5px;
            display: inline-flex;
            overflow-x: auto;
            flex-wrap: nowrap;
            width: 100%;
            &::-webkit-scrollbar {
              height: 0px;
            }
            &::-webkit-scrollbar-track {
              background: $light-blak-bg;
            }
            &::-webkit-scrollbar-thumb {
              background: $light-blak-bg;
            }
          }
          li{
            position: relative;
              @media screen and (max-width: 1480px) and (min-width: 768px) {
            &:nth-child(3){
                &:hover{
                  .tip{
                    right: 108%;
                    left: unset;
                  }
                }
              }
            }
            @media screen and (max-width: 1780px) and (min-width: 768px) {
              &:nth-child(4){
                &:hover{
                  .tip{
                    right: 108% !important;
                    left: unset !important;
                  }
                }
              }
            }
            &:not(&:last-of-type){
              margin: 0 5px 0 0;
            }
            .tab-link{
              padding: 8px 20px 8px;
                color: $white;
                display: inline-flex;
                background: transparent;
                border-radius: 8px;
                font-weight: 500;
                align-items: center;
              @media screen and (max-width: 1199px) and (min-width: 992px) {
                font-size: 12px !important;
              }
                @media screen and (max-width: 1199px) {
                  font-size: 10px;
                  padding: 8px 15px 8px;
                }
              @media screen and (max-width: 767px) {
                font-size: 10px;
                border-radius: 5px;
                text-wrap: nowrap;
                padding: 8px 12px 8px;
              }
                &:after{
                  display: none;
                }
            }
            &:hover{
              .tip{
                display: block;
                  @media screen and (max-width: $screen-xs) {
                    display: none !important;
                  }
              }
            }
            .btn-group{
              &:hover{
                .dropdown-menu{
                display: block !important;
                }
              }
              &.active{
                button{
                  opacity: 1;
                  border-bottom: 2px solid $cyan;
                }
              }
            }
            .dropdown-menu{
              padding: 0;
              top: -54% !important;
              @media screen and (max-width: $screen-xs) {
                top: 74px !important;
                left: 0 !important;
                transform: none !important;
              }
              .dropdown-item{
                background: transparent;
                padding: 0;
                white-space: unset;
                opacity: 1;
                .tip{
                  position: relative;
                  left: 0;
                  top: 0;
                  transform: unset;
                }
              }
            }
            a, button{
              display: inline-block;
              background: transparent;
              font-size: 12px;
              line-height: 14px;
              color: #646F7C;
    padding: 10px 17px 7px;
    display: block;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
              @media screen and (max-width: 1199px) and (min-width: 768px) {
                font-size: 10px;
                padding: 7px 15px 7px;
              }
              @media screen and (max-width: 767px) {
                font-size: 10px;
                line-height: normal;
                padding: 7px 17px 7px;
                border-radius: 5px;
              }
              &:after{
                display: none;
              }
              &.active{
                color: #ffffff !important;
                background: #13192A;
              }
              &:hover{
                .dropdown-menu{
                  display: block;
                }
              }
            }
            .tip{
              background: $white;
              padding: 14px 28px 18px;
              width: 400px;
              //width: 100%;
              border-radius: 10px;
              text-align: left;
              position: absolute;
              display: none;
              left: 107%;
              top: 50%;
              transform: translateY(-50%);
              z-index: 22;
              @media screen and (max-width: 1199px) and (min-width: 768px) {
                width: 340px;
              }
              @media screen and (max-width: $screen-xs) {
                width: 340px;
              }
              &:after{
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: -7px;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-right: 8px solid #ffffff;
                @media screen and (max-width: $screen-xs) {
                  display: none;
                }
              }
              span{
                line-height: 125.8%;
                color: #121828;
              }
            }
          }
        }
        .right-filter-wrapper{
          display: flex;
          align-items: center;
          gap: 15px;
          justify-content: flex-end;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            gap: 10px;
          }
          @media screen and (max-width: 1199px) {
            gap: 5px;
          }
          .download-videos-btn{
            font-size: 12px;
            padding: 8px 10px 6px;
            border-radius: 8px;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              font-size: 10px;
              padding: 5px 5px;
              gap: 6px;
              border-radius: 8px;
            }
            @media screen and (max-width: 1199px) {
              min-width: auto;
              padding: 8px 8px 8px;
              border-radius: 6px;
            }
            img, svg{
              width: 17px;
              height: 17px;
              object-fit: contain;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                width: 12px;
                height: 12px;
              }
              @media screen and (max-width: 1199px) {
                width: 10px;
                height: 10px;
              }
            }
          }
          .select-wrapper{
            display: inline-flex;
            align-items: center;
            //background: $body-color;
            background: $light-blak-bg;
            border-radius: 8px;
            //padding: 13px 13px 11px 15px;
            gap: 10px;
            @media screen and (max-width: $screen-xs) {
              display: flex;
              width: 100%;
              // justify-content: center;
            }
            img, svg{
              color: #FF165D;
              width: 15px;
              height: 20px;
              @media screen and (max-width: 1199px) and (min-width: 768px) {
                width: 13px;
                height: 12px;
              }
              @media screen and (max-width: 767px) {
                width: 12px;
                height: 10px;
                left: 3px;
              }
            }
            .form-select, .dropdown{
              background-color: transparent;
              box-shadow: none;
              border: none;
              border-radius: 15px ;
              padding: 0 30px 0 0;
              color: $white;
              --bs-form-select-bg-img:url('./assets/images/icons/down-arrow-white.png');
              background-size: 13px 6px;
              @media screen and (max-width: 767px) {
                border-radius: 5px !important;
              }
              .dropdown-toggle{
              font-size: 14px;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                font-size: 12px;
                line-height: 18px;
              }
              @media screen and (max-width: 1199px) and (min-width: 991px) {
                padding: 11px 13px 10px 36px !important;
              }
              @media screen and (max-width: 1199px) and (min-width: 768px) {
                font-size: 10px;
                padding: 11px 13px 10px 36px !important;
              }
                @media screen and (max-width: 767px) {
                  font-size: 10px;
                  line-height: 14px;
                  padding: 9px 10px 8px 33px !important;
                  border-radius: 5px !important;
                }
              &:after{
                border: solid #ffffff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.5px;
    margin-left: 15px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
                @media screen and (max-width: 767px) {
                  border-width: 0 1.5px 1.5px 0;
                  padding: 2px;
                }
              }
              }
              option{
                background: $body-color;
              }
              &:focus{
                box-shadow: none;
                border: none;
              }
            }
            .dropdown{
              padding: 0;
              button{
                background: transparent;
                border: none;
              }
              .dropdown-menu{
                background: #121828;
                border-radius: 10px;
                top: 53px !important;
                transform: none !important;
                inset: auto auto auto auto !important;
                margin-top: 20px;
                width: 100%;
                @media screen and (max-width: 991px) {
                  margin-top: 5px;
                }
                a{
                  color: $white;
                  font-size: 16px;
                  padding-block: 13px ;
                  @media screen and (max-width: 1199px) and (min-width: 768px) {
                    font-size: 10px;
                    line-height: 14px;
                    padding-block: 10px;
                  }
                  @media screen and (max-width: $screen-xs) {
                    font-size: 8px;
                    line-height: 12px;
                    padding-block: 10px;
                  }
                  &:hover{
                    background: transparent;
                    color: $white;
                    opacity: .8;
                  }
                  &:not(&:last-of-type){
                    border-bottom: 1px solid rgba(100, 111, 124, 0.15);;
                  }
                }
              }
            }
          }
          .date-wrapper{
            display: inline-flex;
            gap: 13px;
            align-items: center;
            cursor: pointer;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              gap: 8px;
            }
            @media screen and (max-width: 1199px) and (min-width: 768px) {
              gap: 8px;
            }
            @media screen and (max-width: $screen-xs) {
              display: flex;
              width: 100%;
              gap: 5px;
            }
            .box-icon{
              background: $light-blak-bg;
              border: 2px solid $light-blak-bg;
              //border: 2px solid $body-color;
              //background: $body-color;
              @media screen and (max-width: 991px) and (min-width: 768px) {
                width: 36px;
                min-width: 26px;
                height: 36px;
                border-radius: 8px;
              }
              @media screen and (max-width: $screen-xs) {
                width: 30px;
                min-width: 28px;
                height: 32px;
                border-radius: 8px;
              }
              &.active{
                border: 2px solid $cyan;
              }
              svg{
                color: #25BC91;
                filter: none;
                @media screen and (max-width: 991px) and (min-width: 768px) {
                  width: 14px;
                  height: 14px;
                }
                @media screen and (max-width: 767px) {
                  width: 12px;
                  height: 12px;
                }
              }
            }
            p{
              margin: 0 0 0;
              font-size: 14px;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                font-size: 12px;
                line-height: 20px;
              }
              @media screen and (max-width: 1199px) and (min-width: 768px) {
                font-size: 10px;
              }
              @media screen and (max-width: $screen-xs) {
                font-size: 10px;
                line-height: normal;
              }
            }
          }
          ul.icon-list{
            display: flex;
            align-items: center;
            margin: 0 0 0 25px !important;
            @media screen and (max-width: 991px) and (min-width: 768px) {
              margin: 0 0 0 10px !important;
            }
            @media screen and (max-width: $screen-xs) {
              width: 100%;
              margin: 0 0 0 0px !important;
              justify-content: end;
            }
            li{
              &:not(&:last-of-type){
                margin: 0 13px 0 0;
                @media screen and (max-width: 991px) {
                  margin: 0 5px 0 0;
                }
              }
              a{
                cursor: pointer;
                .box-icon{
                  background: $light-blak-bg;
                  opacity: 0.5;
                  @media screen and (max-width: 767px) {
                    width: 32px;
                    height: 32px;
                    border-radius: 8px;
                  }
                  img, svg{
                    @media screen and (max-width: 767px) {
                      width: 12px;
                      height: 12px;
                    }
                  }
                }
                &.active{
                  .box-icon{
                    opacity: 1;
                  }
                }
              }
            }
          }
        }

      }
    }
    .pagination-component{
      background: $light-blak-bg;
      margin: 20px 0 0;
      border-radius: 15px;
    }
    .performance-tab-content-main{
      .chart-section{
        @media screen and (max-width: 767px) {
          padding: 0 ;
        }
        .graph-wrapper{
          .graph-chart-wrapper{
            .main-icon-label-div{
              @media screen and (max-width: 767px) {
                width: 56% !important;
                bottom: 25px !important;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
    .characteristics-tab-content-main{
      .characteristics-content{
        @media screen and (max-width: 767px) {
          padding: 10px 0;
        }
        .horizontal-bar-chart{
          canvas{
            @media screen and (max-width: 991px) and (min-width: 768px) {
              height: 232px !important;
            }
          }
        }
      }
    }
    .behavior-tab-content-main{
      .chart-section {
        @media screen and (max-width: $screen-xs) {
          margin: 0 0 20px !important;
        }
      }
    }
  }
}
.videos-section{
  // background: #191F2F;
  // padding: 35px 22px;
  // border-radius: 10px;
  margin: 30px 0 0;
  .main-wrapper{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 21px;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      grid-template-columns: 31% 31% 31%;
    }
    @media screen and (max-width: $screen-xs) {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
}
.video-wrapper{
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  .img-wrapper{
    img{
      height: 460px;
      object-fit: cover;
      width: 100%;
      border-radius: 10px;
      @media screen and (max-width: 1440px) and (min-width: 768px) {
        height: 350px;
      }
      @media screen and (max-width: $screen-xs) {
        height: 250px;
      }
    }
  }
  .content-wrapper{
    .logo{
      position: absolute;
      top: 22px;
      left: 18px;
    }
    ul.list{
      position: absolute;
      bottom: 20px;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        bottom: 14px;
      }
      @media screen and (max-width: $screen-xs) {
        bottom: 14px;
      }
      &.right{
        right: 0;
        li{
          .icon{
            border-radius: 5px 0 0 5px;
          }
        }
      }
      &.left{
        left: 0;
        li{
          .icon{
            border-radius: 0 5px 5px 0;
          }
        }
      }
      li{
        display: block;
        text-align: center;
        &:not(&:last-of-type){
          margin: 0 0 12px 0px;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            margin: 0 0 8px;
          }
          @media screen and (max-width: $screen-xs) {
            margin: 0 0 4px;
          }
        }
        .icon{
          background-color: rgba(25, 31, 47, 0.57);
          display: grid;
          grid-template-columns: 17px auto;
          align-items: center;
          justify-content: flex-start;
          gap: 7px;
          padding: 7px 10px 4px;
          min-width: 86px;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            gap: 4px;
            padding: 3px 10px 3px;
            min-width:65px;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            grid-template-columns: 11px auto;
          }
          @media screen and (max-width: $screen-xs) {
            padding: 3px 6px 3px;
            grid-template-columns: 11px auto;
            gap: 5px;
            min-width: 70px;
          }
        }
        a{
          margin: 0 0 2px 0;
          display: inline-block;
          text-align: center;
          svg, img{
            color: $white;
            width: 17px;
            height: 17px;
            object-fit: contain;
            @media screen and (max-width: 1440px) and (min-width: 767px) {
              width: 11px;
              height: 11px;
            }
            @media screen and (max-width: $screen-xs) {
              width: 11px;
              height: 11px;
            }
          }
        }
        span{
          display: block;
          font-weight: 600;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 12px;
            line-height: 18px;
          }
          @media screen and (max-width: $screen-xs) {
            font-size: 8px;
            line-height: 12px;
          }
        }
      }
    }
    .check-box-wrapper{
      width: 20px;
      height: 20px;
      position: absolute;
      right: 8px;
      top: 10px;
      .checkmark{
        width: 20px;
        height: 20px;
        background-color: #121828;
        border: 1px solid #646F7C;
        &:after{
          left: 6px;
          top: 2px;
          width: 5px;
          height: 9px;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }
  .pin-icon{
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    object-fit: contain !important;
    background-color: rgba(25, 31, 47, 0.57);
    border-radius: 50% !important;
    width: 30px !important;
    height:30px !important;
    padding: 5px !important;
    padding: 6px !important;
  }
}
.chart-videos-info-wrapper{
  margin: 0 0 0;
  .col-xl-4{
    @media screen  and (min-width: 1200px) {
      &:nth-child(2){
        padding-inline: 0;
      }
    }
    @media screen and (max-width: 1199px)  and (min-width: 768px) {
      &:nth-child(even){
        padding-left: 6px;
      }
    }
    @media screen and (max-width: 1199px)  and (min-width: 768px) {
      &:nth-child(odd){
        padding-right: 6px;
      }
    }
  }
  .main-info-wrapper{
    background: #191F2F;
    border-radius: 10px;
    padding:16px 15px 55px;
    position: relative;
    height: 100%;
    @media screen and (max-width: 767px) {
      padding:10px 10px 50px;
    }
    >span{
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        font-size: 10px;
        line-height: 12px;
        display: block;
        margin: 0 0 10px;
      }
      @media screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
    .info-wrapper{
      border-radius: 5px;
      background: #151B2B;
      height: 60px;
      padding: 9px 15px 9px 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(&:last-of-type){
        margin: 0 0 7px;
      }
      .main-wrapper{
        display: flex;
        align-items: center;
        gap: 8px;
        img.sIcon{
          width: 9px;
          height: 9px;
          object-fit: contain;
        }
        img.pinIcon{
          width: 15px;
          height: 15px;
          object-fit: contain;
        }
          img.video-img{
            width: 27px;
            min-width: 27px;
            height: 42px;
            border-radius: 3px;
            object-fit: contain;
          }
          .text-wrapper{
            span{
              display: block;
            }
          }
        .progress-wrapper{
          text-align: right;
          margin: 0 10px 0 0;
          span{
            margin: 0 0 5px 0;
          }
          .progress{
            width: 63px;
            height: 4px;
            background: #173441;
            .progress-bar{
              background: $cyan;
            }
          }
        }
      }
    }
    .btn-style.dark{
      width: 100%;
      display: block;
      background: $light-blak-bg;
      font-size: 12px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0 0 10px 10px;
      padding: 10px 52px 10px;
      z-index: 22;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        font-size: 10px;
        padding: 5px 40px ;
      }
      @media screen and (max-width: 767px) {
        font-size: 10px;
      }
      img, svg{
        width: 20px;
        margin: 0 0 0 6px ;
        object-fit: contain;
      }
    }
  }
}
.videos-list-view{
  background: #191F2F;
  border-radius: 10px;
  padding: 0 12px;
  @media screen and (max-width: 767px) {
    padding: 0 6px;
  }
.table-wrapper{
    padding: 6px 8px;
  table{
    thead{
      tr{
        th{
          background: #161C2C;
          &:first-of-type{
            border-radius: 10px 0 0 10px;
          }
          &:last-of-type{
            border-radius: 0 10px 10px 0;
          }
        }
      }
    }
    tbody{
      tr{
        td{
          padding-block: 7px;
          padding-top: 7px !important;
          background: #161C2C;
          &:first-of-type{
            border-radius: 10px 0 0 10px;
          }
          &:last-of-type{
            border-radius: 0 10px 10px 0;
          }
        }
      }
    }
  }
}
}
.chart-section{
  // background: #161C2C;
  border-radius: 10px;
  //padding: 30px 12px;
  //margin: 0 0 14px;
  padding: 20px 0 40px;
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    //padding: 20px 30px;
  }
  @media screen and (max-width: 991px) {
    margin:0 0 30px;
    padding: 0 ;
  }
  .section-title{
    padding: 25px 53px 15px;
    border-bottom: 1px solid rgba(229, 230, 235, 0.05);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      padding: 25px 20px 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 15px 15px 15px;
    }
    strong.large{
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .section-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin: 0 0 30px;
    position: relative;
    padding: 25px 10px 28px;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      padding: 15px 0 15px;
    }
    @media screen and (max-width: $screen-xs) {
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      padding: 15px 0 20px;
    }
    span{
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        font-size: 10px;
        line-height: 14px;
      }
    }
    .title{
      span{
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 10px;
          line-height: 14px;
        }
      }
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        margin: 0 0  8px;
      }
    }
    strong.white{
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
    h3{
      margin: 0 0 0;
      display: block;
      @media screen and (max-width: $screen-xs) {
        width: 100%;
        font-size: 16px !important;
      }
    }
    p{
      margin: 0 0 0;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        font-size: 10px;
        line-height: 12px;
      }
      @media screen and (max-width: $screen-xs) {
        font-size: 11px;
        line-height: 15px;
      }
    }
    .date-wrapper{
      display: flex;
      gap: 13px;
      align-items: center;
      justify-content: flex-end;
      @media screen and (max-width: $screen-xs) {
        display: flex;
        width: 100%;
        margin:  15px 0 0 0;
      }
      .select-wrapper{
        display: inline-flex;
        align-items: center;
        background: #161C2C;
        border-radius: 10px;
        gap: 10px;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          gap: 5px;
        }
        @media screen and (max-width: $screen-xs) {
          gap: 0px;
          border-radius: 5px;
        }
        img, svg{
          margin: 0 6px 0 0;
          @media screen and (max-width: $screen-xs) {
            width: 14px;
            height: 14px;
          }
        }
        .dropdown{
          @media screen and (max-width: 1199px) {
            width: auto !important;
          }
          .dropdown-toggle{
          padding: 8px 17px;
            font-size: 16px;
            background: transparent;
            border: none;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              font-size: 12px;
              line-height: 18px;
            }
            @media screen and (max-width: 1199px) and (min-width: 768px) {
              font-size: 12px;
              line-height: 16px;
            }
            @media screen and (max-width: $screen-xs) {
              font-size: 10px;
              line-height: 14px;
            }
          }
        }
        .dropdown-menu{
          background: #121828;
          border-radius: 10px;
          top: 53px !important;
          transform: none !important;
          inset: auto auto auto auto !important;
          margin-top: 20px;
          width: 180px;
          @media screen and (max-width: $screen-xs) {
            margin-top: 10px;
          }
          .dropdown-item{
            color: #ffffff;
            font-size: 16px;
            padding-block: 13px;
            @media screen and (max-width: 991px) and (min-width: 768px) {
              font-size: 12px;
              line-height: 16px;
            }
            @media screen and (max-width: $screen-xs) {
              font-size: 10px;
              line-height: 14px;
              padding-block: 7px;
            }
            &:hover, &.active, &:focus{
              background: transparent;
              color: #ffffff;
            }
            &:not(&:last-of-type){
              border-bottom: 1px solid rgba(100, 111, 124, 0.15);
            }
          }
        }
      }
      .box-icon{
        background: #1A2232;
        border: 2px solid #1A2232;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          width: 42px;
          height: 42px;
          border-radius: 12px;
          img, svg{
            width: 16px;
            height: 16px;
          }
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          height: 36px;
          width: 36px;
          border-radius: 8px;
        }
        @media screen and (max-width: $screen-xs) {
          height: 32px;
          width: 32px;
          border-radius: 8px;
        }
        &.active{
          border: 2px solid $cyan;
        }
        svg{
          color: #25BC91;
          filter: none;
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            width: 14px;
            height: 14px;
          }
          @media screen and (max-width: $screen-xs) {
            width: 12px;
            height: 12px;
          }
        }
      }
      p{
        margin: 0 0 0;
        @media screen and (max-width: $screen-xs) {
          font-size: 11px;
          line-height: normal;
        }
      }
      ul.d-list{
        display: inline-flex;
        align-items: center;
        gap: 8px;
        margin: 0 20px 0 0 !important;
        @media screen and (max-width: 991px) {
          margin: 0 10px 0 0 !important;
        }
        li{
          list-style-type: none;
          background: #151B2B;
          border-radius: 8px;
          min-width: 40px;
          text-align: center;
          padding: 8px 4px;
          cursor: pointer;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            min-width: auto;
            padding: 8px 6px;
            line-height: normal;
            font-size: 8px;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            min-width: 36px;
            width: 36px;
            padding: 3px 4px 4px;
          }
          @media screen and (max-width: $screen-xs) {
            min-width: 32px;
            width: 32px;
            padding: 3px 4px 4px;
          }
          &.white{
            background: $white;
            color: #191F2F;
            span{
              color: #151B2B;
            }
          }
          span{
            color: $white;
            font-size: 16px;
            font-weight: bold;
            vertical-align: middle;
            @media screen and (max-width: 1440px) and (min-width: 768px) {
              font-size: 8px;
              line-height: 12px;
            }
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              font-size: 10px !important;
              line-height: 16px !important;
            }
            @media screen and (max-width: $screen-xs) {
              font-size: 10px;
              line-height: normal;
            }
          }
        }
      }
    }
    ul.labels{
      li{
        &:not(&:last-of-type){
          margin: 0 40px 0 0;
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            margin: 0 20px 0 0;
          }
          @media screen and (max-width: $screen-xs) {
            margin: 0 20px 0 0;
          }
        }
        .dot{
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin: 0 6px 0 0;
          display: inline-block;
          @media screen and (max-width: $screen-xs) {
            width: 4px;
            height: 4px;
            margin: 0 4px 0 0;
          }
        }
        span{
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 10px;
            line-height: 14px;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            font-size: 12px;
            line-height: 16px;
          }
          @media screen and (max-width: $screen-xs) {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
    }
  }
  .title{
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 0 13px;
    flex-wrap: wrap;
    @media screen and (max-width: 991px) {
      margin: 0 0 8px;
    }
    .text{
      display: flex;
      align-items: center;
      gap: 5px;
      display: none;
      img{
        width: 15px;
        height: 15px;
      }
      .dot{
        width: 6px;
        height: 6px;
        border-radius: 50px;
      }
      strong{
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
  .chart-content-wrapper{
    background: #191F2F;
    border-radius: 12px;
    padding: 20px 15px;
    @media screen and (max-width: $screen-xs) {
      padding: 15px 10px !important;
    }
    &.transparent{
      background: transparent;
      padding: 0 0;
    }
    strong.white{
      margin: 0 0 5px;
      display: block;
      @media screen and (max-width: 991px) and (min-width: 768px) {
        font-size: 14px;
        line-height: 20.36px;
      }
    }
    .graph-chart-wrapper {
      canvas{
        width: 100% !important;

      }
    }
    ul.lists{
      padding: 10px 22px 0 0 !important;
      position: relative;
      height: 100%;
      @media screen and (max-width: 767px) {
        padding: 10px 5px 0 0 !important;
      }
      &:after{
        content: "";
        display: block;
        border-right: 1.5px solid;
        //border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #58b4b1, rgba(255, 255, 255, 0)) 1;
        background:#E5E6EB26;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
      }
      li{
        display: block;
        &:not(&:last-of-type){
          margin: 0 0 20px;
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            margin: 0 0 5px;
          }
          @media screen and (max-width: 767px) {
            margin: 0 0 3px;
          }
        }
        img, svg{
          width: 18px;
          height: 18px;
          object-fit: contain;
        }
        button{
          background: transparent;
          padding: 14px 27px;
          width: 100%;
          border: none;
          color: $white;
          text-align: left;
          border-radius: 5px;
          font-size: 16px;
          line-height: 14.52px;
          display: grid;
          align-items: center;
          grid-template-columns: 18px auto;
          gap: 7px;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 12px;
            line-height: 18px;
            padding: 7px 18px 7px;
          }
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            padding: 10px 20px 10px 15px;
            font-size: 12px;
            line-height: 18px;
          }
          @media screen and (max-width: 991px) and (min-width: 768px) {
            font-size: 12px;
            line-height: 18px;
            padding: 7px 18px 7px;
          }
          @media screen and (max-width: 767px) {
            min-width: auto;
            width: 100%;
            font-size: 9px;
            padding: 3px 6px 3px 6px;
            line-height: normal;
            border-radius: 5px;
            grid-template-columns: 10px auto;
          }
          img, svg{
            width: 18px;
            object-fit: contain;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              width: 13px;
            }
            @media screen and (max-width: 1199px) and (min-width: 768px) {
              width: 15px;
            }
            @media screen and (max-width: 767px) {
              width: 10px;
            }
          }
          &:hover, &.active{
            background: #272E3E;
          }
        }
      }
    }
    .graph-title{
      display: flex;
      justify-content: flex-end;
      padding: 0 30px 0 0;
      align-items: center;
      gap: 10px;
      margin: 0 0 20px;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        gap: 8px;
      }
      @media screen and (max-width: 767px) {
        padding: 0 20px 0 0;
      }
      .dot{
        width: 6px;
        height: 6px;
        border-radius: 50%;

      }
      span{
        text-transform: capitalize;
        color: $white;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 10px;
          line-height: 12px;
        }
        @media screen and (max-width: 767px) {
          font-size: 10px;
          line-height: 12px;
        }
        }
    }
  }
  .average-text{
    padding: 7px 0 7px 12px;
    border-left: 3px solid #FF165D;
    margin: 30px 0 0 30px;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      padding: 5px 0 5px 12px;
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      margin: 10px 0 0 20px;
    }
    @media screen and (max-width: $screen-xs) {
      margin: 10px 0 0 30px;
    }
    p.small{
      margin: 0 0 0 0;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        font-size: 10px;
        line-height: 16px;
      }
      @media screen and (max-width: 1199px) {
        br{
          display: none;
        }
      }
      @media screen and (max-width: $screen-xs) {
        font-size: 10px;
        line-height: 16px;
      }
      span{
        color: $white;
        font-size: 14px;
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          font-size: 12px;
          line-height: 16.36px;
        }
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 10px;
          line-height: 16px;
        }
        @media screen and (max-width: $screen-xs) {
          font-size: 10px;
          line-height: 16px;
        }
      }
    }
    &.purple{
      border-left: 3px solid #5352B0 !important;
    }
    &.pink{
      border-left: 3px solid #FF165D !important;
    }
    &.light-pink{
      border-left: 3px solid #EB55B4 !important;
    }
    &.blue{
      border-left: 3px solid #0DABEA !important;
    }
  }
  .chart-tabs-wrapper{
    margin: 100px 0 30px;
    display: grid;
    grid-template-columns: 13% 13% 13% 13% 13% 13% 13%;
    gap: 15px;
    justify-content: end;
    @media screen and (max-width: 1199px) and (min-width: 992px) {
      gap: 9px;
      margin: 50px 0 30px;
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      gap: 10px;
      margin: 50px 0 30px;
    }
    @media screen and (max-width: $screen-xs) {
      grid-template-columns: 48% 48%;
      margin: 50px 0 30px;
    }
    .chart-tab{
      cursor: pointer;
      &.active{
        .img-wrapper{
          border: 1px solid $white;
        }
        .text{
          p{
            color: $white;
            font-weight: bold;
            @media screen and (max-width: 1199px) and (min-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
      .img-wrapper{
        padding: 18px 6px 20px;
        background: #191F2F;
        border-radius: 12px;
        border: 1px solid #191F2F;
        img{
          width: 100%;
        }
      }
      .text{
        text-align: center;
        margin: 20px 0 0 ;
        p{
          margin: 0 0 0 ;
        }
      }
    }
  }
}
.characteristics-section{
  // background: #161C2C;
  // border-radius: 10px;
  // padding: 0 0 40px;
  .section-title{
    padding: 21px 50px 23px;
    border-bottom: 1px solid rgba(229, 230, 235, 0.05);
    @media screen and (max-width: 991px) and (min-width: 768px) {
      padding: 20px 20px 20px !important;
    }
    @media screen and (max-width: 767px) {
      padding: 20px 0px 20px !important;
    }
    strong.large{
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
  .chart-content-wrapper{
    background: #161C2C;
    border-radius: 15px;
    //padding: 40px 15px;
    //margin: 0 38px;
    @media screen and (max-width: 1199px) and (min-width: 992px) {
      padding: 20px 13px;
    }
    @media screen and (max-width: 991px) {
      padding: 20px 5PX;
    }
    strong.white{
      margin: 0 0 5px;
      display: block;
    }
    ul.lists{
      padding: 10px 22px 0 13px !important;
      position: relative;
      @media screen and (max-width: 767px) {
        padding: 10px 0 15px 0 !important;
      }
      &:after{
        content: "";
        display: block;
        border-right: 1px solid;
        //border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #58b4b1, rgba(255, 255, 255, 0)) 1;
        background: #E5E6EB26;
        height: 90%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      li{
        display: block;
        &:not(&:last-of-type){
          margin: 0 5px 7px 0;
          @media screen and (max-width: 767px) {
            margin: 0 5px 9px 0;
          }
        }
        img, svg{
          width: 18px;
          height: 18px;
          object-fit: contain;
          @media screen and (max-width: 767px) {
            width: 12px;
            height: 12px;
          }
        }
        button{
          background: transparent;
          padding: 14px 27px;
          width: 100%;
          border: none;
          color: $white;
          text-align: left;
          border-radius: 5px;
          font-size: 16px;
          line-height: 14.52px;
          display: grid;
          align-items: center;
          grid-template-columns: 18px auto;
          gap: 7px;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 12px;
            line-height: 18px;
            padding: 8px 16px;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            font-size: 10px;
            line-height: 14px;
            padding: 8px 13px;
          }
          @media screen and (max-width: 767px) {
            padding: 5px 8px;
            font-size: 10px;
            gap: 4px;
            line-height: 14.52px;
          }
          img{
            width: 15px;
            object-fit: contain;
            @media screen and (max-width: 767px) {
              width: 12px;
            }
          }
          span{
            color: #646F7C;
            font-size: 10px;
            margin: 0 0 0 2px;

            @media screen and (max-width: 1199px) and (min-width: 768px) {
              font-size: 7px;
              line-height: normal;
            }
            @media screen and (max-width: 767px) {
              font-size: 6px;
              line-height: normal;
            }
          }
          &:hover, &.active{
            background: #272E3E;
          }
        }
      }
    }
    .graph-title {
      display: flex;
      justify-content: flex-end;
      padding: 0 30px 0 0;
      align-items: center;
      gap: 10px;
      margin: 0 0 20px;
      @media screen and (max-width: 767px) {
        gap: 5px;
      }
      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        @media screen and (max-width: 767px) {
          width: 3px;
          height: 3px;
        }
      }

      span {
        text-transform: capitalize;
        color: $white;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 10px;
          line-height: 12px;
        }
        @media screen and (max-width: 767px) {
          font-size: 8px;
          line-height: 12px;
        }
      }
    }
  }
  .section-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px;
    padding: 0 15px;
    position: relative;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      margin: 0 0 20px;
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      margin: 0 0 20px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 0 0 0;
      margin: 0 0 10px;
    }
    strong{
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        font-size: 12px;
        line-height: 18px;
      }
      @media screen and (max-width: 767px) {
        margin: 0 0 5px;
      }
    }
    span{
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        font-size: 11px;
        line-height: 16px;
      }
    }
    h3{
      margin: 0 0 0;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .date-wrapper{
      display: flex;
      justify-content: flex-end;
      gap: 13px;
      align-items: center;
      @media screen and (max-width: 991px) and (min-width: 768px) {
        gap: 7px;
      }
      @media screen and (max-width: $screen-xs) {
        display: flex;
        width: 100%;
        gap: 5px;
      }
      .box-icon{
        background: #1A2232;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          width: 42px;
          height: 42px;
          border-radius: 12px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          width: 36px;
          height: 36px;
          border-radius: 8px;
        }
        @media screen and (max-width: 767px) {
          width: 32px;
          height: 32px;
          border-radius: 8px;
        }
        svg{
          color: #25BC91;
          filter: none;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            width: 16px;
            height: 16px;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            width: 15px;
            height: 15px;
          }
          @media screen and (max-width: 767px) {
            width: 12px;
            height: 12px;
            object-fit: contain;
          }
        }
      }
      p{
        margin: 0 0 0;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 12px;
          line-height: 18px;
        }
        @media screen and (max-width: $screen-xs) {
          font-size: 10px;
          line-height: 18px;
        }
      }
    }
    ul.d-list{
      display: inline-flex;
      align-items: center;
      gap: 8px;
      margin: 0 20px 0 0 !important;
      @media screen and (max-width: 991px) and (min-width: 768px) {
        gap: 5px;
        margin: 0 10px 0 0!important;
      }
      @media screen and (max-width: $screen-xs) {
        gap: 5px;
        margin: 0 10px 0 0!important;
      }
      li{
        list-style-type: none;
        background: #151B2B;
        border-radius: 8px;
        min-width: 40px;
        text-align: center;
        padding: 8px 4px;
        cursor: pointer;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 12px;
          line-height: 18px;
          padding: 4px 6px;
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          font-size: 10px;
          line-height: 16px;
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          font-size: 8px;
          line-height: 14px;
          padding: 9px 6px;
          min-width: 36px;
        }
        @media screen and (max-width: $screen-xs) {
          min-width: 32px;
          width: 32px;
          padding: 9px 3px;
          font-size: 10px;
          line-height: normal;
        }
        span{
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            font-size: 10px !important;
            line-height: 16px;
          }
          @media screen and (max-width: $screen-xs) {
            font-size: 10px !important;
            line-height: normal;
          }
        }
        &.white{
          background: $white;
          color: #191F2F;
          span{
            color: #151B2B;
          }
        }
        span{
          color: $white;
          font-size: 16px;
          font-weight: bold;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
            font-size: 12px;
            line-height: 18px;
          }
          @media screen and (max-width: 991px) and (min-width: 768px) {
            font-size: 8px;
            line-height: 14px;
          }
        }
      }
    }
  }
  .characteristics-content{
    background: rgba(26, 34, 49, 0.36);
    border-radius: 10px;
    padding: 13px 11px;
    margin: 14px 0 0;
    &.light-dark{
      background: #191F2F;
    }
    @media screen and (max-width: 767px) {
      padding: 15px 15px;
    }
    .main-wrapper{
      // background: #1A2231;
      background: #191F2F5C;
      padding: 24px 20px;
      border-radius: 12px;
      height: 100%;
      @media screen and (max-width: 991px) {
        padding: 14px 15px;
      }
      &.padding{
        @media screen and (max-width: 1440px) and (min-width: 1200px) {

        }
      }
      strong.title{
        margin: 0 0 25px;
        display: block;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 10px;
          line-height: 12px;
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          margin: 0 0 15px;
        }
        @media screen and (max-width: 767px) {
          margin: 0 0 10px;
          font-size: 12px;
          line-height: normal;
        }
      }
      p{
        @media screen and (max-width: 1440px) and (min-width: 768px) {
          font-size: 10px;
          line-height: 12px;
        }
        @media screen and (max-width: 767px) {
          font-size: 10px;
          line-height: 12px;
        }
        span{
          color: $white;
          font-size: 14px;
          @media screen and (max-width: 1440px) and (min-width: 768px) {
            font-size: 10px;
            line-height: 12px;
          }
          @media screen and (max-width: 767px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
      .bar-chart-wrapper{
        .horizontal-bar-chart.content-bar-chart{
          margin: 0 0 10px;
          canvas{
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              height: 270px !important;
              width: 100% !important;
            }
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              height: 250px !important;
              width: 100% !important;
            }
            @media screen and (max-width: 767px) {
              height: 270px !important;
              width: 100% !important;
            }
          }
        }
      }
      .brand-mention{
        background: #181F2F;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 14px 7px 13px;
        margin: 0 0 4px 0;
        .brand-content{
          display: grid;
          grid-template-columns: 37px auto;
          gap: 8px;
          align-items: center;
          .img-wrapper{
            img{
              width: 37px;
              height: 37px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .content{
            .text{
              display: flex;
              align-items: center;
              font-size: 0;
              gap: 13px;
            }
          }
        }
      }
      .progress-bar-wrapper{
        margin: 23px 0 35px;
        //position: relative;
        display: inline-block;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          margin: 20px 0 10px;
          max-width: 200px;
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          margin: 10px 0 10px;
        }
        @media screen and (max-width: $screen-xs) {
          margin: 0;
        }
          .text-wrapper{
          margin: -30px 0 0 0;
            text-align: center;
          span{
            font-size: 14px !important;
            font-weight: 300 !important;
            @media screen and (max-width: $screen-xs) {
              font-size: 12px !important;
              line-height: normal !important;
            }
          }
          h2{
            margin: 10px 0 0 0 ;
            font-size: 40px !important;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              font-size: 24px !important;
            }
            @media screen and (max-width: 991px) and (min-width: 768px) {
              font-size: 32px !important;
            }
            @media screen and (max-width: $screen-xs) {
              font-size: 20px !important;
              line-height: normal;
            }
          }
        }
        .CircularProgressbar{
          max-width: 300px;
          .CircularProgressbar-trail{
            stroke: #FF165D !important;
          }
          .CircularProgressbar-path{
            stroke: $cyan !important;
          }
          .CircularProgressbar-text{
            fill: $white;
            font-weight: bold;
          }
        }
      }
      .views-wrapper{
        margin: 60px 0 0 0;
        @media screen and (max-width: 1440px){
          margin: 0 0 0;
        }
        .content{
          position: relative;
          padding: 0 0 0 18px;
          @media screen and (max-width: 1199px) {
            padding: 0 0 0 10px;
          }
          &:before{
            content: '';
            display: block;
            width: 3px;
            height: 74px;
            background: $cyan;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            border-radius: 6px;
            @media screen and (max-width: 1199px) {
              height: 60px;
            }
          }
          &:nth-child(2){
            &:before{
              background: #FF165D;
            }
          }
          &:not(&:last-of-type){
            margin: 0 0 45px;
            @media screen and (max-width: 1199px) {
              margin: 0 0 25px;
            }
          }

          span{
            color: #646F7C;
            display: block;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              font-size: 12px;
              line-height: 18px;
            }
            @media screen and (max-width: 1199px) {
              font-size: 10px;
              line-height: 14px;
            }
&.small{

  @media screen and (max-width: 1440px) and (min-width: 1200px) {
    font-size: 10px;
    line-height: 14px;
  }
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    font-size: 10px;
    line-height: 14px;
  }
}
            &.white{
              color: $white !important;
              font-weight: 600;
            }
          }
          strong{
            display: block;
            color: $white;
            // margin: 3px 0;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              font-size: 12px;
              line-height: 18px;
            }
            @media screen and (max-width: 1199px) {
              font-size: 10px;
              line-height: 14px;
            }
          }
          .text{
            display: flex;
            align-items: center;
            grid-template-columns: auto;
            gap: 5px;
            @media screen and (max-width: 1199px) and (min-width: 768px){
              grid-template-columns: 80px auto;
              gap: 10px;
            }
            @media screen and (max-width: $screen-xs) {
              margin: 0 0 3px;
            }
          }
        }
      }
    }
    .chart-videos-info-wrapper{
      .main-info-wrapper {
        padding: 16px 30px;
        @media screen and (max-width: 991px) and (min-width: 768px) {
          padding: 16px 20px;
        }
        @media screen and (max-width: 767px) {
          padding: 15px 15px;
        }

        .main-wrapper {
          padding: 0 0;
        }
      }
    }
  }
  .table-wrapper.gapped{
    tbody{
      tr{
        &:first-of-type{
          td{
            padding-top: 13px;
          }
        }
        td{
          &:first-of-type{
            padding-top: 13px;
          }
          padding-block: 13px;
          .images-wrapper{
            display: grid;
            align-items: center;
            grid-template-columns: 28px 28px 28px;
            gap: 8px;
            img{
              width: 29px;
              height: 44px;
              border-radius: 5px;
              @media screen and (max-width: 767px) {
                width: 19px;
                height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
.post-frequency-cards-wrapper{
  margin: 18px 0 15px;
  display: grid;
  grid-template-columns: 12% 12% 12% 12% 12% 12% 12% 12%;
  gap: 7px;
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    grid-template-columns: 24% 24% 24% 24%;
  }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    grid-template-columns: 24% 24% 24% 24%;
  }
  @media screen and (max-width: $screen-xs) {
    grid-template-columns: 49% 48%;
  }
  .post-frequency-card{
    background: #212937;
    border-radius: 5px;
    text-align: center;
    .title{
      background: $cyan;
      padding: 17px 17px 20px;
      border-radius: 5px;
      text-align: center;
      display: block;
      margin: 0 0 0 0;
      span.strong{
        color: #181F2F;
        display: block;
        font-weight: bold;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
    .content{
      padding: 17px 10px 15px;
      h3{
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 18px !important;
          line-height: 21px !important;
        }
      }
      .text{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin: 11px 0 0 0;
        .dot{
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #FF6400;
        }
        img{
          width: 13px;
          height: 13px;
        }
      }
    }
  }
}
.video-posted-average-wrapper{
  display: grid;
  grid-template-columns: 12% 12% 12% 12% 12% 12% 12% 12%;
  gap: 7px;
  margin: 0 0 30px ;
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    grid-template-columns: 24% 24% 24% 24%;
  }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    grid-template-columns: 24% 24% 24% 24%;
  }
  @media screen and (max-width: $screen-xs) {
    grid-template-columns: 49% 48%;
    margin: 0 0 10px ;
  }
  .video-posted-wrapper{
    background: #212937;
    border-radius: 5px;
    &:nth-child(1){
      .content{
        .progress{
        .progress-bar{
          background-color: #EB55B4;
        }
        }
      }
    }
    &:nth-child(6){
      .content{
        .progress{
          background-color: #2d354c;
          .progress-bar{
            background-color: #858DE2;
          }
        }
      }
    }
  &:nth-child(7){
    .content{
      .progress{
        background-color: #393a4f;
        .progress-bar{
          background-color: #EAB9FF;
        }
      }
    }
  }
    .title{
      padding: 24px 10px 7px;
      border-radius: 5px;
      display: block;
      text-align: center;
      min-height: 58px;
      margin: 0 0 0 0;
      span{
        display: block;
        color: $white;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
    .content{
      padding: 14px 12px 12px;
      span{
        display: block;
        margin: 0 0 5px;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 10px;
          line-height: 12px;
        }
      }
      .progress{
        margin: 17px 0 0 0;
        height: 7px;
        background-color: #392e46;
        .progress-bar{
          background-color: #B58AE4;
        }
      }
    }
  }
}
ul.graph-ranks{
  margin: 0 0 20px;
  li{
    &:not(&:last-of-type){
      margin: 0 43px 0 0;
      @media screen and (max-width: $screen-xs) {
        margin: 0 10px 0 0;
      }
    }
    .dot{
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      vertical-align: middle;
       margin: 0 6px 0 0;
    }
    span{
      color: $white;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        font-size: 10px;
        line-height: 16px;
      }
    }
  }
}
.scan-profile-new{
  .main-dashboard-wrapper{
    .dashboard-inner-page{
      @media screen and (max-width: 767px) {
        height: calc(100vh - 110px);
      }
      .scan-new-profile-inner-content{
        .dashboard{
          padding: 40px 0;
          height: calc(100vh - 220px);
          overflow-y: auto;
          padding-inline: 100px;
          @media screen and (max-width: 1880px){
            padding-inline: 30px;
          }
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            height: calc(100vh - 300px);
            padding: 20px 0;
          }
          @media screen and (max-width: 991px) and (min-width: 768px) {
            height: calc(100vh - 265px);
            padding: 20px 0;
          }
          @media screen and (max-width: 767px) {
            height: auto;
            padding: 30px 0;
          }
          &::-webkit-scrollbar {
            width: 3px;
          }
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: $cyan;
            border-radius: 50px;
          }
          .user-scan-dashboard-info{
            background: linear-gradient(to right, #1b1a2c 10%,#171d2e, #142030);
            padding: 5px 11px 5px 6px;
            border-radius: 10px;
            .ratings-wrapper, .user-info-wrapper{
              background: #171B2D;
              border: 1px solid #191F2F;
            }
            .actions-main-wrapper{
              grid-template-columns: 32% 32% 32%;
              .action-wrapper{
                background: #171B2D;
                border: 1px solid #121828;
              }
            }
            .features-wrapper{
              padding: 20px 12px 14px 12px;
              background: #182942;
              border: 1px solid #121828;
              ul.features-list{
                .dot{
                  width: 5px;
                  height: 5px;
                }
                li{
                  font-size: 10px;
                  color: #ffffffa1;
                }
              }
            }

          }
          .average-cards-wrapper{
            background: transparent;
            // padding: 19px 9px 10px;
            border-radius: 10px;
            margin: 0 0 12px;
            >strong{
              margin: 0 0 19px 7px;
              display: block;
            }
          }
          .video-tabs-inner-info-body-wrapper{
            .upper-charts-wrapper{
              margin: 0 0 3px;
              >.row{
                >.col-xl-5{
                    @media (min-width: 1200px) {
                      width: 45%;
                  }
                }
                >.col-xl-4{
                  @media  (min-width: 768px) {
                    padding-right: 0;
                  }
                  @media (min-width: 1200px) {
                    width: 30%;
                    padding-inline: 0;
                  }
                }
              }
            }
          .below-charts-wrapper{
            >.row{
              >.col-xl-8 {
                @media (min-width: 1200px) {
                  width: 72%;
                }
              }
              >.col-xl-4{
                @media  (min-width: 768px) {
                  padding-left: 0;
                }
                @media (min-width: 1200px) {
                  width: 28%;
                  padding-left: 0;
                }
              }
            }
          }
          }
          .total-cards-wrapper{
            background: $dark-blue;
            padding: 14px 12px 20px;
            border-radius: 10px;
            height: 100%;
            @media screen and (max-width: 991px) and (min-width: 768px) {
              padding: 10px 12px 10px;
            }
            >.d-flex{
              strong{
                @media screen and (max-width: 1440px) and (min-width: 768px) {
                  font-size: 10px;
                  line-height: 18px;
                }
                @media screen and (max-width: 767px) {
                  font-size: 10px;
                  line-height: 12px;
                }
              }
            }
            ul.result{
              li{
                font-size: 10px;
                color: $white;
                padding: 6px;
                background: #151B2B;
                border-radius: 5px;
                font-weight: bold;
                cursor: pointer;
                @media screen and (max-width: 1440px) and (min-width: 1200px) {
                  font-size: 8px;
                  line-height: normal;
                  padding: 7px 4px;
                }
                @media screen and (max-width: 991px) {
                  font-size: 8px;
                  line-height: 12px;
                }
                &.active{
                  background: $white;
                  color: #191F2F !important;
                }
                &:not(&:last-of-type){
                  margin: 0 5px 0 0;
                }
              }
            }
            >strong{
              margin: 0 0 19px 7px;
              display: block;
              @media screen and (max-width: 767px) {
                font-size: 12px;
                line-height: 16px;
              }
            }
            .total-stats-inner-wrapper{
              // background: #151B2B;
              // border-radius: 10px;
              // padding: 14px 17px;
              display: grid;
              grid-template-columns: 130px auto;
              @media screen and (max-width: 767px) {
                grid-template-columns: 90px auto;
              }
              ul.lists{
                // padding: 10px 0 0 0 !important;
                position: relative;
                &:after{
                  content: "";
                  display: none;
                  border-right: 1px solid;
                  border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #58b4b1, rgba(255, 255, 255, 0)) 1;
                  height: 100%;
                  position: absolute;
                  right: 0;
                  top: 0;
                  @media screen and (max-width: 767px) {
                    border-image: linear-gradient(to right, rgba(255, 255, 255, 0), #58b4b1, rgba(255, 255, 255, 0)) 1;
                    width: 100%;
                    border-bottom: 1px solid;
                    height: 2px;
                    border-right: 0;
                    top: unset;
                    bottom: -5px;
                  }
                }
                li{
                  display: block;
                  &:not(&:last-of-type){
                    margin: 0 0 3px;
                  }
                  img, svg{
                    width: 11px;
                    height: 11px;
                    object-fit: contain;

                  }
                  button{
                    background: transparent;
                    padding: 5px 10px;
                    min-width: 110px;
                    border: none;
                    color: $white;
                    text-align: left;
                    border-radius: 5px;
                    font-size: 12px;
                    line-height: 14.52px;
                    display: grid;
                    align-items: center;
                    grid-template-columns: 12px auto;
                    gap: 7px;
                    @media screen and (max-width: 1440px) and (min-width: 1200px) {
                      font-size: 10px;
                      line-height: 14px;
                    }
                    @media screen and (max-width: 1199px) and (min-width: 768px) {
                      font-size: 10px;
                      line-height: 14px;
                    }
                    @media screen and (max-width: 767px) {
                      font-size: 8px;
                      line-height: 12px;
                      padding: 5px 9px;
                      width: 90%;
                      min-width: auto;
                    }
                    img{
                      width: 15px;
                      height: 15px;
                      object-fit: contain;
                      @media screen and (max-width: 1199px) and (min-width: 768px) {
                        width: 12px;
                        height: 12px;
                      }
                    }
                    &:hover{
                      background: #272E3E;
                    }
                  }
                }
              }
              .total-stats-chart{
                .graph-chart-wrapper{
                  height: 140px;
                  #lineChart{
                    width: 100% !important;
                    height: 175px !important;
                  }
                }
              }
            }
          }
          .common-duration-graph-wrapper{
            margin: 0 0 12px;
            @media screen and (max-width: 1199px) and (min-width: 991px) {
              margin: 0 0 24px;
            }
            .common-duration-chart-wrapper{
              height: 226px !important;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                height: 200px !important;
              }
            }
          }
          .posting-behaviour-main-wrapper, .long-vs-short-videos{
            height: 100%;
          }
          .common-duration-graph-wrapper, .most-used-hashtags, .long-vs-short-videos, .posting-behaviour-main-wrapper{
            background: $dark-blue;
            border-radius: 10px;
            position: relative;
            // padding-bottom: 35px;
            height: 100%;
            .content-wrapper{
              padding: 16px 17px 8px 20px;
              position: relative;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                padding: 7px 14px 6px 14px;
              }
              @media screen and (max-width: 991px) and (min-width: 768px) {
                padding: 7px 10px 6px 10px;
              }
              @media screen and (max-width: $screen-xs) {
                padding: 7px 10px 6px 10px;
              }
              .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                //margin: 0 0 30px;
                @media screen and (max-width: 991px) and (min-width: 768px) {
                  margin: 0 0 5px;
                }
                span.strong {
                  @media screen and (max-width: 1440px) and (min-width: 1200px) {
                    font-size: 10px;
                    line-height: 12px;
                  }
                  @media screen and (max-width: 991px) and (min-width: 768px) {
                    font-size: 10px;
                    line-height: 12px;
                  }
                  @media screen and (max-width: $screen-xs) {
                    font-size: 10px;
                    line-height: 12px;
                  }
                }
                ul.result{
                  li{
                    font-size: 10px;
                    color: $white;
                    padding: 6px;
                    background: #151B2B;
                    border-radius: 5px;
                    font-weight: bold;
                    cursor: pointer;
                    @media screen and (max-width: 1440px) and (min-width: 1200px) {
                      font-size: 8px;
                      line-height: 10px;
                    }
                    @media screen and (max-width: 991px) {
                      font-size: 8px;
                      line-height: 12px;
                    }
                    &.active{
                      background: $white;
                      color: #191F2F;
                    }
                    &:not(&:last-of-type){
                      margin: 0 5px 0 0;
                    }
                  }
                }
              }
            }
            .btn-style.view-full-btn{
              font-size: 12px;
              background: #151B2B;
              width: 100%;
              border-radius: 0 0 10px 10px;
              padding: 10px 52px 10px;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: 9;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                font-size: 10px;
                padding: 8px 50px ;
              }
              @media screen and (max-width: 991px) and (min-width: 768px) {
                font-size: 10px;
                line-height: normal;
              }
              @media screen and (max-width: 767px) {
                font-size: 10px;
                line-height: normal;
              }
              img{
                width: 13px;
                height: 13px;
                object-fit: contain;
              }
            }
          }
          .progress-circular-bar-wrapper{
            margin: 20px 0 10px;
            @media screen and (max-width: 1399px) and (min-width: 1200px){
              margin: 20px 0 0 0;
            }
            @media screen and (max-width: 767px) {
              margin: 20px 0 30px;
            }
            >.row{
              >.col-xl-8{
                @media screen and (max-width: 1440px) and (min-width: 1200px) {
                  width: 60%;
                }

                }
                >.col-xl-4{
                  @media screen and (max-width: 1440px) and (min-width: 1200px) {
                    width: 40%;
                    padding-right: 0;
                  }
              }
            }
            .progress-bar-wrapper{
              text-align: center;
              .text-wrapper{
                margin: 34px 0 0 0;
                span{
                  font-size: 12px !important;
                  font-weight: 300 !important;
                  @media screen and (max-width: 767px) {
                    font-size: 10px !important;
                    line-height: normal;
                  }
                }
                h2{
                  margin: 10px 0 0 0 ;
                  font-size: 36px !important;
                  @media screen and (max-width: 1440px) and (min-width: 1200px) {
                    font-size: 26px !important;
                    margin: 5px 0 0 4px;
                  }
                  @media screen and (max-width: 767px) {
                    font-size: 26px !important;
                  }
                }
              }
              .CircularProgressbar{
                max-width: 216px;
                margin: auto;
                .CircularProgressbar-trail{
                  stroke: #FF165D !important;
                }
                .CircularProgressbar-path{
                  stroke: $cyan !important;
                }
                .CircularProgressbar-text{
                  fill: #ffffff;
                  font-weight: bold;
                  font-size: 20px;
                }
              }
              >div{
                >div{
                  >div{
                    margin: 0 0 0 0;
                    top: -20px;
                    margin-top: 0 !important;
                  }
                }
              }
             }
            .views-wrapper{
              @media screen and (max-width: 767px) {
                display: grid;
                grid-template-columns: auto;
                gap: 10px;
                margin: 0 0;
              }
              .content{
                position: relative;
                padding: 0 0 0 12px;
                &:not(&:last-of-type){
                  margin: 0 0 20px;
                  @media screen and (max-width: 767px) {
                    margin: 0 0 10px;
                  }
                }
                &:nth-child(2){
                  &:before{
                    background: #FF165D;
                  }
                }
                .text{
                  display: grid;
                  align-items: center;
                  grid-template-columns:auto;
                  gap: 10px;
                }
                &:before{
                  content: "";
                  display: block;
                  width: 3px;
                  height: 74px;
                  background: #00F2EA;
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  border-radius: 6px;
                  @media screen and (max-width: 1440px) and (min-width: 1200px) {
                    height: 60px;
                  }
                  @media screen and (max-width: 767px) {
                    height: 60px;
                  }
                }
                span{
                  color: #646F7C;
                  display: block;
                  font-size: 14px;
                  margin: 0 0 3px;
                  @media screen and (max-width: 1440px) and (min-width: 1200px){
                    font-size: 8px;
                    line-height: normal;
                  }
                  @media screen and (max-width: 1199px) and (min-width: 768px) {
                    font-size: 10px;
                    line-height: normal;
                  }
                  @media screen and (max-width: 767px) {
                    font-size: 8px;
                    line-height: normal;
                  }
                  &.white{
                    color: $white !important;
                  }
                }
                strong{
                  display: block;
                  color: #ffffff;
                  margin: 0 0 3px;
                  font-size: 10px;
                  @media screen and (max-width: 1599px) and (min-width: 1400px){
                    font-size: 10px;
                    line-height: normal;
                  }
                  @media screen and (max-width: 1399px) and (min-width: 1200px){
                    font-size: 9px;
                    line-height: normal;
                  }
                  @media screen and (max-width: 767px) {
                    line-height: normal;
                    margin: 0 0 5px;
                  }
                }
              }
            }
          }
          .graphs-main-wrapper{
            padding: 8px 0 ;
            display: flex;
            align-items: center;
            overflow-x: scroll;
            flex-wrap: nowrap;
            text-wrap: nowrap;
            gap: 10px;
            &::-webkit-scrollbar {
              width: 5px;
              height: 8px;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
              background: rgba(100, 111, 124, 0.19);
              border-radius: 4px;
              opacity: 8;
            }
            .chart-wrapper{
              background: #151B2B;
              border-radius: 10px;
              padding: 9px 2px;
              max-width: 240px;
              width: 100%;
              //width: 280px;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                padding: 3px 2px 9px;
              }
              span.white{
                padding-inline: 10px;
                @media screen and (max-width: 1440px) and (min-width: 1200px) {
                  font-size: 9px;
                  line-height: 12px;
                }
                @media screen and (max-width: 1199px) and (min-width: 768px) {
                  font-size: 10px;
                  line-height: 12px;
                }
                @media screen and (max-width: $screen-xs) {
                  font-size: 8px;
                  line-height: 12px;
                }
              }
              .dashboard-posting-behaviour-charts{
                height: 215px !important;
                @media screen and (max-width: 1440px) and (min-width: 1200px) {
                  height: 180px !important;
                }
                canvas{

                }
              }
              .footer{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: -20px 0 0  0;
                padding-inline: 10px;
                span{
                  color: #646F7C;
                  font-size: 10px;
                  &.white{
                    color: $white;
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.github-chart{
  .react-calendar-heatmap-month-label{
    font-size: 3px;
  }
  .react-calendar-heatmap-all-weeks{
    .color-empty{
      fill: #38414F;
      border-radius: 5px;
    }
  }
  .w-heatmap{
    width: 100%;
    min-height: 220px;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      min-height: 190px;
    }
    >rect{
      display: none !important;
    }
    .w-heatmap-week{
      color: $white;
      font-size: 12px;
    }
    text{
      color: $white;
      font-size: 12px;
    }
    rect{
      width: 21px;
      height: 21px;
      //fill: #38414F;
    }
  }
}
.large-chart-custom-tooltip{
  background: $white;
  border-radius: 10px;
  max-width: 190px;
  position: relative;
  padding: 0 0 8px;
  &:after{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -7px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ffffff;
  }
  .upper-date-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(100, 111, 124, .15);
    padding: 5px 10px;
    .date{
      svg, img{
        color: #646F7C;
        width: 12px;
        height: 12px;
      }
      span.small{
        font-size: 8px;
      }
      span.strong{
        color: #121828;
        font-size: 10px;
        font-weight: 700;
      }
    }
    .percentage{
      display: inline-flex;
      align-items: center;
      gap: 3px;
      img, svg{
        color: #34A853;
        width: 15px;
        height: 15px;
        object-fit: contain;
      }
      span.green{
        color: #34A853;
      }
    }
  }
  .img-list{
    display: grid;
    grid-template-columns: 30px 30px 30px 30px 30px;
    gap: 5px;
    padding: 5px 10px;
    .list{
      .img{
        width: 30px;
        height: 46px;
        object-fit: cover;
        border-radius: 5px;
      }
      .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        margin: 2px 0 0 0;
        .tiktokIcon{
          width: 8px;
          height: 8px;
          object-fit: contain;
        }
        span{
          color: #646F7C;
          font-size: 8px;
        }
      }
    }
  }
  .view-more-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    a{
    color: #121828;
      font-size: 8px;
      padding: 0;
      height: auto;
      min-width: auto;
    }
    img, svg{
      height: 6px;
      object-fit: contain;
      color: #121828;
    }
  }
}
.double-line-chart{
  border-radius: 5px;
  width: 99%;
  padding: 5px 20px;
  height: 35px;
  margin: 10px auto 0;
  canvas{
    height: 25px !important;
    width: 100% !important;
  }
}

.pricing-container {
  margin: 120px auto;
  max-width: 1200px;
}
.cards-row {
  display: flex;
  justify-content: space-between;
}

.pricing-card {
  // color: white;
  // padding: 40px 45px 110px;
  // background: #191F2F;
  // transition: .3s ease-in-out all;
  // position: relative;
  // height: 100%;
  // border-radius: 16px;
  // background: $light-body-color;


  background: linear-gradient(164.95deg, rgba(0, 242, 234, .1), rgba(0, 242, 234, .02) 50%, rgba(0, 242, 234, .06));
  text-align: left;
  padding: 40px 45px 110px;
  border-radius: 32px;
  height: 100%;
  position: relative;
  transition: all .3s ease-in-out;
  border: 1px solid transparent;
  cursor: pointer;
  &.active{
    transform: scale(1.05);
    background: linear-gradient(180.95deg, rgba(0, 242, 234, .24), rgba(0, 242, 234, .02) 50%, rgba(0, 242, 234, .06));
    border-image-slice: 1;
    transition: .3s ease-in-out all;
    .card-body{
      .btn{
        opacity: 1;
        background-color: #12A9A6 !important;
      }
      .icon i{
        color: #12A9A6;
      }
    }
  }
  .icon {
    height: 60px;
    width: 60px;
    margin: auto;
    margin-bottom: 30px;
    border: 2px solid #232A31;
    border-radius: 100px;
    background: #1E252C;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    i, svg {
      color: $white;
    }
  }

  .heading {
    text-align: center;
    font-size: 26px;
    width: 100%;
    margin-bottom: 0px;
    color: $white;
  }

  .price {
    font-size: 36px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin: 20px 0px;
    color: $white;
  }

  .card-text {
    text-align: left;

    p {
      line-height: 26px;
      font-size: 14px;
      font-weight: 300 !important;
      color: hsla(0,0%,100%,.8);
    }

    strong {
      font-weight: 500;
    }
  }

  // .btn-style {
  //   margin-top: 30px;
  //   text-transform: uppercase;
  //   width: 78%;
  //   display: block;
  //   position: absolute !important;
  //   bottom: 40px;
  //   left: 50%;
  //   top: unset !important;
  //   transform: translateX(-50%);
  //   z-index: 9;
  //   text-align: center;
  //   padding: 15px 52px 14px;
  //   background: #74787d!important;
  //   &.subscribed{
  //     opacity: 1;
  //       background-color: #12A9A6 !important;
  //   }
  // }
   .btn-style {
    width: 100%;
       position: absolute !important;
    bottom: 40px;
    left: 50%;
    top: unset !important;
    transform: translateX(-50%);
    z-index: 9;
    margin-top: 30px;
    width: 78%;
    text-transform: uppercase;
    box-shadow: none;
    padding: 12px 20px 10px;
    &.subscribed{
      opacity: 1;
        background-color: #12A9A6 !important;
        box-shadow: 0px 11px 16.8999996185px 0px rgba(0, 0, 0, 0.1882352941), 0px 18px 48.7999992371px 0px #eae5e7 inset;
    }
  }

}
   .plan-card {
        background: linear-gradient(164.95deg, rgba(0, 242, 234, 0.1) 0%, rgba(0, 242, 234, 0.02) 50%, rgba(0, 242, 234, 0.06) 100%);
        text-align: left;
        padding: 40px 40px 116px;
        border-radius: 32px;
        height: 100%;
        position: relative;
        transition: .3s ease-in-out all;
        border: 1px solid transparent;
        cursor: pointer;
        &.active {
          background: linear-gradient(180deg, rgba(0, 242, 234, 0.12) 0%, rgba(0, 242, 234, 0.04) 50%, rgba(0, 242, 234, 0.07) 100%);
          border: 1px solid rgba(0, 242, 234, 0.16);
          // border-image-source: linear-gradient(180deg, rgba(0, 242, 234, 0.32) 0%, rgba(0, 242, 234, 0.1) 50%, rgba(0, 242, 234, 0.16) 100%);
          border-image-slice: 1;
          border-radius: 32px;
          transition: .3s ease-in-out all;
          @media screen and (max-width: 991px) and (min-width: 768px) {
            padding: 20px 20px 150px;

          }
          .plan-name-wrapper {
            .title {
              .recommended-text {
                display: inline-flex;
              }
              .icon {
                background: linear-gradient(180deg, #00F2EA 0%, rgba(0, 242, 234, 0.41) 100%);
                transition: .3s ease-in-out all;
                border-image-source: none;
                &:after {
                  border: 4px solid #000000;
                  transition: .3s ease-in-out all;
                }
              }
            }
            .btn-wrapper {
              .get-started-btn {
                background: #00F2EA80;
                background: linear-gradient(180deg, #00F2EA 0%, #1A9999 100%);
                color: $black;
                font-weight: 600;
                border: 1px solid #1A9999;
              }
            }
          }
        }

        .plan-name-wrapper {
          .title-wrapper{
            min-height: 225px;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .icon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #FFFFFF1F;
                border: 1px solid;
                border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.02) 100%);
                position: relative;
                transition: .3s ease-in-out all;
                min-width: 40px;

                &:after {
                  content: "";
                  display: block;
                  background: transparent;
                  width: 20px;
                  height: 20px;
                  border: 5px solid #ffffff;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  border-radius: 50%;
                  transition: 0.3s ease-in-out all;
                  min-width: 20px;
                  min-height: 20px;
                }
              }

              .recommended-text {
                border: 0.5px solid #FFFFFF1F;
                background: #FFFFFF14;
                border-radius: 12px;
                display: inline-block;
                align-items: center;
                gap: 3px;
                padding: 2px 11px;

                img,
                svg {
                  width: 15px;
                  height: 15px;
                  object-fit: contain;
                }

                span {
                  color: #00F2EA;
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }
            .text {
              margin: 24px 0 32px;
              .package-title{
                img, svg{
                  height: 15px;
                  width: auto;
                  object-fit: contain;
                  &.threeDaysFreeTrailIcon{
                    position: absolute;
                    top: 28px;
                    right: 30px;
                    height: 48px;
                  }
                }
              }
              strong {
                font-size: 20px;
                display: block;
                margin: 0 0 4px;
                font-weight: 500;
              }
              span{
                color: #FFFFFFCC;
              }
            }
            .price {
              h3.large {
                font-size: 48px !important;
                line-height: 56px !important;
                font-weight: 500 !important;
                sub {
                  color: #FFFFFFCC;
                  font-weight: 400;
                  font-size: 16px;
                  bottom: 0;
                }
              }
            }
          }

          .btn-wrapper{
            padding: 21px 0 40px ;
            border-bottom: 1px solid #FFFFFF3D;
            .get-started-btn {
              width: 100%;
              display: flex;
              background: linear-gradient(180deg, rgb(255 255 255 / 3%) 0%, rgba(255, 255, 255, 0) 100%);
              color: $white;
              border: 1px solid #FFFFFF1A;
              box-shadow: none;
              font-weight: 500;
              padding: 12px 10px 11px;
            }
          }
        }

        .features-main-wrapper {
          margin: 40px 0 0;

          strong.features-title {
            font-weight: 500;
          }

          ul.features-list {
            margin: 18px 0 0 0 !important;

            li {
              display: grid;
              grid-template-columns: 14px auto;
              gap: 8px;
              &:not(&:last-of-type) {
                margin: 0 0 16px;
              }

              img,
              svg {
                width: 16px;
                height: 16px;
                object-fit: contain;
              }
              span {
                font-size: 14px;
                color: #FFFFFFCC;
                line-height: 18px;
                @media screen and (max-width: 991px) and (min-width: 768px) {
                  font-size: 12px;
                }
                &.white {
                  color: $white;
                  font-weight: 600;
                }
              }
            }
          }
        }

        .support-social-media-wrapper {
          text-align: center;
          background: #FFFFFF05;
          border-radius: 10px;
          padding: 10px 16px 20px;
          position: absolute;
          bottom: 40px;
          left: 50%;
          right: 0;
          width: 78%;
          transform: translateX(-50%);

          @media screen and (max-width: 991px) and (min-width: 768px) {
            padding: 10px 8px 20px;
          }

          p {
            color: #81868E;
            font-size: 14px;
            line-height: 33.6px;
            margin: 0 0 0;
          }

          ul.social-media-icons {
            margin: 3px 0 0 0 !important;

            li {
              &:not(&:last-of-type) {
                margin: 0 19px 0 0;
              }

              a {
                display: inline-block;

                img,
                svg {
                  width: 20px;
                  height: 20px;
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
.graph-chart-wrapper{
  .large-graph-chart-1{
    // height: 450px !important;
    width: 100% !important;
    overflow: hidden;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
       height: 370px !important;
    }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
      min-height: 320px;
    }
    @media screen and (max-width: 1199px){
      height: auto !important;

    }
    @media screen and (max-width: 767px) {
      height: 150px !important;
    }
    // @media screen and (max-width: 767px){
    //    height: 450px !important;
    // }
    .main-icon-label-div{
      @media screen and (max-width: 767px){
        //height: auto !important;
        min-height: 220px;
      }
    }
  }
  .engagement-chart{
    height: 300px !important;
    width: 100% !important;
    @media screen and (max-width: 1199px){
      height: auto !important;
      min-height: 150px;
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      height: 320px !important;
    }
  }
  .large-graph-chart-2{
    height: 350px !important;
    width: 100% !important;
    @media screen and (max-width: 1199px){
      height: auto !important;
      min-height: 150px;
    }
  }
  .content-characteristic-chart{
    height: 400px !important;
    width: 100% !important;
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      height: 300px !important;
    }
    @media screen and (max-width: 1199px){
      height: auto !important;

    }
  }
}
.pricing-page{
  .form-switch{
    text-align: center;
    justify-content:center;
    margin: 0 0 30px;
    label{
      color: $white;
      padding: 0 0 0 20px;
    }
  }
  .form-check-input:checked{
    background-color: $white;
    border-color: $white;
  }
  .form-check-input:checked{
    background-color: #445071;
    border-color: #445071;
  }
}
.upgrade-package-modal {
  .modal-dialog{
    max-width: 500px;
  }
  .modal-body{
    text-align: center;
  }
  .btn-wrapper{
    text-align: right;
    margin: 50px 0 0 0;
    a{
      padding: 12px 30px 11px;
    }
  }
}
.posting-behaviour-tab{
  .chart-section{
    .chart-content-wrapper{
      background: #191F2F;
      border-radius: 15px;
      padding: 40px 15px;
      @media screen and (max-width: 1440px) and (min-width: 1200px) {
        padding: 18px 20px;
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding: 15px 10px;
      }
      ul.lists{
        li{
          &:not(&:last-of-type){
            margin: 0 0 10px;
            @media screen and (max-width: $screen-xs) {
              margin: 0 0 3px;
            }
          }
        }
      }
    }
  }
}
.posting-behaviour-section{
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    margin-bottom:  30px !important;
  }
  .graphs-main-wrapper{
    .chart-wrapper{
      background: #191F2F !important;
    canvas{
      width: 100% !important;
    }
    .footer{
      margin: -7px 0 0 !important;
    }
    }
  }
}
.bar-chart-wrapper{
  .horizontal-bar-chart{
    .apexcharts-grid-borders{
      .apexcharts-gridline{
        &:first-of-type{
          stroke: $white !important;
        }
      }
    }
  }
}

.download-images-page{
  .dashboard-inner-page{
    padding-inline: 64px;
    @media screen and (max-width: 1199px) {
      padding-inline: 0;
    }
    .title-text-wrapper{
      padding: 0 0 0 104px;
      margin: 34px 0 44px;
      @media screen and (max-width: 1199px) {
        padding: 0 0 0 0;
      }
      .title-text{
        padding: 5px 20px;
        background: #1C263E52;
        border-radius: 8px;
        strong{
          br{
            @media screen and (max-width: 1199px) {
              display: none;
            }
          }
        }
      }
    }
    .download-comment-image-section{
      .download-comment-image-main{
        border-radius: 17px;
        background: $white;
        max-width: 530px;
        width: 100%;
        ul.tabs-wrapper{
          display: flex;
          align-items:center;
          justify-content: space-between;
          border-radius: 17px 17px 0 0;
          overflow: hidden;
          li{
            text-align: center;
              background: #1C263E;
            padding: 10px 10px;
            display: block;
            width: 100%;
            a, button{
              color: $white;
              text-align: center;
              padding: 5px 10px;
              background: transparent;
              border: none;
              display: block;
              width: auto;
              margin-inline: auto;
                border-bottom: 3px solid transparent;
              svg, img{
                color: #00807C;
                width: 10px;
                height: 10px;
                object-fit: contain;
                margin: 0 0 0 4px;
              }
            }
            &.active{
              background: #00F2EA;
              box-shadow: 0px 19.96px 36.67px 0px #FFDADAC2 inset;
              a, button{
                color: $body-color;
                font-weight: bold;
                border-bottom: 3px solid $body-color;
              }
            }
          }
        }
        .tabs-content-wrapper{
          padding: 18px 36px 27px;
          @media screen and (max-width: 767px) {
            padding: 18px 16px 27px;
          }
          .tab-content{
            strong.title{
              color: #161F36;
              font-size: 10px;
              display: block;
              margin: 0 0 12px;
              font-weight: bold;
            }
            .choose-profile-pic{
              display: inline-flex;
              align-items: center;
              gap: 13px;
              .doubleArrows-icon{
                width: 33px;
                height: 33px;
                object-fit: contain;
              }
              .profile-pic-wrapper{
                display: grid;
                align-items: center;
                grid-template-columns: 57px auto;
                gap: 20px;
                border: 1px dashed #12182836;
                background: #E8E8E8;
                border-radius: 12px;
                padding: 17px 20px;
                .avatarIcon{
                  width: 57px;
                  height: 57px;
                  border-radius: 50%;
                  object-fit: contain;
                }
                .change-profile-btn {
                  background: $white;
                  border-radius: 4px;
                  color: #121828;
                  font-size: 14px;
                  line-height: normal;
                  font-weight: 400;
                  padding: 8px 20px;
                }
              }
            }
            .form-wrapper{
              margin: 53px 0 0 0;
              form.form{
                .form-group-main{
                  position: relative;
                  display: flex;
                  align-items: center;
                  gap: 11px;
                  margin-bottom: 43px;
                  span.character-text{
                    color: #81868E;
                    position: absolute;
                    left: 10px;
                    bottom: -22px;
                  }
                  .doubleArrows-icon{
                    width: 33px;
                    height: 33px;
                    object-fit: contain;
                  }
                  .verified-text{
                    background: #E8E8E8;
                    border-radius: 4px;
                    position: absolute;
                    top: -34px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    left: 2px;
                    padding: 2px 15px;
                    span{
                      color: #666666;
                    }
                    svg, img{
                      color: #666666;
                      width: 9px;
                      height: 9px;
                      object-fit: contain;
                    }
                  }
                }
                .form-group{
                  margin: 0 0 0 0;
                  background: transparent;
                  box-shadow: 0px 3.33px 12.47px 0px #FFFFFF inset;
                  border: 1px solid #BCBCBC;
                    padding-left: 50px;
                  position: relative;
                  width: 100%;
                  .icon{
                    background: #E8E8E8;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 46px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 15px 0 0 15px;
                    overflow: hidden;
                    img, svg{
                      width: 20px;
                      height: 20px;
                      object-fit: contain;
                      color: #646F7C;
                    }
                  }
                }
              }
              a.download-btn{
                color: $white;
                width: 100%;
                box-shadow: 0px 9.15px 14.05px 0px #00000030;
                box-shadow: 0px 9.98px 40.58px 0px #2E3B5F inset;
                background: #121828;
                border-radius: 12px;
                font-size: 14px;
                font-weight: bold;
              }
            }
          }
        }
      }
      .mobile-img-wrapper{
        text-align: center;
        margin: 50px 0 0 0;
        .mobileImg{
          max-width: 290px;
          width: 100%;
          height: auto;
          max-height: 590px;
        }
      }
    }
  }
}
.contribution-chart-main-wrapper{
  .section-heading{
    .date-wrapper{
      .select-wrapper{
        .dropdown-menu{
          width: auto;
        }
      }
    }
  }
}
.git-hub-chart-tooltip-wrapper{
  background: $white;
  border-radius: 10px;
  border: 0.5px solid #646F7C26;
  .date-wrapper{
    display: grid;
    grid-template-columns: 13px auto;
    gap: 8px;
    padding: 5px 10px;
    svg{
      color: $body-color;
      width: 13px;
      height: 12px;
      object-fit: contain;
    }
    span{
      color: $body-color;
    }
  }
  .images-wrapper{
    border-top:  0.5px solid #646F7C26;
    border-bottom:  0.5px solid #646F7C26;
    padding: 10px 10px 6px;
    display: grid;
    grid-template-columns: 30px 30px 30px 30px 30px;
    gap: 5px;
    .img-main-wrapper{
      text-align: center;
      .img{
        img, svg{
          width: 30px;
          height: 46px;
          object-fit: contain;
          border-radius: 8px;
        }
      }
      .icon{
        img, svg{
          width: 9px;
          height: 8px;
          object-fit: contain;
        }
        sup{
          color: $text-primary02;
          font-size: 6px;
          line-height: normal;
          font-weight: 500;
          top: 0;
        }
      }
    }
  }
  .total-detail-wrapper{
    padding: 10px 6px;
    img , svg{
      width: 13px;
      height: 13px;
      object-fit: contain;
      color: $body-color;
    }
    .total{
      display: grid;
      grid-template-columns: 13px auto;
      gap: 8px;
      .text{
        display: inline-flex;
        align-items: center;
        gap: 9px;
        span, strong{
          color: $body-color;
          font-size: 10px !important;
        }
      }
    }
  }
}
.w-overlay-container{
  .w-tooltip{
    .w-tooltip-arrow{
      border-top-color: $white !important;
      bottom: 1px;
    }
    .w-tooltip-inner{
      background-color: $white;
      padding: 0;
      box-shadow: none;
      border-radius: 10px;
    }
  }
}
.bs-tooltip-top, .custom-tooltip .tooltip-inner {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  background: $white;
  border-radius: 10px;
  background-color: $white !important;
  color: $white;
  .arrow{
    &:before {
      border-right-color: $white !important; /* Match arrow color to tooltip background */
    }
  }
}
.with-magnifier-icons{
  position: relative;
  padding: 0 15px 0 0;
  .magnifiers-list{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    li{
      display: block;
      &:not(&:last-of-type){
        margin: 0 0 10px;
      }
      a{
        background: transparent;
        border: none;
        cursor: pointer;
        background: #191F2F;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        svg {
          color: $white;
          width: 14px;
    height: 14px;
    object-fit: contain;
        }
      }
    }
  }
}
.content-bar-chart{
  &:before{
    content: '';
    display: block;
    background: $white;
    width: 1px;
    height: 88%;
    position: absolute;
    left: 53px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@keyframes ldio-zxrz71mlja-r {

  0%,
  100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8)
  }

  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1)
  }

  0% {
    transform: rotate(0deg)
  }

  50% {
    transform: rotate(180deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes ldio-zxrz71mlja-s {

  0%,
  100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8)
  }

  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1)
  }

  0% {
    transform: translate(-30px, -30px) scale(0)
  }

  50% {
    transform: translate(-30px, -30px) scale(1)
  }

  100% {
    transform: translate(-30px, -30px) scale(0)
  }
}

.ldio-zxrz71mlja>div {
  transform: translate(0px, -15px)
}

.ldio-zxrz71mlja>div>div {
  animation: ldio-zxrz71mlja-r 1s linear infinite;
  transform-origin: 100px 100px;
}

.ldio-zxrz71mlja>div>div>div {
  position: absolute;
  transform: translate(100px, 82px);
}

.ldio-zxrz71mlja>div>div>div>div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #00f2ea;
  animation: ldio-zxrz71mlja-s 1s linear infinite;
}

.ldio-zxrz71mlja>div>div:last-child {
  animation-delay: -0.5s;
}

.ldio-zxrz71mlja>div>div:last-child>div>div {
  animation-delay: -0.5s;
  background: #ff0050;
}

.loadingio-spinner-interwind-flyom1cz6sv {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff00;
}

.ldio-zxrz71mlja {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-zxrz71mlja div {
  box-sizing: content-box;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}

@keyframes spin3D {
  from {
    transform: rotate3d(.5,.5,.5, 360deg);
  }
  to{
    transform: rotate3d(0deg);
  }
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-inline: auto;
  .leo-border-1 {
    position: absolute;
    width: 150px;
    height: 150px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(63,249,220);
    background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
    animation: spin3D 1.8s linear 0s infinite;
  }

  .leo-core-1 {
    width: 100%;
    height: 100%;
    background-color: #37474faa;
    border-radius: 50%;
  }

  .leo-border-2 {
    position: absolute;
    width: 150px;
    height: 150px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(251, 91, 83);
    background: linear-gradient(0deg, rgba(251, 91, 83, 0.1) 33%, rgba(251, 91, 83, 1) 100%);
    animation: spin3D 2.2s linear 0s infinite;
  }

  .leo-core-2 {
    width: 100%;
    height: 100%;
    background-color: #1d2630aa;
    border-radius: 50%;
  }
}
.help-and-support-page{
  .inner-page-wrapper{
      .dashboard-inner-page{
        padding: 45px 54px 100px;
        @media screen and (max-width: $screen-xs) {
          padding: 30px 5px 50px;
        }
        .page-title{
          margin: 0 0 39px;
          @media screen and (max-width: $screen-xs) {
            margin: 0 0 20px;
          }
        h3{
          color: $heading-color ;
          @media screen and (max-width: 1440px) and (min-width: 1200px) {
          }
        }
      }
      .help-and-support-page-content{
        background: $dark-blue;
        border-radius: 12px;
        padding: 0 34px 26px;
        border: 1px solid #272C3B;
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          border-radius: 8px;
        }
        @media screen and (max-width: $screen-xs) {
          padding: 0 15px 26px;
        }
        .img-wrapper{
          img, svg{
            max-width: 401px;
            width: 100%;
            margin-top: -75px;
          }
        }
        .main-sontent-wrapper{
          padding: 26px 10px 0 10px;
          max-width: 420px;
          width: 100%;
          @media screen and (max-width: $screen-xs) {
            padding: 26px  0 10px;
          }
          .text-wrapper{
            strong{
              margin: 0 0 10px;
              display: block;
              @media screen and (max-width: $screen-xs) {
                margin: 0 0 5px;
              }
              &.large{
                @media screen and (max-width: 1440px) and (min-width: 1200px) {
                  font-size: 18px;
                  line-height: 24px;
                }
                @media screen and (max-width: $screen-xs) {
                  font-size: 15px;
                  line-height: 22px;
                }
              }
            }
            p{
              font-weight: 500;
              color: $text-primary02;
              @media screen and (max-width: 1440px) and (min-width: 1200px) {
                font-size: 12px;
                line-height: 17px;
              }
              @media screen and (max-width: $screen-xs) {

              }
            }
          }
        }
        form.new-form{
          .field-wrapper{
            margin: 0 0 20px;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              margin: 0 0 15px;
            }
            @media screen and (max-width: $screen-xs) {
              margin: 0 0 10px;

            }
            .form-group{
              background: #13192A;
              display: flex;
              // align-items: center;
              align-items: self-start;
              padding: 11px 12px;
              gap: 18px;
              margin: 0 0 0;
              @media screen and (max-width: $screen-xs) {
                gap: 10px;
              }
              img, svg{
                width: 20px;
                height: 20px;
                @media screen and (max-width: 1440px) and (min-width: 1200px) {
                  width: 18px;
                  height: 18px;
                }
                @media screen and (max-width: $screen-xs) {
                  width: 18px;
                  height: 18px;
                }
              }
              .form-control{
                background: transparent;
                background-color: transparent;
                border: none;
                padding: 0 0 0;
                line-height: 18px;
                border-radius: 0;
                @media screen and (max-width: 1440px) and (min-width: 1200px) {
                  font-size: 12px;
                  line-height: 18px;
                  color: #81868E;
                }
                @media screen and (max-width: $screen-xs) {
                  font-size: 12px;
                  line-height: 16px;
                }
                &::placeholder{
                  line-height: 18px;
                  @media screen and (max-width: 1440px) and (min-width: 1200px) {
                    font-size: 12px;
                    line-height: 18px;
                    color: #81868E;
                  }
                  @media screen and (max-width: $screen-xs) {
                    font-size: 12px;
                    line-height: 16px;
                  }
                }
              }
              textarea{
                height: 65px;
              }
            }
          }
          .btn-style{
            padding: 12px 29px;
            max-width: 158px;
            width: 100%;
            @media screen and (max-width: 1440px) and (min-width: 1200px) {
              font-size: 12px;
              line-height: 15px;
            }
            @media screen and (max-width: $screen-xs) {
              font-size: 12px;
              line-height: 15px;
              border-radius: 6px;
              padding:8px 20px;
            }
            &.disabled{
              background: #115e67;
              box-shadow: none;
            }
          }
        }
      }
      }


  }
}
.new-login-page, .new-signup-page{
  position: relative;
    &:after{
      content: '';
      display: block;
       background-image: url('./assets/images/dashboard-bg-img.png');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    position: absolute;
    top: 0;
    left: 0;
        z-index: -1;
        filter: blur(14px);
        background-attachment: fixed;
    }
  &.new-login-page{
    .inner-page-wrapper{
      .inner-body-modal-wrapper{
        max-width: 405px;
        margin-inline: auto;
        .title-wrapper{
          h3{
            margin: 11px 0;
          }
          p{
            padding: 0 0 0;
            font-weight: 400;
            &.white{
              font-size: 14px;
              line-height: 22px;
              color: #ECECEC;
            }
          }
        }
        form.form{
          max-width: 288px;
          margin-inline: auto;
        }
      }
    }
  }
  .on-boarding-modal{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    @media screen and (max-height: 650px) {
      height: auto;
      padding: 80px 0;
    }
  }
}

.on-boarding-modal{
  &.login-modal{
    .modal-dialog{
      max-width: 550px;
      .inner-body-modal-wrapper{
        max-width: 405px;
        margin-inline: auto;
        .title-wrapper{
          margin: 0 0 30px;
          h3{
            margin: 11px 0;
          }
          p{
            padding: 0 0 0;
            font-weight: 400;
            &.white{
              font-size: 14px;
              line-height: 22px;
              color: #ECECEC;
            }
          }
        }
        form.form{
          max-width: 288px;
          margin-inline: auto;
        }
      }
    }
  }
  .inner-page-wrapper{
    max-width: 550px;
      background: #151C2F;
      border: 1px solid #212737;
      border-radius: 20px;
      padding: 40px 0 57px;
      width: 100%;
      .modal-header, .modal-footer{
        display: none;
      }
    }
  .modal-body{
    padding: 41px 0 50px;
  }
  .inner-body-modal-wrapper{
    max-width: 288px;
    margin-inline: auto;
    .title-wrapper{
      text-align: center;
      .btn-style.colored-border{
      font-size: 10px;
      line-height: 14px;
      padding: 4px 10px 3px !important;
      border-radius: 9px;
      border: 2px solid transparent;
      }
      h3{
        margin: 11px 0 18px;
        color: #ECECEC !important;
      }
      p{
        font-size: 12px;
        line-height: 14px;
        color: #81868E;
        text-align: left;
        padding-inline: 15px;
      }
    }
    .agree-text{
      display: flex;
      align-items:center;
      gap: 5px;
      position: relative;
      padding: 0 0 0 10px;
      input[type="checkbox"] {
        border: 1px solid #FFFFFF0F;
        border-radius: 20px;
        box-shadow: 25px 4px 0px 0px #1B1C3B inset;
        width: 14px;
        height: 14px;
        border-radius: 4px;
        position: relative;
        &:after{
          content: '';
          position: absolute;
          background: transparent;
          border: 2px solid #FFFFFF0F;
          width: 100%;
          height: 100%;
          box-shadow: 0px 4px 15px 0px #1B1C3B inset;
          border-radius: 4px;
        }
      }
      input[type="checkbox"]:checked::after {
        display: none;
      }

      input[type=checkbox]:checked+label::before {
        content: "";
        display: block;
        position: absolute;
        text-align: center;
        height: 14px;
        width: 14px;
        left: 7px;
        top: 0;
        // background-color: $pink;
        background: linear-gradient(130deg, #00F2EA , #5352B0);
        font-family: "Montserrat";
        border-radius: 4px;
        border: 1px solid rgb(150 150 150 / 30%);
      }

      input[type=checkbox]:checked+label::after {
        content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
        display: block;
        position: absolute;
        left: 10px;
        top: 0;
      }
      label{
        color: $white;
        font-size: 12px;
        line-height: 14.6px;
        margin: 0 0 0 0;
        font-weight: 400;
        a{
          color: $pink;
          text-decoration: underline !important;
          font-size: 12px;
          line-height: 14.6px;
          font-weight: 400;
        }
      }
    }
    form.form{
      .field-wrapper{
        position: relative;
        &:not(&:last-of-type){
          margin: 0 0 10px;
        }
        .form-group {
          margin: 0 0 3px;
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 6px 15px;
          border-radius: 11px;
          border: 2px solid rgba(255, 255, 255, 0.0588235294);
          img, svg{
            width: 18px;
            height: 18px;
            object-fit: contain;
          }
          .react-tel-input{
            padding-left: 0 ;
            .form-control {
              background: transparent;
              background-color: transparent;
              border: none;
              padding-left: 34px;
              padding-block: 0;
              height: auto;
            }
            .flag-dropdown {
              background: transparent;
              background-color: transparent;
             border: none;
             .selected-flag{
              background: transparent !important;
              background-color: transparent !important;
              padding-left: 0 !important;
             }
             &:hover{
               background: transparent;
               background-color: transparent;
             }
            }
            .country-list {
              background: #1b1c3b !important;
              border-radius: 8px;
              overflow-x: hidden;
              z-index: 22;
              left: -22px;
    top: 41px;
              &::-webkit-scrollbar {
                width: 5px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #1b1c3b;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #81868E;
              }

              .country{
                background: transparent !important;
                background-color: transparent !important;
                display: block;
              }
            }
          }
          input:-webkit-autofill {
            color: #81868E !important;        /* Dark teal */
          }

          input:-webkit-autofill:focus {
            color: #81868E !important;           /* Even darker teal */
          }
            /* Change the white to any color */
            .form-control:-webkit-autofill,
            .form-control:-webkit-autofill:hover,
            .form-control:-webkit-autofill:focus,
            .form-control:-webkit-autofill:active{
              -webkit-box-shadow: 0 0 0 1000px $body-color inset !important;
              background: transparent !important;
              outline: none;
              background-color: transparent !important;
              -webkit-text-fill-color: #81868E !important;
            }
            .form-control:-internal-autofill-selected {
              background-color: transparent !important;
              color: #81868E !important;
            }
          .form-control{
            font-size: 12px;
            line-height: 14px;
            color: #81868E !important;
            border-radius: 0 0 0 !important;

          input:-webkit-autofill{
            -webkit-text-fill-color: $text-primary01 !important;
          }
            &::placeholder{
              color: #81868E;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      } .error{
        color: #AAAAAA;
        width: 0;
        text-wrap: nowrap;
        overflow: hidden;
        padding: 0 0 3px 21px;
        overflow: hidden;
  margin: 0 0 16px;
  position: absolute;
  left: 108%;
  top: 45%;
  transform: translateY(-50%);
  max-width: 350px;
  font-size: 12px;
  line-height: 14px;
        @media screen and (max-width: 1199px) {
          position: relative;
          top: unset;
          left: 20px;
          transform: none;
        }
        &:before{
          content: '';
          display: block;
          background-image: url("./assets/images/icons/cross-red-error-icon.png");
          position: absolute;
          left: 0;
          top: 3px;
          width: 14px;
          height: 14px;
          background-size: contain;
          background-repeat: no-repeat;

        }
      }
      .btn-wrapper{
        z-index: 1;
        position: relative;
        .btn-style{
          border-radius: 11px;
          font-size: 12px;
          line-height: 14px;
          padding: 13px 7px;
          box-shadow: none;
          background: #00F2EA;
          &.full{
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
    .already-sign-up-text{
      text-align: center;
      margin: 30px 0 0;
      P{
        font-size: 12px;
        line-height: 14px;
        margin: 0 0 0;
        a{
          font-size: 12px;
          line-height: 14px;
          &.pink{
            color: #FF6393;
          }
        }
      }
    }
  }
}
